import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'app/services/user-service/user-app.service';
import {ConfigService} from '@noble/services/config.service';

@Component({
  selector: 'app-our-project',
  templateUrl: './our-project.component.html',
  styleUrls: ['./our-project.component.scss']
})
export class OurProjectComponent implements OnInit {

  activities: string [];
  goals: string [];
  conditions: string [];
  servicesOption1: {icon: string, title: string, description}[];

  constructor(private route: ActivatedRoute, private router: Router,
              private userService: UserAppService,
              private _configService: ConfigService) {
    this.userService.isHomeURL.emit(true);
    // Configure the layout
    this._configService.config = {
      layout: {
        style   : 'app-navbar-home',
      }
    };
  }

  ngOnInit(): void {

    this.activities = [
      'our-project.activities.description-item1.label',
      'our-project.activities.description-item2.label',
    ];

    this.goals = [
      'our-project.goals.description-item1.label',
      'our-project.goals.description-item2.label',
    ];


    this.conditions = [
      'our-project.membership-conditions.item1.label',
      'our-project.membership-conditions.item2.label',
      'our-project.membership-conditions.item3.label',
    ];

    this.servicesOption1 = [
      {
        icon: 'airline_seat_flat',
        title: 'about-us.offered-services.option1.item1.title.label',
        description: 'about-us.offered-services.option1.item1.description.label',
      },
      {
        icon: 'business_center',
        title: 'about-us.offered-services.option1.item2.title.label',
        description: 'about-us.offered-services.option1.item2.description.label',
      },
      {
        icon: 'airport_shuttle',
        title: 'about-us.offered-services.option1.item3.title.label',
        description: 'about-us.offered-services.option1.item3.description.label',
      },
      {
        icon: 'airline_seat_individual_suite',
        title: 'about-us.offered-services.option1.item4.title.label',
        description: 'about-us.offered-services.option1.item4.description.label',
      },
      {
        icon: 'local_airport',
        title: 'about-us.offered-services.option1.item5.title.label',
        description: 'about-us.offered-services.option1.item5.description.label',
      },
      {
        icon: 'fiber_smart_record',
        title: 'about-us.offered-services.option1.item6.title.label',
        description: 'about-us.offered-services.option1.item6.description.label',
      },
    ];

  }

  makeDonation(){
    this.router.navigate(['home/make-donation']);
  }

  hireMe(){
    this.router.navigate(['register']);
  }

  ngOnDestroy(){
    this.userService.isHomeURL.emit(false);
  }
}
