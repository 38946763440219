import {MemberShip} from '../../../model/register/MemberShip';
import {RegisterActions, RegisterActionType} from '../actions/registerActions';

export interface MemberShipState {
  memberShip: MemberShip;
  email: string;
  userName: string;
  errorRegister: Error | string;
}

export const initialiseRegister: MemberShipState = {
  memberShip: null,
  email: null,
  userName: null,
  errorRegister: null
};

export function memberShipReducer(state = initialiseRegister, action: RegisterActions) {
  switch (action.type){
    case RegisterActionType.REGISTER_START :
      return {
        ...state,
        memberShip: action.payload,
        email: action.payload.emailAddress,
        userName: action.payload.surname,
        errorRegister: null
      };

    case RegisterActionType.REGISTER_SUCCESS :
      return {
        ...state,
        memberShip: null,
        email: action.payload.email,
        userName: action.payload.username,
        errorRegister: null
      };
    case RegisterActionType.REGISTER_FAILURE :
      return {
        ...state,
        memberShip: null,
        email: null,
        userName: null,
        errorRegister: action.payload
      };
    default : {
      return state;
    }
  }
}
