import {Component, OnInit, OnDestroy, ViewChild, Input, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {Subscription, Subject, Observable} from 'rxjs';
import { ButtonsBarComponent, IFormActionBarButton, FormAction } from '../../base/buttons-bar/buttons-bar.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '@noble/services/config.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {LoginState, selectAuthenticationState} from '../../../store/login/login.state';
import {User} from '../../../model/security/user';
import {State} from '../../../store/login/reducers/authentication.reducer';
import {RegisterService} from '../../../services/register/register.service';
import {StripePaymentComponent} from '../stripe-payment/stripe-payment.component';
import {ListAbstractComponent} from '../../base/abstract/list-abstract-component';
import {Member} from '../../../model/organisation/Member';
import {HttpService} from '../../../services/http-service/http.service';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {NotificationService} from '../../../services/notification-service/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {MemberShip} from '../../../model/register/MemberShip';
import {MatChipInputEvent, MatChipList} from '@angular/material/chips';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Basket} from '../../../model/payment/Basket';
@Component({
  selector: 'app-make-deposit',
  templateUrl: './make-deposit.component.html',
  styleUrls: ['./make-deposit.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class MakeDepositComponent extends ListAbstractComponent<Member>  implements OnInit, OnDestroy, AfterViewInit{

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  user = new User();
  userAge = 0;

  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';

  memberControl = new FormControl('', []);
  @ViewChild('chipList') nativeListChips: MatChipList;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  removable = true;
  addOnBlur = true;
  selectMe = true;
  selectAll = false;

  selectedMember: Member[] = [];
  public showMember: Member[] = [];

  amount: number;
  depositAmountControl: FormControl;
  depositAmountSubscription: Subscription;
  depositAmountSubject: Subject<number> = new Subject<number>();

  // @ViewChild('btnBar')
  btnBarCmp: ButtonsBarComponent;
  public buttons: IFormActionBarButton[];

  @ViewChild('stripePaymentComponent') stripePaymentComponent: StripePaymentComponent;

  functions: {icon: string, value: string, color: string}[] = [];

  isHandset$: Observable<boolean>;

  currentMemberShip: MemberShip;

  favoriteOption: {text: string, value: string, description: string};
  options: {text: string, value: number, description: string}[] = [
    {
      text: 'deposit.option.subscription.text.short',
      value: 76,
      description: 'deposit.option.subscription.text'
    },
    {
      text: 'deposit.option.unique.inscription.text.short',
      value: 50,
      description: 'deposit.option.unique.inscription.text'
    }
  ];

  static getAge(bir: Date | string): number {
    return new Date(Date.now()).getFullYear() - new Date(bir).getFullYear();
  }

  static amountInscription(birthday: Date | string): number {

    const ageOfMemberShip = MakeDepositComponent.getAge(birthday);

    if (ageOfMemberShip <= Basket.MAX_YEAR) {
      return Basket.NOBLE_INSCRIPTION_LESS_THAN_59_YEAR;
    } else {
      return Basket.NOBLE_INSCRIPTION_MORE_THAN_59_YEAR;
    }
  }

  static amountInscriptionAndSubscription(birthday: Date | string) {
    return MakeDepositComponent.amountInscription(birthday) + Basket.NOBLE_SUBSCRIPTION_AMOUNT;
  }

  constructor(
    protected ref: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected httpService: HttpService<Member>,
    protected userService: UserAppService,
    protected msgService: NotificationService,
    protected translateService: TranslateService,
    private _configService: ConfigService,
    private _registerService: RegisterService,
    private store: Store<LoginState>,
    private breakpointObserver: BreakpointObserver) {
    super(ref, httpService, router, route, userService, msgService, translateService);

    this.searchBaseUrl = 'organisation/member';

    this.authenticationState$ = this.store.select(selectAuthenticationState);

    this.initControl();

    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };

    this.buttons = [
      {id: 'save', value: FormAction.UPDATE, icon: {type: 'save', color: '#ff9933'},
        text: 'btn.confirm.label', disabled: true, functionalities: []}
    ];

    this.functions = [
      {
        icon: 'done',
        value: 'Register New member',
        color: 'primary'
      },
      {
        icon: 'done',
        value: 'Finish Dificulty',
        color: 'primary'
      },
      {
        icon: 'done',
        value: 'Part of association',
        color: 'warn'
      },
      {
        icon: 'done',
        value: 'Assistance',
        color: 'primary'
      },
    ];
  }

  ngOnInit(): void {

    super.ngOnInit();
    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.user = state.user.user;

        if (!this.user.administrator) {
          this._registerService.findMemberShipByLogin(this.user.login).then(member => {
            const userAge = new Date(Date.now()).getFullYear() - new Date(member.birthdate).getFullYear();
            this.currentMemberShip = member;
            this.criteria = {
              ...this.criteria,
              memberShipId: this.currentMemberShip.id
            };
            this.searchClicked({});
            this.setAmountByAge();
          });
        }
      }
    });

    this.depositAmountControl.valueChanges.subscribe((val: string) => {
      if (this.depositAmountControl.valid) {
        this.buttons[0].disabled = false;
      } else {
        this.buttons[0].disabled = true;
      }
    });

    this.depositAmountSubscription = this.depositAmountSubject.subscribe(amount => {

    });

    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(
        map(result => !result.matches),
        shareReplay()
      );
  }

  ngOnDestroy() {
    this.depositAmountSubscription.unsubscribe();
    this.authenticationSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.dataEmitter.subscribe((members: Member[]) => {
      this.showMember = members?.
      filter(x => this.selectedMember.findIndex(e => e.id === x.id) === -1);
    });
  }

  emitAmount() {
    this.depositAmountSubject.next(this.amount);
  }

  initControl() {
    this.depositAmountControl = new FormControl('', [Validators.required, Validators.pattern('[1-9][0-9]*')]);
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id ) {
      this.router.navigate(['/deposit/confirm', {amount: this.amount}]);
    }
  }
  amountByMemberShip(callback: (date: Date | string) => number) {
    return callback(this.currentMemberShip.birthdate);
  }

  amountByMemberShipAndIsFamily(callback: (date: Date | string) => number) {
    let amount = 0;
    if (this.selectMe) {
      amount = this.amountByMemberShip(callback);
    }

    if (this.selectedMember && this.selectedMember.length !== 0) {
      const amounts: number[] = this.selectedMember.map(e => callback(e.birthdate));
      const sum = amounts.reduce((a, b) => a + b, 0);
      amount = sum + amount;
    }
    return amount;
  }

  amountInscriptionByMemberShip() {
    return this.amountByMemberShip(MakeDepositComponent.amountInscription);
  }

  amountInscriptionAndSubscriptionByMemberShip() {
    return this.amountByMemberShip(MakeDepositComponent.amountInscriptionAndSubscription);
  }

  amountInscriptionByMemberShipAndIsFamily() {
   return this.amountByMemberShipAndIsFamily(MakeDepositComponent.amountInscription);
  }

  amountInscriptionAndSubscriptionByMemberShipAndIsFamily() {
    return this.amountByMemberShipAndIsFamily(MakeDepositComponent.amountInscriptionAndSubscription);
  }

  setAmountByAge() {
    this.options = this.options.filter((e: {text: string, value: number, description: string}) => {
      if (e.text === 'deposit.option.subscription.text.short') {
        e.value = this.amountInscriptionAndSubscriptionByMemberShipAndIsFamily();
      } else if (e.text === 'deposit.option.unique.inscription.text.short') {
        e.value = this.amountInscriptionByMemberShipAndIsFamily();
      }
      return e;
    });
  }

  changeTap(event) {
    console.log(event);
  }

  changeOption(event){
    if (event) {
      this.stripePaymentComponent.depositAmount = event.value.value;
    }
  }

  check(event) {
    this.selectMe = event.checked;
    this.setAmountByAge();
  }

  checkAllMeme(event) {
    this.selectedMember = [];
    this.selectedMember = this.tableDataSource.data;
    this.updateShowMember();
    this.setAmountByAge();
  }

  remove(m: Member) {
    this.selectAll = false;
    this.selectedMember = this.selectedMember.filter(e => e.id !== m.id);
    this.updateShowMember();
    this.setAmountByAge();
  }
  displayMember(m: Member) {
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      // this.selectedMember = this.selectedMember.concat([value]);
      this.memberControl.setValue(value);
    }

    if (input) {
      input.value = '';
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value: Member = event.option.value;
    const index = this.selectedMember.findIndex(e => e.id === value.id);
    if (index === -1) {
      this.memberControl.setValue(event.option.value);
      this.selectedMember = this.selectedMember.concat([event.option.value]);
      this.updateShowMember();
      this.setAmountByAge();
    }
  }

  displayMemberFn(member?: Member): string {
    return member ? `${member.fullname}` : '';
  }

  updateShowMember() {
    this.showMember =
      this.tableDataSource.data.
        filter(x => this.selectedMember.findIndex(e => e.id === x.id) === -1);
  }
}
