import {ClassNameMap, Persistent} from '../persistent/persistent';
import {Person} from './Person';

export class ContactPerson extends Persistent {
    public person?: Person;
    public rule?: string;
    constructor() {
        super();
        this.classe = ClassNameMap.ContactPerson;
    }
    public company?: string; // use in the case of attending doctor to specify his hospital
}
