import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {NobleValidators} from '../../base/validators/noble-validators';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {SingleContactPersonComponent} from '../../single-contact-person/single-contact-person.component';
import {Organisation} from '../../../model/organisation/Organisation';
import {Company, Party} from '../../../model/organisation/Party';
import {Address} from '../../../model/country/Address';
import {User} from '../../../model/security/user';
import {RegisterService} from '../../../services/register/register.service';
import {RegisterFailure, RegisterSuccess} from '../../../store/member-ship/actions/registerActions';
import {Observable, of, Subscription} from 'rxjs';
import {MemberShipState} from '../../../store/member-ship/reducers/register.reducer';
import {Store} from '@ngrx/store';
import {RegisterState, selectRegisterState} from '../../../store/member-ship/register.state';
import {City} from '../../../model/country/City';
import {Country} from '../../../model/country/Country';
import { TranslateService } from '@ngx-translate/core';
import {SearchCriteria} from '../../../model/data-page';
import { FileDownloaderComponent } from 'app/components/file-downloader/file-downloader.component';


@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit, AfterViewInit, OnDestroy {
  static password;

  generalInformationForm: FormGroup;
  StepGeneralInformationIsOptional = false;
  StepGeneralInformationIsEditable = true;

  @ViewChild('firstRepresentativePerson')
  firstRepresentativePersonCmp: SingleContactPersonComponent;
  firstRepresentativePersonForm: FormGroup;
  StepFirstRepresentativePersonIsOptional = false;
  StepFirstRepresentativePersonIsEditable = true;


  @ViewChild('secondRepresentativePerson')
  secondRepresentativePersonCmp: SingleContactPersonComponent;
  secondRepresentativePersonForm: FormGroup;
  StepSecondRepresentativePersonIsOptional = true;
  StepSecondRepresentativePersonIsEditable = true;

  accountInformationForm: FormGroup;
  StepAccountInformationIsOptional = false;
  StepAccountInformationIsEditable = true;

  @ViewChild('downloader')
  downloader: FileDownloaderComponent;
  openFileInTab = true;
  criteria: any | SearchCriteria = new SearchCriteria();

  optionGroup: FormGroup;
  stepOptionIsOptional = false;
  stepOptionIsEditable = true;

  checkTermsUse = false;
  checkTermsUse2 = false;

  loading = false;

  favoriteOption: {text: string, value: string, description: string};
  options: {text: string, value: string, description: string}[] = [
    {
      text: 'register.option.one.text.short',
      value: 'option-1',
      description: 'register.option.one.text'
    },
    {
      text: 'register.option.two.text.short',
      value: 'option-2',
      description: 'register.option.two.text'
    }
  ];

  registerState$: Observable<any>;
  registerSubscription: Subscription;

  static validConfirmPassWord(control: AbstractControl): ValidationErrors | null{
    if (PartnerComponent.password && control.value !== PartnerComponent.password){
      return {mismatch: true};
    }
    return null;
  }

  constructor(private formBuilder: FormBuilder,
              public translate: TranslateService,
              private registerStore: Store<RegisterState>,
              private registerService: RegisterService,
              private msgService: NotificationService) {
    this.registerState$ = this.registerStore.select(selectRegisterState);
  }

  ngOnInit(): void {
    this.initForms();

    this.registerSubscription = this.registerState$.subscribe((state: MemberShipState) => {
      if (state && state.errorRegister !== null && this.loading === true) {
        this.loading = false;
      }
    });

    this.accountInformationForm.controls.password.valueChanges.subscribe(value => {
      PartnerComponent.password = value;
    });
  }

  ngAfterViewInit() {
    this.firstRepresentativePersonForm = this.firstRepresentativePersonCmp.contactPersonForm;
    this.secondRepresentativePersonForm = this.secondRepresentativePersonCmp.contactPersonForm;
  }

  ngOnDestroy() {
    this.registerSubscription.unsubscribe();
  }

  StepGeneralInformationIsValid() {
    return this.generalInformationForm.valid;
  }

  StepFirstRepresentativePersonIsValid() {
    return this.firstRepresentativePersonCmp ? this.firstRepresentativePersonCmp.isValid() : false;
  }

    StepSecondRepresentativePersonIsValid() {
      return this.secondRepresentativePersonCmp ? this.secondRepresentativePersonCmp.isValid() : false;
    }

  StepAccountInformationIsValid(){
    return this.accountInformationForm.valid;
  }
  optionStepValid(){
    return this.optionGroup.valid;
  }

  radioChange(event) {
    this.favoriteOption = event.value;
  }



  initForms() {
    this.generalInformationForm = this.formBuilder.group({
      nameOfAssociation : ['', [Validators.required, NobleValidators.notEmpty, NobleValidators.notNumber]],
      fullAddressOfTheHeadOffice: ['', []],
      associationEmailAddress: ['', [Validators.required, Validators.email]],
      associationPhoneNumber: ['', [Validators.pattern(/[+0-9]{9}/)]],
      associationMinimumMember: ['', [Validators.required, NobleValidators.notEmpty]]
    });

    this.firstRepresentativePersonForm = this.formBuilder.group({
      firstRepresentative: []
    });

    this.secondRepresentativePersonForm = this.formBuilder.group({
      secondRepresentative: []
    });

    this.accountInformationForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, PartnerComponent.validConfirmPassWord]]
    });

    this.optionGroup = this.formBuilder.group({
      option : [null, [Validators.required]],
    });
  }

  goToStepFirstRepresentative() {
    if (!this.StepGeneralInformationIsValid()) {
      this.msgService.log('stepper.data.invalid', NotificationType.ERROR);
    }
  }

  goToStepSecondRepresentative() {
    if (!this.StepFirstRepresentativePersonIsValid()) {
      this.msgService.log('stepper.data.invalid', NotificationType.ERROR);
    }
  }

  goToStepOption() {
    if (!this. StepSecondRepresentativePersonIsValid()) {}
  }

  goToAccountInformation() {
    if (!this.optionStepValid()) {
      this.msgService.log('stepper.data.invalid', NotificationType.ERROR);
    }
  }

  getObjectFromView(): Promise<Organisation> {
    return new Promise<Organisation>((resolve, reject) => {
      let organisation = new Organisation();
      let party = new Company();

      party = {
        ...party,
        name: this.generalInformationForm.controls.nameOfAssociation.value,
        emailAddress: this.generalInformationForm.controls.associationEmailAddress.value
      };

      let user = new User();
      user = {
        ...user,
        login: this.generalInformationForm.controls.associationEmailAddress.value,
        password: this.accountInformationForm.controls.password.value,
      };

      organisation = {
        ...organisation,
        party,
        account: user,
        fullAddress: this.generalInformationForm.controls.fullAddressOfTheHeadOffice.value,
        firstRepresentative: this.firstRepresentativePersonCmp.getData(),
        secondRepresentative: this.secondRepresentativePersonCmp.getData(),
        readTerms: this.checkTermsUse,
        minimumMember: this.generalInformationForm.controls.associationMinimumMember.value,
        phoneNumber: this.generalInformationForm.controls.associationPhoneNumber.value
      };
      if (organisation.secondRepresentative.name === undefined || organisation.secondRepresentative.name === null) {
        delete organisation.secondRepresentative;
      }

      delete  organisation.party.address;

      resolve(organisation);
    });
  }

  isAllStepValid(){
    return this.StepGeneralInformationIsValid() &&
    this.StepFirstRepresentativePersonIsValid() &&
    this.StepAccountInformationIsValid();
  }

  save() {
    if (!this.isAllStepValid() || !this.checkTermsUse || !this.checkTermsUse2) {
      this.msgService.log('register.step.checkTermsUse.invalid', NotificationType.ERROR);
      return;
    }

    this.getObjectFromView().then(ob => {

      this.loading = true;
      this.registerService.saveOrganisation(ob).then((result: Organisation) => {
        this.registerStore.dispatch(new RegisterSuccess({email: result.account.login, username: result.party.name}));
      }).catch(err => {
        this.registerStore.dispatch(new RegisterFailure(err));
      });
    });
  }

  startDownload(val: string) {
    this.criteria.lang = this.translate.currentLang;
    this.criteria.value = val;
    this.criteria.code = val + '.pdf';

    this.downloader.criteria = this.criteria;
    this.downloader.criteria.classe = 'CriteriaSearch';
    this.downloader.link = 'organisation/documents/print';
    this.downloader.downloadFile({});
    this.downloader.event.subscribe( (wellDone) => {
      if (wellDone === false) {
        this.msgService.log('form.print.error', NotificationType.ERROR);
      }
    });
  }

}
