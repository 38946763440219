import {ClassNameMap, Persistent} from '../persistent/persistent';
import {User} from '../security/user';

export class Payment extends Persistent {
  user?: User;
  amount?: number;
  currency?: string;
  paymentId?: string;
  created?: number;
  description?: string | null;
  paymentMethod?: string | null;
  type?: 'stripe' | 'paypal' | 'other';
  status?: string;
  receiptEmail?: string;

  constructor(){
    super();
    this.classe = ClassNameMap.Payment;
  }
}
