import {ClassNameMap, Persistent} from '../persistent/persistent';
import {Person} from './Person';
import {User} from '../security/user';
import {Member} from './Member';
import {Company} from './Party';

export class Organisation extends Persistent{
  public party?: Company;
  public account?: User;
  public firstRepresentative?: Person;
  public secondRepresentative?: Person;
  public readTerms?: boolean;
  public members?: Member[];
  public fullAddress?: string;
  public options?: string;
  public phoneNumber?: string;
  public minimumMember?: string;

  constructor() {
    super();
    this.classe = ClassNameMap.Organisation;
    this.members = [];
  }
}
