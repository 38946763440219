import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ListAbstractComponent} from '../../base/abstract/list-abstract-component';
import {DownloadFile} from '../../../model/file/downloadFile';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../../services/http-service/http.service';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {RegisterService} from '../../../services/register/register.service';
import {GlobalVariables} from '../../../services/global-variables';
import {FileDownloaderComponent} from '../../file-downloader/file-downloader.component';
import {ConfigService} from '@noble/services/config.service';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})
export class FileListComponent extends ListAbstractComponent<DownloadFile> {

  @ViewChild('downloader')
  downloader: FileDownloaderComponent;

  openFileInTab = true;

  constructor(
    protected ref: ChangeDetectorRef,
    protected router: Router, protected route: ActivatedRoute,
    protected httpService: HttpService<DownloadFile>,
    protected userService: UserAppService,
    protected msgService: NotificationService,
    protected translateService: TranslateService,
    private _configService: ConfigService,
    private registerService: RegisterService
  ) {
    super(ref, httpService, router, route, userService, msgService, translateService);
    this.searchBaseUrl = 'organisation/documents/search-base';
    this.searchClicked(null);

    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };
  }

  protected search(criteria: any) {
    this.criteria.allPages = null;
    this.registerService.getDocument().subscribe( (result) => {
      this.tableDataSource.data = result ? result.datas : [];
      this.paginatorParams = {
        pageSize: GlobalVariables.PAGINATOR_DEFAULT_SIZE,
        pageSizeOptions: GlobalVariables.PAGINATOR_SIZE_OPTIONS,
        length: result.totalItemCount
      };
      this.tableCmp.renderRows();
      this.listSelectionChanged();
    }, (error) => {
      this.handleSearchError(error);
    });
  }

  download(element: DownloadFile) {
    this.criteria.lang = this.translateService.currentLang;
    this.criteria.value = element.value;
    this.criteria.code = element.value + '.pdf';

    this.downloader.criteria = this.criteria;
    this.downloader.criteria.classe = 'CriteriaSearch';
    this.downloader.link = 'organisation/documents/print';
    this.downloader.downloadFile({});
    this.downloader.event.subscribe( (wellDone) => {
      if (wellDone === false) {
        this.msgService.log('form.print.error', NotificationType.ERROR);
      }
    });
  }
}
