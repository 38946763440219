<!-- If the item has children, we render it and call menu-item to render his childs-->
<div *ngIf="menuItem && menuItem.children && menuItem.children.length > 0 ; else elseBlock">
  <mat-accordion>
    <mat-expansion-panel class="menu-item-btn" >
      <mat-expansion-panel-header>
        <mat-panel-title matTooltip="{{menuItem.menuDescription| translate}}">
          <div *ngIf="menuItem.menuAvatarUrl" mat-card-avatar class="menu-avatar" [ngStyle]="{ 'background-image': 'url(' + menuItem.menuAvatarUrl + ')'}"></div>
          {{menuItem.menuTitle | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-menu-item *ngFor="let item of menuItem.children" [menuItem]="item" [level]="level + 1"></app-menu-item>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<!-- If menuItem has no children we simply render it as a button-->
<ng-template #elseBlock >
  <mat-divider *ngIf="menuItem && level > 0"></mat-divider><!-- We put a divider only for sub menu (when level/depth is upper than 0)-->
  <button *ngIf="menuItem" mat-button class="menu-item-btn {{level == 0? 'mat-elevation-z2' : ''}} mat-button" matTooltip="{{menuItem.menuDescription | translate}}" (click)="menuItemClicked(menuItem)">
        <span class=" float-left">
        <img *ngIf="menuItem.menuAvatarUrl" mat-card-avatar class="menu-img-avatar" [src]="menuItem.menuAvatarUrl"/>
          {{menuItem.menuTitle | translate}}
        </span>
  </button>
</ng-template>
