<div class="row">
    <div class="col-xs-12 col-sm-6" >
        <button 
            style="min-width: 18em"
            class="btn btn-default " 
            (click)="downloadFile($event)">
            <span class="glyphicon glyphicon-download-alt {{cssClass}}" ></span>
            <!-- {{ title | translate}} -->
        </button>
        <a class="download-link" *ngIf="!openInTab"
        title="mon_fichier" download="{{fileName}}"></a>
        <a class="download-link" *ngIf="openInTab==true"
        title="mon_fichier" target="_blank"></a>
    </div>
</div>