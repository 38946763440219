import {
  Component,
  OnInit, Input,
  AfterViewInit, Output, EventEmitter, ViewChild, ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'app-secure-template',
  template:
  `<ng-container #container *ngIf="hasAccess">
   <ng-content></ng-content>
  </ng-container>`
  ,
  styles: []
})
export class SecureTemplateComponent implements OnInit, AfterViewInit {

  @ViewChild('container', {read: ViewContainerRef})
  container: ViewContainerRef;

  @Input() functionalities: string[];

  @Input() routingUrl: string[];

  @Input() serverUrl: string[];

  @Output() secure: EventEmitter<boolean> = new EventEmitter();

  hasAccess: boolean;
  constructor() {
    this.hasAccess = false;
   }

  ngOnInit() {
    this.hasAccessByFunctionalities();
  }

  hasAccessByFunctionalities() {
    if (!this.functionalities || this.functionalities.length === 0) { return; }
    this.functionalities = this.functionalities.filter((f: string) =>  true);
    if (this.functionalities.length !== 0) {
          this.hasAccess = true;
    }
    this.secure.emit(this.hasAccess);
  }
  ngAfterViewInit() {}

  hasPrivilage(): boolean{
    return true;
  }

}
