import {AfterViewInit, Component, HostListener, Inject, ElementRef, OnDestroy, OnInit, Renderer2, ChangeDetectorRef, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {GlobalVariables} from './services/global-variables';
import {registerLocaleData} from '@angular/common';
import 'moment/locale/fr';
import * as moment from 'moment';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {Observable, Subject, Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay, takeUntil} from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { UserAppService } from './services/user-service/user-app.service';
import {Store} from '@ngrx/store';
import {LoginState, selectAuthenticationState} from './store/login/login.state';
import {State} from './store/login/reducers/authentication.reducer';
import { filter } from 'rxjs/operators';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {RuleState} from './store/rule/rule.state';
import {StartGetRule} from './store/rule/actions/rule.action';
import {ConfigService} from '@noble/services/config.service';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fade',
    [
      state('void', style({ opacity : 0})),
      transition(':enter', [ animate(300)]),
      transition(':leave', [ animate(500)]),
    ]
  )]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit{
  progressBarMode = 'indeterminate';
  title = 'Noble retour';

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  @ViewChild(MatSidenav, {static: false})
  sideNav: MatSidenav;

  @ViewChild('mainContent')
  private mainContentDiv!: ElementRef<HTMLElement>;

  mediaSub: Subscription;
  deviceXs: boolean;
  options: FormGroup;
  longProcess = false;

  userLogged = false;
  userLogged$: Observable<boolean>;
  userRules$: Observable<boolean>;

  isHome = false;
  isHome$: Observable<boolean>;

  nobleConfig: any;

  private _unsubscribeAll: Subject<any>;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  constructor(private translate: TranslateService,
              private userService: UserAppService,
              private el: ElementRef,
              private renderer: Renderer2,
              private  ruleStore: Store<RuleState>,
              private _configService: ConfigService,
              private store: Store<LoginState>,
              private breakpointObserver: BreakpointObserver,
              fb: FormBuilder,
              private router: Router,
              private mediaObserver: MediaObserver,
              private cd: ChangeDetectorRef,
              @Inject(DOCUMENT) document
  ) {

    this._configService.config = {
      layout: {
        style   : 'app-navbar-home',
      }
    };

    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );

    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', 'UA-177090348-1', {page_path: e.urlAfterRedirects});
    });

    this.userLogged = userService.isUserLoggedIn();
    this.userService.userLoggedChanged.subscribe( (event) => {
      this.userLogged = event.logged;
      this.cd.detectChanges();
      if (!this.userLogged) {
        this.sideNav.close();
      }

    });

    const browserLang = this.translate.getBrowserLang();
    const langIndex = GlobalVariables.AVAILABLE_LANG.findIndex( (lang) => lang === browserLang);
    const currentLang = GlobalVariables.AVAILABLE_LANG[langIndex !== -1 ? langIndex : 0];
    this.translate.setDefaultLang(currentLang);
    this.translate.use(currentLang);
    GlobalVariables.AVAILABLE_LANG.forEach( (lang) => {
      registerLocaleData(GlobalVariables.AVAILABLE_LOCALE[lang]);
      // console.log(' lang ', lang, GlobalVariables.AVAILABLE_LOCALE[lang]  );
      registerLocaleData(GlobalVariables.AVAILABLE_LOCALE[lang]);
    });

    this.options = fb.group({
      bottom: 0,
      fixed: false,
      top: 0
    });

    // this.homeNavigate();
    this.authenticationState$ = this.store.select(selectAuthenticationState);

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * Whenever a new route is activated
   */
  onActivate(_event: any): void {
    // Scrolling back to the top
    // Reference: https://stackoverflow.com/questions/48048299/angular-5-scroll-to-top-on-every-route-click/48048822
    if (this.mainContentDiv) {
      (this.mainContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    }
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.translate.onLangChange.subscribe( (lang) => {
      moment.locale(lang.lang);
    });

    this.mediaSub = this.mediaObserver.media$.subscribe((result: MediaChange) => {
      console.log(result.mqAlias);
      this.deviceXs = result.mqAlias === 'xs';
    });

    this.isHome$ = new Observable<boolean>(ob => {
      this.userService.isHomeURL.subscribe((val: boolean) => {
        console.log(val);
        console.log(this.isHome);
        ob.next(val);
      });
    });

    this.userService.isHomeURL.subscribe(val => {
      this.isHome = val;
      // this.homeNavigate();
    });

    this.userLogged$ = new Observable<boolean>(ob => {
      this.authenticationState$.subscribe((newState: State) => {
        if (newState && (newState.isAuthenticated === true)) {
          ob.next(true);
        } else {
          ob.next(false);
        }
      });
    });

    this.authenticationState$.subscribe((newState: State) => {
      if (newState && (newState.getRule === true)) {
        this.ruleStore.dispatch(new StartGetRule(new Date()));
      }
    });

    // this.handleLoginError();

    this._configService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.nobleConfig = config;
      });
  }

  ngAfterViewInit() {
    this.setContentMaxHeight();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
   if (window.pageYOffset > 300) {
     const element = document.getElementById('navbar');
     if (element) {
       element.classList.add('sticky');
     }
   } else {
    const element = document.getElementById('navbar');
    if (element) {
      element.classList.remove('sticky');
    }
    }
  }


  // To set the maxHeight of the modal content and make it scrollable
  protected setContentMaxHeight() {

    const appContent = (this.el.nativeElement as HTMLElement).querySelector('.app-content');
    const containerHeight = screen.height;
    const minHeight = (containerHeight - 220) + 'px';
    this.renderer.setStyle(appContent, 'min-height', minHeight);
  }

  private homeNavigate(){
    // if (!this.userLogged && !this.isHome){
    //   this.router.navigate(GlobalVariables.HOME_ROUTE);
    // }
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy()  {
    this.mediaSub.unsubscribe();
    this.authenticationSubscription.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.unsubscribe();
  }

  private handleLoginError() {
    // this.router.navigate(['/login']);
  }
}


export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  translate: TranslateService;

  public rangeLabel: string;

  constructor() {
    super();
  }

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe((lang) => {
      this.translateLabels();
    });

    this.translateLabels();

  }

  private translateLabels(){
    this.itemsPerPageLabel = this.translate.instant('paginator.item.per.page.label');
    this.nextPageLabel = this.translate.instant('paginator.next.page.label');
    this.previousPageLabel = this.translate.instant('paginator.previous.label');
    this.firstPageLabel = this.translate.instant('paginator.first.page.label');
    this.lastPageLabel = this.translate.instant('paginator.last.page.label');
    this.rangeLabel = this.translate.instant('paginator.range.label');
    this.changes.next();
    // console.log("translateLabels ", this);
  }

  getRangeLabel = (page, pageSize, length) => {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + '-' + endIndex + ' ' + (this.rangeLabel ? this.rangeLabel : '/') + ' ' + length;
  }
}
