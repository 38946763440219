import { Country } from './Country';
import { Persistent, ClassNameMap } from '../persistent/persistent';

export class City extends Persistent {
    boxCode?: string;
    postCode?: string;
    name?: string;
    country?: Country;
    phoneCode?: Country;

    constructor() {
      super();
      this.country = new Country();
      this.classe = ClassNameMap.City;
    }
}
