<div class="login-zone align-items">
  <div class="card row login-container">
    <h2 class="col-xs-12 col-sm-12 col-md-12 card-header align-items">
      {{"reset.password.form.title.label"| translate}}
    </h2>
    <div class="card-body">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="col-xs-12 col-sm-12 col-md-12" appearance="outline">
          <mat-label>{{'login.form.password.label' | translate}}</mat-label>
          <input [type]="hide ? 'password' : 'text'"
                 matInput formControlName="password" >
          <mat-error *ngIf="submitted && resetPasswordForm.get('password').invalid">{{ "reset.password.form.password.required" | translate}}</mat-error>
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field><br><br>

        <mat-form-field class="col-xs-12 col-sm-12 col-md-12" appearance="outline">
          <mat-label>{{'register.form.confirmPassword.label' | translate}}</mat-label>
          <input [type]="hideConfirm ? 'password' : 'text'"
                 matInput formControlName="confirmPassword" >
          <mat-error *ngIf="resetPasswordForm.get('confirmPassword').getError('required')">{{ "register.form.confirmPassword.required" | translate}}</mat-error>
          <mat-error *ngIf="resetPasswordForm.get('confirmPassword').getError('mismatch')">{{ "register.form.confirmPassword.mismatch.error" | translate}}</mat-error>
          <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field><br><br>



        <button [disabled]="loading" class="btn btn-lg btn-block signIn-btn">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{"reset.password.btn.label"| translate}}
        </button><br>
      </form>
    </div>
  </div>
</div>
