import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ConfigService} from '../../../../@noble/services/config.service';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../../model/security/user';
import {MemberShip} from '../../../model/register/MemberShip';
import {State} from '../../../store/login/reducers/authentication.reducer';
import {RegisterService} from '../../../services/register/register.service';
import {LoginState, selectAuthenticationState} from '../../../store/login/login.state';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-family-member-home',
  templateUrl: './family-member-home.component.html',
  styleUrls: ['./family-member-home.component.scss']
})
export class FamilyMemberHomeComponent implements OnInit {
  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  currentUser: User;
  currentMemberShip: MemberShip;

  items: {label: string, linkUrl?: string}[] = [
    {label: 'family-member-home.member.title'},
    {label: 'family-member-home.member.active.title'},
    {label: 'family-member-home.member.inactive.title'},
    {label: 'family-member-home.member.on.probation.title'},
    {label: 'family-member-home.member.die.title'}
  ];

  constructor(
    private router: Router,
    private _configService: ConfigService,
    private registerService: RegisterService,
    private store: Store<LoginState>,
  ) {
    this.authenticationState$ = this.store.select(selectAuthenticationState);
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };
  }

  openEditDocument() {
    this.router.navigate(['/record-doc/edit']);
  }

  openListDocument() {
    this.router.navigate(['/record-doc/list']);
  }

  openAsblDocument() {
    this.router.navigate(['/documents/list']);
  }

  ngOnInit(): void {
    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.currentUser = state.user.user;
        this.registerService.findMemberShipByLogin(this.currentUser.login).then(memberShip => {
          this.currentMemberShip = memberShip;
        });
      }
    });
  }

}
