<div class="primary-navbar">
  <div mat-ripple routerLink="/home" class="navbar-logo">
    <img alt="logo" class="navbar-logo-image" src="assets/images/new-logo.png" height="38" width="38">
    <div class="navbar-logo-text d-none d-md-block">NOBLE RETOUR</div>
  </div>

  <div class="navbar-buttons">
    <ng-container *ngIf="showRegisterButton">
      <div class="ml-2">
        <button routerLink="/register"
                mat-raised-button color="primary" >
          {{ "register.btn.label" | translate}}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="showLoginButton">
      <div class="ml-2">
        <button routerLink="/login"
                [color]="showRegisterButton ? 'default' : 'primary'"
                mat-raised-button
                attr.aria-label="{{'signIn.btn.label' | translate}}"
                matTooltip="{{'signIn.btn.label' | translate}}">
          {{ "login.btn.label" | translate }}
        </button>
      </div>
    </ng-container>

    <div class="ml-2 d-flex align-items-center"
         [matMenuTriggerFor]="langMenu"
         *ngIf="languageService.currentLanguage"
         attr.aria-label="{{'change-lang-btn.label' | translate}}"
         matTooltip="{{'change-lang-btn.label' | translate}}">
      {{languageService.currentLanguage.value | uppercase}}
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-menu #langMenu="matMenu">
      <ng-container *ngFor="let lang of languageService.availableLanguages">
        <button mat-menu-item (click)="languageService.changeLanguage(lang)">{{lang.labelCode | translate}}
        </button>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-menu>

  </div>
</div>

