import { Party } from './Party';
import { Country } from '../country/Country';
import { ClassNameMap, Nameable } from '../persistent/persistent';
import { Period } from './Period';

export class Person extends Party {
    public surname?: string;
    public officePhone?: string;
    public religion?: Religion;
    public nationality?: Country;
    public gender?: string | GenderType | any;
    public identity?: Identity;
    public profession?: Profession;
    public addressString?: string;
    public birthplace?: string;

    constructor() {
        super();
        this.nationality = new Country();
        this.classe = ClassNameMap.Person;
    }
}

export class Profession extends Nameable{
    constructor(name?: string) {
        super(name);
        this.classe = ClassNameMap.Profession;
    }
}


export class Religion extends Nameable{
    constructor(name?: string) {
        super(name);
        this.classe = ClassNameMap.Religion;
    }
}

export enum GenderType {
  FEMALE,
  MALE,
  OTHER
}

export enum RelationType {
  SON,
  GIRL,
  WIFE,
  HUSBAND,
  DAD,
  MOTHER,
  UNCLE,
  AUNTY,
  GRANDFATHER,
  GRANDMOTHER,
  OTHER,
}

export interface Identity {
    number?: string;
    identityType?: string | IdentityType;
    validityPeriod?: Period;
    deliveryPlace?: string;
}

export enum IdentityType {
    NATIONAL_CARD,
    PASSPORT
}

