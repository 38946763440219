import { Injectable } from '@angular/core';
import {HttpService} from '../http-service/http.service';
import {UserAppService} from '../user-service/user-app.service';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../notification-service/notification.service';
import {Record} from '../../model/document/record';
import {RecordDoc} from '../../model/document/record-doc';

@Injectable({
  providedIn: 'root'
})
export class RecordService extends HttpService<any>{
  constructor(public userService: UserAppService ,
              public http: HttpClient, public notificationService: NotificationService) {
    super(userService, http, notificationService);
  }

  saveRecord(entity: Record): Promise<Record> {
    return  new Promise<Record>((resolve, reject) => {
      this.save(entity, 'document/record').subscribe((ob: Record) => {
        resolve(ob);
      }, error => {
        reject(error);
      });
    });
  }

  saveRecordDoc(entity: RecordDoc): Promise<RecordDoc> {
    return  new Promise<RecordDoc>((resolve, reject) => {
      this.save(entity, 'document/record-doc').subscribe((ob: Record) => {
        resolve(ob);
      }, error => {
        reject(error);
      });
    });
  }
}
