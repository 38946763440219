import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {NavbarHomeComponent} from './navbar-home/navbar-home.component';
import {NavbarMenuComponent} from './navbar-menu/navbar-menu.component';
import {NavbarOutComponent} from './navbar-out/navbar-out.component';
import {NavbarComponent} from './navbar.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRippleModule} from '@angular/material/core';

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule, MatMenuModule, MatButtonModule, MatDividerModule,
    MatIconModule, MatToolbarModule, MatTooltipModule, MatRippleModule],
  declarations: [NavbarHomeComponent, NavbarMenuComponent, NavbarOutComponent, NavbarComponent],
  exports: [NavbarHomeComponent, NavbarMenuComponent, NavbarOutComponent, NavbarComponent]
})
export class AppLayoutModule {
}
