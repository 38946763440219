<div class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'profile.page.password.title'">
        <button
          mat-raised-button
          routerLink="/manage/account"
          color="primary"
          aria-label="button with a add one icon">
          <mat-icon>skip_previous</mat-icon>
          <mat-label>{{"profile.password.update.btn-back-to-account.label" | translate}}</mat-label>
        </button>
      </app-header>
    </mat-card-content>
  </mat-card>

  <div class="justify-content-center">
    <ng-container *ngIf="!checkOldPassword">
      <div class="card" style="width: 25rem">
        <div class="card-body">
          <h4 class="card-title mb-3 text-center">{{name}}</h4>
          <p class="card-text text-center">{{'profile.password.update.subtitle' | translate}}</p>
          <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">

            <mat-form-field class="col-xs-12 col-sm-12 col-md-12" appearance="outline">
              <mat-label>{{'profile.password.update.old-password.label' | translate}}</mat-label>
              <input type="password" matInput formControlName="oldPassword">
              <mat-icon matSuffix>password</mat-icon>
              <mat-error *ngIf="passwordForm.get('oldPassword').invalid">{{'profile.password.update.old-password.invalid' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="col-xs-12 col-sm-12 col-md-12" appearance="outline">
              <mat-label>{{'profile.password.update.new-password.label' | translate}}</mat-label>
              <input [type]="hide ? 'password' : 'text'"
                     matInput formControlName="newPassword" >
              <mat-error *ngIf="passwordForm.get('newPassword').invalid">{{ "profile.password.update.new-password.invalid" | translate}}</mat-error>
              <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>

            <mat-form-field class="col-xs-12 col-sm-12 col-md-12" appearance="outline">
              <mat-label>{{'profile.password.update.confirm-password.label' | translate}}</mat-label>
              <input type="password" matInput formControlName="confirmation" >
              <mat-error *ngIf="passwordForm.get('confirmation').getError('required')">{{ "register.form.confirmPassword.required" | translate}}</mat-error>
              <mat-error *ngIf="passwordForm.get('confirmation').getError('mismatch')">{{ "register.form.confirmPassword.mismatch.error" | translate}}</mat-error>
            </mat-form-field>

            <div class="d-flex justify-content-between mt-3">
              <button [disabled]="loading || passwordForm.invalid" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                <!----> {{'profile.password.update.submit.label' | translate}}
              </button><br>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
  </div>
</div>
