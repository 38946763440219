import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';

@Component({
  templateUrl: 'navbar-menu.component.html',
  selector: 'app-navbar-menu',
  styleUrls: [ 'navbar-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'navbar-menu'
  }
})
export class NavbarMenuComponent implements OnChanges {

  public myMenuLinks: { routerLink: string, label: string }[] = [];

  @Input() menuLinks: { routerLink: string, label: string }[];

  @Input() useButton = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['menuLinks']) {
      this.myMenuLinks = this.menuLinks;
    }
  }
}
