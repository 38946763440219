import {Person} from '../organisation/Person';
import {ClassNameMap} from '../persistent/persistent';

export class GodFather extends Person{
  code?: string;
  constructor() {
    super();
    this.classe = ClassNameMap.GodFather;
  }
}
