import { Person } from './Person';
import { ClassNameMap } from '../persistent/persistent';

export class Member extends Person {
  registrationNumber?: string;
  group?: Person;
  status?: MemberStatus | string;
  dead?: boolean;
  type?: MemberType;
  registrationDate: Date;
  placeOfBirth?: string;
  socialSecurityNumber?: string;
  expirationDate?: string;
  relationType?: string | any;


  constructor() {
    super();
    this.group = new Person();
    this.registrationDate = new Date();
    this.type = MemberType.VISITEUR;
    this.status = MemberStatus[MemberStatus.ONPROBATION];
    this.dead = false;
    this.classe = ClassNameMap.Member;
  }
}

export enum MemberStatus {
  APPROBATE, ACTIVE, INACTIVE, ONPROBATION, DIE, INAPPROVAL
}

export enum MemberType {
  VISITEUR,
  RESIDENT
}

