import {Action} from '@ngrx/store';

export enum RuleActionType {
  START_GET_RULE = '[Rule] Start',
  SUCCESS_GET_RULE = '[Rule] Success',
  FAILURE_GET_RULE = '[Rule] Failure',
}

export class StartGetRule implements Action{
  readonly type = RuleActionType.START_GET_RULE;
  constructor(public payload: Date) {
  }
}

export class SuccessGetRule implements Action{
  readonly type = RuleActionType.SUCCESS_GET_RULE;
  constructor(public payload: string[]) {
  }
}

export class FailureGetRule implements Action{
  readonly type = RuleActionType.FAILURE_GET_RULE;
  constructor(public payload: string | Error) {
  }
}

export type RuleActions =
  | StartGetRule
  | SuccessGetRule
  | FailureGetRule;
