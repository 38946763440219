import { Component, OnInit } from '@angular/core';
import {RecordDoc} from '../../../../../model/document/record-doc';
import {Record, RecordType} from '../../../../../model/document/record';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
let self;

@Component({
  selector: 'app-record-information',
  templateUrl: './record-information.component.html',
  styleUrls: ['./record-information.component.scss']
})
export class RecordInformationComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  _currentObject: RecordDoc;

  get currentObject(){
    return this._currentObject;
  }
  set currentObject(bankStatement: RecordDoc) {
    this._currentObject = bankStatement;
  }
  headerForm: FormGroup;
  public maxDate: Date;

  types: any[] = [
    {value: RecordType[RecordType.PASSPORT], text: 'translate.passport'},
    {value: RecordType[RecordType.CNI], text: 'translate.cni'},
    {value: RecordType[RecordType.BIRTHCERTIFICATE], text: 'translate.birth-certificate'},
    {value: RecordType[RecordType.GENERALCONDITION], text: 'translate.general-condition'},
  ];

  constructor(private fb: FormBuilder, protected translateService: TranslateService) {
    this._currentObject = new RecordDoc();
    self = this;

    const date = new Date();
    this.maxDate = new Date(date.getFullYear() , date.getMonth(), date.getDate());
  }

  ngOnInit(): void {
    this.initForm();
  }

  setObjectInView(object: RecordDoc) {

    if (object) {
      const type: {text: string, value: string} = this.types.find((e: {text: string, value: string}) => {
        return e.value === object.record.type;
      });

      this._currentObject = object;
      this._currentObject.record.type = type;
      this.headerForm.patchValue(this._currentObject.record);
      this.headerForm.get('end').setValue(this._currentObject.record.validityPeriod.end);
      this.headerForm.get('start').setValue(this._currentObject.record.validityPeriod.start);
    }
  }

  getData(): Promise<Record> {
    return new Promise((resolve, reject) => {
      let persistent: Record;
      persistent = Object.assign(this.currentObject.record, this.headerForm.value);
      persistent.type = this.headerForm.get('type').value.value;
      persistent.validityPeriod = {
        end: persistent.end,
        start: persistent.start
      };
      delete persistent.end;
      delete persistent.start;
      resolve(persistent);
    });
  }

  isValid() {
    let isValid = true;
    if (this.headerForm !== undefined && this.headerForm.invalid === true) {
      isValid = false;
    }
    return isValid;
  }

  initForm() {
    this.headerForm = this.fb.group({
      number: ['', [Validators.required]],
      type: ['', [Validators.required]],
      description: ['', [Validators.required]],
      end: [null, [Validators.required]],
      start: ['', [Validators.required]],
      isValid: [false, [Validators.required]]
    });
  }

  display(val: {value: string, text: string}) {
    let response = '';
    if (val) {
      response = self.translateService.instant(val.text);
    }
    return response;
  }
}
