<div class="banner">
  <img class="banner-bg-img blur" src="assets/images/1.jpg">
  <div class="banner-glass"></div>
  <div class="banner-body ">
    <div class="text-light banner-title">{{'app.about-us.label'|translate}}?</div>
  </div>
</div>


<div class="d-flex page-body mt-3">
  <div>
    <h1 class="section-title">{{"home.about-us.title.label"| translate}}</h1>
    <div class="paragraph">
      <p> {{"about-us.description-1.label"| translate}} </p>
      <p> {{"about-us.description-2.label"| translate}} </p>
      <p> {{"about-us.description-3.label"| translate}} </p>
      <p> {{"about-us.description-4.label"| translate}} </p>

      <p> {{"about-us.description-6.label"| translate}} </p>
    </div>
  </div>

  <div class=" ml-3 d-none d-sm-block">
    <img src="assets/images/new-images/about-us.jpg">
  </div>
</div>


<div class="mt-5 page-body text-white" style="background-color:var(--accent-color)">
  <h1 class="section-title" style="color: #FFF">{{"home.our-mission.title.label"| translate}} </h1>

  <ul class="h4">
    <li *ngFor="let mission of ourProjects" class="mt-3">{{mission | translate}} </li>
  </ul>

  <div class="py-3">
    <button mat-raised-button color="primary"
            (click)="ourProject()">{{"home.btn.our-project.label" | translate}}
    </button>
    <button class="ml-2" mat-raised-button (click)="register()">
      {{"home.btn.hire-me.label" | translate}}
    </button>
  </div>
</div>


<div class="mt-5 page-body">
  <h1 class="section-title">{{"our-project.valeur.title.label" | translate}}</h1>
  <div class="value-list">
    <div class="value-item" *ngFor="let value of values; let i = index">
      <div class="value-header">
        <div class="value-number">{{i + 1}}</div>
        <div class="value-title">{{ value.title | translate}}</div>
      </div>

      <div class="value-body">
        <div>{{ value.description | translate}}</div>
      </div>
    </div>
  </div>
</div>

<div class="page-body mt-5" style="background-color: var(--accent-color);color: #FFF">
  <h1 class="section-title" style="color: #FFF">{{ "our-team.tilte.label" | translate}}</h1>

  <div class="team">
    <div class="team-persona mt-4" *ngFor="let person of teams">
      <img  [src]="person.image" alt="persona" class="team-persona-img">
      <div class="team-persona-text">
        <div class="team-persona-name">{{person.name | translate}}</div>
        <div>{{ person.office | translate}}</div>
        <div>
          <a style="color:#FFF; user-select:none;"  [href]="'mailto:' + person.email">{{ person.email}}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer-bar></app-footer-bar>
