<mat-horizontal-stepper labelPosition="bottom" [linear]="'true'" #stepper>
  <mat-step
    [stepControl]="generalInformationForm"
    [completed]="StepGeneralInformationIsValid()"
    [optional]="StepGeneralInformationIsOptional"
    [editable]="StepGeneralInformationIsEditable">
    <form [formGroup]="generalInformationForm">
      <!--<ng-template matStepLabel>{{ 'register.partner.general.information.label' | translate }}</ng-template>-->
      <mat-card>
        <mat-card-header>
          <mat-card-title><mat-label class="label-stngModel ep-register">{{'register.partner.general.information.label' | translate}}</mat-label></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
              <mat-label>{{'register.partner.general.Information.form.name-of-association.label' | translate}}</mat-label>
              <input matInput formControlName="nameOfAssociation" required>
              <mat-error *ngIf="generalInformationForm.controls.nameOfAssociation.errors && generalInformationForm.controls.nameOfAssociation.hasError('required')">{{ "register.partner.general.Information.form.name-of-association.required" | translate}}</mat-error>
              <mat-error *ngIf="generalInformationForm.controls.nameOfAssociation.errors && !generalInformationForm.controls.nameOfAssociation.errors?.notEmpty">{{ "validator.not.empty" | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
              <mat-label>{{'register.partner.general.Information.form.full-address-of-the-head-office.label' | translate}}</mat-label>
              <input matInput formControlName="fullAddressOfTheHeadOffice">
              <mat-error *ngIf="generalInformationForm.controls.fullAddressOfTheHeadOffice.errors && generalInformationForm.controls.nameOfAssociation.hasError('required')">{{ "register.partner.general.Information.form.full-address-of-the-head-office.required" | translate}}</mat-error>
              <mat-error *ngIf="generalInformationForm.controls.fullAddressOfTheHeadOffice.errors && !generalInformationForm.controls.nameOfAssociation.errors?.notEmpty">{{ "validator.not.empty" | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
              <mat-label>{{'register.partner.general.Information.form.association-email-address.label' | translate}}</mat-label>
              <input matInput formControlName="associationEmailAddress" required>
              <mat-error *ngIf="generalInformationForm.controls.associationEmailAddress.errors && generalInformationForm.controls.nameOfAssociation.hasError('required')">{{ "register.partner.general.Information.form.association-email-address.required" | translate}}</mat-error>
              <mat-error *ngIf="generalInformationForm.controls.associationEmailAddress.errors && !generalInformationForm.controls.nameOfAssociation.errors?.email">{{ "pattern.email.error" | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
              <mat-label>{{'single.contact.person.phone.label' | translate}}</mat-label>
              <input required matInput formControlName="associationPhoneNumber">
              <mat-icon matSuffix>phone_android</mat-icon>
              <mat-error *ngIf="generalInformationForm.controls.associationPhoneNumber.errors">{{ 'format.phone.error' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
              <mat-label>{{'register.partner.general.Information.form.minimum-member.label' | translate}}</mat-label>
              <input required matInput formControlName="associationMinimumMember">
              <mat-error *ngIf="generalInformationForm.controls.associationMinimumMember.errors">{{ 'format.minimum.member.error' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <div class="stepper-btn">
            <button mat-raised-button color="primary" (click)="goToStepFirstRepresentative()" mat-button matStepperNext>
              {{ 'stepper.next.label' | translate }}
              <mat-icon>skip_next</mat-icon>
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-step>
  <mat-step
    [stepControl]="firstRepresentativePersonForm"
    [completed]="StepFirstRepresentativePersonIsValid()"
    [optional]="StepFirstRepresentativePersonIsOptional"
    [editable]="StepFirstRepresentativePersonIsEditable">
    <form [formGroup]="firstRepresentativePersonForm">
      <!--<ng-template matStepLabel>{{ 'register.partner.first.representative.label' | translate }}</ng-template>-->
      <app-single-contact-person [title]="'register.partner.first.representative.label'" #firstRepresentativePerson></app-single-contact-person>

      <br><mat-divider></mat-divider>


      <div class="steper-btns">
        <button mat-raised-button color="primary" mat-button matStepperPrevious>
          <mat-icon>skip_previous</mat-icon>{{ 'stepper.back.label' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="goToStepSecondRepresentative()" mat-button matStepperNext>
          {{ 'stepper.next.label' | translate }}
          <mat-icon>skip_next</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step
    [stepControl]="secondRepresentativePerson"
    [completed]="StepFirstRepresentativePersonIsValid()"
    [optional]="StepSecondRepresentativePersonIsOptional"
    [editable]="StepSecondRepresentativePersonIsEditable">
    <form [formGroup]="secondRepresentativePersonForm">
      <!--<ng-template matStepLabel>{{ 'register.partner.second.representative.label' | translate }}</ng-template>-->
      <app-single-contact-person [title]="'register.partner.second.representative.label'" #secondRepresentativePerson></app-single-contact-person>

      <br><mat-divider></mat-divider>

      <div class="steper-btns">
        <button mat-raised-button color="primary" mat-button matStepperPrevious>
          <mat-icon>skip_previous</mat-icon>{{ 'stepper.back.label' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="goToStepOption()" mat-button matStepperNext>
          {{ 'stepper.next.label' | translate }}
          <mat-icon>skip_next</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <!--<mat-step
    [stepControl]="optionGroup"
    [completed]="optionStepValid()"
    [optional]="stepOptionIsOptional"
    [editable]="stepOptionIsEditable" >
                      <form [formGroup]="optionGroup">
                        <ng-template matStepLabel>{{ 'register.sheet.option.label' | translate }}</ng-template>

                        <mat-card class="options-class">
                          <mat-card-header>
                            <mat-card-title><mat-label class="label-step-register">{{'register.sheet.option.label' | translate}}</mat-label></mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <label id="example-radio-group-label">{{'register.option.title' | translate}}</label>
                            <mat-radio-group formControlName="option"
                                             aria-labelledby="example-radio-group-label"
                                             class="example-radio-group">
                              <ng-container *ngFor="let option of options" >
                                <mat-radio-button class="example-radio-button"
                                                  (change)="radioChange($event)"
                                                  [value]="option">
                                  {{option.text | translate}}
                                </mat-radio-button>
                              </ng-container>
                            </mat-radio-group>
                            <div>{{favoriteOption?.description | translate}}</div>
                            <br>

                            <mat-divider></mat-divider>

                            <div class="steper-btns">
                              <button mat-raised-button color="primary" mat-button matStepperPrevious>
                                <mat-icon>skip_previous</mat-icon>{{ 'stepper.back.label' | translate }}
                              </button>

                              <button (click)="goToAccountInformation()"mat-raised-button color="primary" mat-button matStepperNext>
                                {{ 'stepper.next.label' | translate }}<mat-icon>skip_next</mat-icon>
                              </button>

                            </div>
                          </mat-card-content>
                        </mat-card>
                      </form>
                    </mat-step>-->
  <mat-step [stepControl]="accountInformationForm"
            [completed]="StepAccountInformationIsValid()"
            [optional]="StepAccountInformationIsOptional"
            [editable]="StepAccountInformationIsEditable" >
    <form [formGroup]="accountInformationForm">
      <!--<ng-template matStepLabel>{{ 'register.sheet.step5.label' | translate }}</ng-template>-->

      <mat-card class="">
        <mat-card-header>
          <mat-card-title><mat-label class="label-step-register">{{'register.sheet.step5.label' | translate}}</mat-label></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
              <mat-label>{{'register.form.password.label' | translate}}</mat-label>
              <input type="password" matInput formControlName="password" required >
              <mat-error *ngIf="accountInformationForm.controls.password.hasError('required')">{{ "register.form.password.required" | translate}}</mat-error>
              <mat-error *ngIf="accountInformationForm.controls.password.invalid">{{ "pattern.password.error" | translate}}</mat-error>

            </mat-form-field>

            <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
              <mat-label>{{'register.form.confirmPassword.label' | translate}}</mat-label>
              <input type="password" matInput  formControlName="confirmPassword" required >
              <mat-error *ngIf="accountInformationForm.controls.confirmPassword.hasError('required')">{{ "register.form.confirmPassword.required" | translate}}</mat-error>
              <mat-error *ngIf="accountInformationForm.controls.confirmPassword.getError('mismatch')">{{ "register.form.confirmPassword.mismatch.error" | translate}}</mat-error>
            </mat-form-field>

            <section class="example-section optios-class">
              <div class="align-ckeck">
                <mat-checkbox [labelPosition]="'after'"
                              [ngModelOptions]="{standalone: true}"
                              [(ngModel)]="checkTermsUse">
                </mat-checkbox>
                <span class="termsUse">
                  <b>{{ "register.form.termsUse.yes.label" | translate}}</b>,
                  {{ "register.form.termsUse.take.label" | translate}}
                  <button mat-button  style="color: blue;" class="link" (click)="startDownload('R.O.I.NR')">R.O.I</button>
                  {{ "register.form.termsUse.and.label" | translate}}
                  <button mat-button  style="color: blue;" class="link" (click)="startDownload('NR_Conditions_Generales')">{{ "register.form.termsUse.condition.label" | translate}}</button>

                  {{ "register.form.termsUse.respect.label" | translate}}
                </span>
              </div>
            </section>
            <section class="example-section optios-class">
              <div class="align-ckeck">
                <mat-checkbox [labelPosition]="'after'"
                              [ngModelOptions]="{standalone: true}"
                              [(ngModel)]="checkTermsUse2">
                </mat-checkbox>
                <span class="termsUse">
                  <b>{{ "register.form.termsUse.yes.label" | translate}}</b>,
                  {{ "register.form.termsUse.accept.label" | translate}}
                  <button mat-button  style="color: blue;" class="link" (click)="startDownload('NR_Conditions_Generales')">{{ "register.form.termsUse.condition2.label" | translate}}</button>

                  {{ "register.form.termsUse.confirm.label" | translate}}
                  <button mat-button  style="color: blue;" class="link" (click)="startDownload('NR_Declaration_relative_a_la_vie_priveee')">{{ "register.form.termsUse.policy.label" | translate}}</button>
                </span>
              </div>
            </section>
          </div>

          <br><mat-divider></mat-divider>

          <div class="steper-btns">
            <button mat-raised-button color="primary" mat-button matStepperPrevious>
              <mat-icon>skip_previous</mat-icon>{{ 'stepper.back.label' | translate }}
            </button>

            <button mat-raised-button mat-button
                    (click)="save()" class="register-btn">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              <mat-icon>check_circle</mat-icon> {{ 'stepper.register.label' | translate }}
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </mat-step>
</mat-horizontal-stepper>

<file-downloader hidden #downloader class="hide"
                 [open-in-tab]="openFileInTab"
                 file-type="application/pdf"
                 succedCodeMessage="document.download.succeed"
                 failCodeMessage="document.download.fail"
></file-downloader>
