import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  // tslint:disable-next-line:variable-name
  private _bufferValue: BehaviorSubject<number>;

  // tslint:disable-next-line:variable-name
  private _mode: BehaviorSubject<string>;

  // tslint:disable-next-line:variable-name
  private _value: BehaviorSubject<number>;

  // tslint:disable-next-line:variable-name
  private _visible: BehaviorSubject<boolean>;

  constructor(
    private router: Router
  ) {
    this.init();
  }

  bufferValue(): Observable<any> {
    return this._bufferValue.asObservable();
  }

  setBufferValue(value: number): void
  {
    this._bufferValue.next(value);
  }

  mode(): Observable<any> {
    return this._mode.asObservable();
  }

  setMode(value: 'determinate' | 'indeterminate' | 'buffer' | 'query'): void
  {
    this._mode.next(value);
  }

  value(): Observable<any> {
    return this._value.asObservable();
  }

  setValue(value: number): void
  {
    this._value.next(value);
  }

   visible(): Observable<any> {
    return this._visible.asObservable();
  }

  private init(): void
  {
    // Initialize the behavior subjects
    this._bufferValue = new BehaviorSubject(0);
    this._mode = new BehaviorSubject('indeterminate');
    this._value = new BehaviorSubject(0);
    this._visible = new BehaviorSubject(false);

    // Subscribe to the router events to show/hide the loading bar
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.show();
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel))
      .subscribe(() => {
        this.hide();
      });
  }

  show(): void
  {
    this._visible.next(true);
  }

  hide(): void
  {
    this._visible.next(false);
  }
}
