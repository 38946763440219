import {Person} from '../organisation/Person';
import {MemberShip} from './MemberShip';
import {ClassNameMap} from '../persistent/persistent';

export class Beneficiary  extends Person{
  memberShip: MemberShip;
  public description?: string;
  constructor() {
    super();
    this.memberShip = new MemberShip();
    this.classe = ClassNameMap.Beneficiary;
  }
}
