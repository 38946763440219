import { Directive, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { UserAppService } from 'app/services/user-service/user-app.service';

@Directive({
  selector: '[appIsLogin]'
})
export class IsLoginDirective implements OnInit {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private userAppService: UserAppService
  ) { }

  ngOnInit() {
    const isLogin = this.userAppService.isUserLoggedIn();

    if (isLogin && !this.hasView) {
      this.vcr.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isLogin && this.hasView) {
      this.vcr.clear();
      this.hasView = false;
    }
  }

}
