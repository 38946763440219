export class Persistent {
    public classe: string;
    public id?: number;
    public creationDate?: Date;
    public modificationDate?: Date;
    public toAddOrUpdate?: boolean;
    public toDelete?: boolean;
    public authorization?: string;
}

export class TaggedValue extends Persistent {
    public name?: string;
    public stringValue?: string;
    public integerValue?: number;
    public doubleValue?: string;
    public decimalValue?: string;

    constructor(){
        super();
        this.classe = ClassNameMap.TaggedValue;
        this.name = '';
        this.stringValue = '';
    }
}

export class Nameable extends Persistent {
    public name?: string;

    constructor(name?: string) {
        super();
        this.name = name;
        this.classe = ClassNameMap.Nameable;
    }
}

export class ClassNameMap {
    public static User = 'User';
    public static PotentialUser = 'PotentialUser';
    public static ConfirmUser = 'ConfirmUser';
    public static Persistent = 'Persistent';
    public static Country = 'Country';
    public static City = 'City';
    public static Address = 'Address';
    public static Party = 'Party';
    public static Person = 'Person';
    public static Profession = 'Profession';
    public static Religion = 'Religion';
    public static Member = 'Member';
    public static MemberShip = 'MemberShip';
    public static GodFather = 'GodFather';
    public static News = 'News';
    public static Donation = 'Donation';
    public static Amount = 'Amount';

    public static TaggedValue = 'TaggedValue';
    public static Nameable = 'Nameable';
    public static DownloadFile = 'DownloadFile';

    public static Basket = 'Basket';
    public static Organisation = 'Organisation';
    public static ContactPerson = 'ContactPerson';
    public static Company = 'Company';
    public static Payment = 'NoblePayment';
    public static Contact = 'Contact';

    public static Document = 'Document';
    public static Record = 'Record';
    public static RecordDoc = 'RecordDoc';
    public static RecordValidation = 'RecordValidation';

    public static AccountCredentials = 'AccountCredentials';
    public static Beneficiary = 'Beneficiary';
    public static Count = 'Count';

}
