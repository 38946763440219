<div class="banner">
  <img class="banner-bg-img blur" src="assets/images/1.jpg">
  <div class="banner-glass"></div>
  <div class="banner-body ">
    <div class="text-light banner-title">{{'app.contact-us.label'|translate}}</div>
  </div>
</div>

<div class="page-body">
<div class="d-flex justify-content-between flex-wrap">
  <div class="mt-5">
    <h2 class="section-title">{{"contact-us.page.title"| translate}}</h2>
    <div class="paragraph" style="max-width: 50vw">
      <p>{{"contact-us.form.description.label1"| translate}}</p>
      <p>{{"contact-us.form.description.label2"| translate}}</p>
      <p>{{"contact-us.form.description.label3"| translate}}</p>
    </div>

    <div class="d-flex justify-content-between flex-wrap">
      <div class="mt-5 px-3">
        <h2>{{"contact-us.link.contact.title.label" | translate}}</h2>

        <ul class="link-ul" style="user-select: none; pointer-events: none; margin: 0; line-height: 1.8">
          <li>{{ "footer.link.contact.number.label" | translate}}</li>
          <li>18 Rue Fouche 6060 GILLY</li>
          <li>{{ "footer.link.contact.email.label" | translate}}</li>
        </ul>
      </div>

      <div class="mt-5" #mapElement style="max-width: 86vw; width: 380px; height:400px;"></div>
    </div>

  </div>

  <div class="mt-3 p-3" style="width: 476px; max-width: 100%">
    <form [formGroup]="contactForm">
      <div class="font-size-24">{{"contact-us.form.title" | translate}}</div>

      <div class="mt-3">
        <mat-form-field appearance="outline">
          <mat-label>{{"contact-us.form.notes.label" | translate}}</mat-label>
          <textarea required name="notes" formControlName="notes" matInput type="text"
                    max-rows="4"></textarea>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>{{"contact-us.form.name.label" | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
          <input name="name" formControlName="name" matInput required>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>{{"contact-us.form.lastname.label" | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
          <input name="lastName" formControlName="lastName" matInput>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label>{{"contact-us.form.email.label" | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">email</mat-icon>
          <input name="email" formControlName="email" matInput type="email" required>
        </mat-form-field>
      </div>

      <div class="d-flex mt-3">
        <mat-checkbox [labelPosition]="'after'"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="checkTermsUse">
        </mat-checkbox>
        <div class="ml-2">
          <b>{{ "register.form.termsUse.yes.label" | translate}}</b>,
          {{ "contact.form.termsUse.take.label" | translate}}
        </div>
      </div>

      <div class="mt-3">
        <button
          mat-raised-button
          color="primary"
          class="save-button"
          (click)="save()"
          [disabled]="contactForm.invalid || !checkTermsUse"
          aria-label="SAVE">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{"contact-us.form.btn.sent.label" | translate}}
        </button>

        <button
          mat-button
          class="save-button"
          (click)="cancel()"
          aria-label="SAVE">
          {{"contact-us.form.btn.cancel.label" | translate}}
        </button>

      </div>
    </form>
  </div>
</div>
</div>
<app-footer-bar></app-footer-bar>
