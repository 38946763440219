<app-progress-bar-noble></app-progress-bar-noble>
<div class="container-fluid">

  <ng-container *ngIf="nobleConfig.layout.style === 'app-navbar'">
    <app-navbar
                [drawer]="sideNav"
                [isHandset$]="isHandset$"
                [deviceXs]="false"
                [userLogged]="(userLogged$ | async)">
    </app-navbar>

  </ng-container>


  <ng-container *ngIf="nobleConfig.layout.style === 'app-navbar-home'">
    <app-navbar-home>
    </app-navbar-home>
  </ng-container>

  <ng-container *ngIf="nobleConfig.layout.style === 'app-navbar-out'">
    <app-navbar-out
      [showLoginButton]="nobleConfig.layout.appNavbarOut.showLoginButton"
      [showRegisterButton]="nobleConfig.layout.appNavbarOut.showRegisterButton"
    ></app-navbar-out>
  </ng-container>

  <!-- <app-navbar-out class="{{ (((userLogged$ | async) === false) && ((isHome$ | async ) ===false)) ? '':'d-none'}}">
  </app-navbar-out> -->

  <mat-progress-bar class="mat-progress-bar" [mode]="progressBarMode" *ngIf="longProcess==true" ></mat-progress-bar>
  <mat-sidenav-container autosize >
    <mat-sidenav class="mat-elevation-z2 main-side-nav-bar" #sideNav mode="side" >
      <app-main-menu ></app-main-menu>
    </mat-sidenav>
    <mat-sidenav-content  class="app-content" (scroll)="onWindowScroll($event);">
      <main id="main-content" #mainContent>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
