import {Action} from '@ngrx/store';
import {MemberShip} from '../../../model/register/MemberShip';

export enum RegisterActionType {
  REGISTER_START= '[Register] Start',
  REGISTER_SUCCESS= '[Register] Success',
  REGISTER_FAILURE= '[Register] Failure',
}

export class Register implements Action{
  readonly type = RegisterActionType.REGISTER_START;
  constructor(public payload: MemberShip) {}
}

export class RegisterSuccess implements Action{
  readonly type = RegisterActionType.REGISTER_SUCCESS;
  constructor(public payload: {email: string, username: string}) {}
}

export class RegisterFailure implements Action{
  readonly type = RegisterActionType.REGISTER_FAILURE;
  constructor(public payload: Error | string) {}
}

export type RegisterActions =
  | Register
  | RegisterSuccess
  | RegisterFailure;
