import { Component, Input, Output, ElementRef,
         OnInit, ViewChild, OnChanges, SimpleChange,
         ChangeDetectorRef, EventEmitter } from '@angular/core';

import { TranslateService, TranslatePipe} from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Base64 } from 'app/services/utils';

import * as $ from 'jquery';
import {HttpService} from '../../services/http-service/http.service';
import {NotificationService, NotificationType} from '../../services/notification-service/notification.service';

let self;

@Component({
  // tslint:disable-next-line:component-selector
    selector: 'file-downloader',
    templateUrl: './template.html',
    styleUrls: ['./style.css']
})

export class FileDownloaderComponent implements OnInit, OnChanges {
    /**
     * Title of the button
     */
    @Input() title: string;
    /**
     * link to download document
     */
    @Input() link;
     /**
      * Indicate if the file must be open in a new tab
      */
       // tslint:disable-next-line:no-input-rename
    @Input('open-in-tab') openInTab = false;
     /**
      * Indicate if the file must be open in a new tab
      */
       // tslint:disable-next-line:no-input-rename
    @Input('file-type') fileType: string ;
    /**
     * code of the message to show to user if download fail
     */
    @Input() failCodeMessage;
    /**
     * code of the message to show to user if download succed
     */
    @Input() succedCodeMessage;
    /**
     * css glyphicon of the button
     */
      // tslint:disable-next-line:no-input-rename
    @Input('glyphicon') cssClass;
     /**
      * search criteria
      */
    @Input() criteria;

     /**
      * Emit a Boolean
      * true if the download succed
      * False if not
      */
    @Output() event: EventEmitter<any> = new EventEmitter();

    private base64FileLink: any;

    private fileName: string;

    /**
     * Translation pipe
     */
    public translatePipe: TranslatePipe;

    /**
     * Default constructor
     */
    constructor(private sanitizer: DomSanitizer, private el: ElementRef, private translate: TranslateService,
                public translateService: TranslateService, private msgService: NotificationService,
                // tslint:disable-next-line:variable-name
                private _ref: ChangeDetectorRef, private httpService: HttpService<any>) {
        self = this;
        this.translatePipe = new TranslatePipe(translate, _ref);
    }

    ngOnInit() {
        if (this.title == null) {
            this.title = 'file-downloader.default.title';
        }
        if (this.failCodeMessage == null) {
            this.failCodeMessage = 'file-downloader.fail.error';
        }
        if (this.succedCodeMessage == null) {
            this.succedCodeMessage = 'file-downloader.succed.message';
        }
    }
    ngOnChanges(changes: {[propName: string]: SimpleChange}) {
        // console.log(changes);
        if (changes.failCodeMessage) {
            // console.log(changes["failCodeMessage"]);
        }
    }

    // public downloadFile(event) {
    //     this.httpService.get(this.link, true).subscribe(
    //         (file) => {
    //           //console.log("file", file);
    //           if (file != null) {
    //              if (file === true) {
    //                 let cmp = this;
    //                 setTimeout( () => {
    //                     cmp.event.emit(true);
    //                     this.msgService.log(cmp.succedCodeMessage, NotificationType.SUCCESS);
    //                 }, 1000);
    //               }else {
    //                 this.launchDownload(file);
    //              }
    //           } else {
    //             console.log('file is null');
    //             this.event.emit(false);
    //             this.msgService.log(this.failCodeMessage, NotificationType.ERROR);
    //           }
    //         },
    //         (error) => {
    //             this.event.emit(false);
    //             try {
    //                 this.msgService.log(this.translatePipe.transform(error), NotificationType.ERROR);
    //             }catch (e) {
    //                 console.error(e);
    //                 this.msgService.log(this.failCodeMessage, NotificationType.ERROR);
    //             }
    //         }
    //     );
    // }

    /**
     * Download report
     */
    public downloadFile(event) {
        console.log(this.criteria);
        this.httpService.post(this.criteria, this.link, true).subscribe(
            (file) => {
             // console.log("file", file);
              if (file != null) {
                 if (file === true) {
                    const cmp = this;
                    setTimeout( () => {
                        cmp.event.emit(true);
                        this.msgService.log(cmp.succedCodeMessage, NotificationType.SUCCESS);
                    }, 1000);
                  }else {
                    this.launchDownload(file);
                 }
              } else {
               // console.log('file is null');
                this.event.emit(false);
                this.msgService.log(this.failCodeMessage, NotificationType.ERROR);
              }
            },
            (error) => {
                this.event.emit(false);
                try {
                    this.msgService.log(this.translatePipe.transform(error), NotificationType.ERROR);
                }catch (e) {
                    console.error(e);
                    this.msgService.log(this.failCodeMessage, NotificationType.ERROR);
                }
            }
        );
    }

    public download() {
        this.downloadFile({});
    }

    protected launchDownload(file) {
        const linkTag = $(this.el.nativeElement).find('.download-link');
        let document = null;
        if (file.value != null) {
            document = { name: file.name, value: file.value, type: file.type };
        } else {
            document = { name: 'document', value: file };
        }
        document.type = this.fileType ? this.fileType : document.type;
        const url = Base64.convertBase64ToBlob(document.value, document.type);
        // console.log(" url ", url);
        if (url != null) {
            (linkTag[0] as any).href = window.URL.createObjectURL(url);
            this.fileName = document.name;
            const cmp = this;
            setTimeout(() => {
                (linkTag[0] as HTMLElement).click();
                cmp.event.emit(true);
                this.msgService.log(cmp.succedCodeMessage, NotificationType.SUCCESS);
               // console.log("a_link,", (<HTMLElement> linkTag[0]))
            }, 1000);
        } else {
            this.event.emit(false);
            this.msgService.log(this.failCodeMessage, NotificationType.ERROR);
        }
    }

    public launchFileDownload(file) {
        this.launchDownload(file);
    }
}
