import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { Validators, AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'app/model/security/user';
import { NotificationService } from 'app/services/notification-service/notification.service';
import {Observable, Subscription} from 'rxjs';
import { LoginState, selectAuthenticationState } from 'app/store/login/login.state';
import {select, Store} from '@ngrx/store';
import {State} from '../../store/login/reducers/authentication.reducer';
import {Login, Logout} from '../../store/login/actions/authentication.action';
import {ConfigService} from '@noble/services/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  hide = true;
  returnUrl: string;
  errors: string[];
  urlAccount: string;

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  user = new User();

  constructor(
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private store: Store<LoginState>,
              private router: Router,
              private msgService: NotificationService,
              private _configService: ConfigService,
  ) {
    this.authenticationState$ = this.store.select(selectAuthenticationState);

    // Configure the layout
    this._configService.config = {
      layout: {
        style   : 'app-navbar-out',
        appNavbarOut: {
          showLoginButton: false,
          showRegisterButton: true,
        },
      }
    };
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
          username: ['', [Validators.required, Validators.pattern(/[+a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)]],
          password: ['', Validators.required]
      });

      // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin !== null && this.loading === true) {
        this.loading = false;
      }
    });
  }

  ngOnDestroy()  {
    this.authenticationSubscription.unsubscribe();
  }

  // convenience getter for easy access to form fields
  get formCtrl() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;

    if (this.isValid()) {
    this.msgService.showSpiner.emit(true);
    this.store.dispatch(new Login(this.user));
    }
  }

  getObjectFromView(){
      this.user.userName = this.loginForm.get('username').value;
	     this.user.login = this.loginForm.get('username').value;
      this.user.password = this.loginForm.get('password').value;
  }

  /**
   *  Is user parameters valid?
   */
  public isValid() {
      this.getObjectFromView();
      return this.loginForm.valid;
  }

}
