import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NotificationService, NotificationType} from '../../../../services/notification-service/notification.service';
import {RecordDoc} from '../../../../model/document/record-doc';
import {HttpService} from '../../../../services/http-service/http.service';
import {ConfigService} from '../../../../../@noble/services/config.service';
import {ModalComponentService} from '../../../base/modal/modal.component';
import {RecordDocumentComponent} from '../record-edit/record-document/record-document.component';
import {DocumentViewerComponent} from '../../document-viewer/document-viewer.component';

@Component({
  selector: 'app-record-show',
  templateUrl: './record-show.component.html',
  styleUrls: ['./record-show.component.scss'],
  providers: [
    { provide: 'USE_MODAL', useValue: true },
  ]
})
export class RecordShowComponent implements OnInit {

  recordDoc: RecordDoc;

  constructor(private route: ActivatedRoute,
              public modalService: ModalComponentService,
              protected msgService: NotificationService,
              private _configService: ConfigService,
              private httpService: HttpService<RecordDoc>) {
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const data: {id?: string | number} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'document/record-doc', true).subscribe( (res: RecordDoc) => {
          this.recordDoc = res;
        }, (errors) => {
          this.msgService.log('record-doc.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  view(event?) {
    const modalData = {modalTitle: 'record-doc.view.label', record: this.recordDoc};
    const dialogRef = this.modalService.openModal(DocumentViewerComponent, modalData.modalTitle, null, {
      disableClose: false,
      data: modalData.record.name,
      width: '50%'
    });
  }

  edit(event) {
    const modalData = {modalTitle: 'record-doc.update.label', record: this.recordDoc};
    const dialogRef = this.modalService.openModal(RecordDocumentComponent, modalData.modalTitle, null, {
        disableClose: false,
        data: modalData.record,
        width: '50%'
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === 'Unknown Error' || result === 'Internal Server Error') {
          this.msgService.log('record-doc.show.update.error', NotificationType.SUCCESS);
        } else {
          this.msgService.log('record-doc.show.update.success', NotificationType.SUCCESS);
          this.view();
        }
      }
    });
  }

}
