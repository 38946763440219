import {GlobalVariables} from '../services/global-variables';
import { Persistent } from './persistent/persistent';

export class DataPage<T extends Persistent> {
    public page: number;
    public pageSize: number;
    public datas: T[];
    public totalItemCount: number;
}

export class SearchCriteria {
    public page?: number;
    public pageSize?: number;
    public value: string;
    public medicalAgentId?: number;
    public countryId?: number;
    public memberShipId?: number;

    constructor(value?: string) {
      this.value = value ? value : '';
      this.page = 0;
      this.pageSize = GlobalVariables.MAX_ITEM_PER_PAGE;
    }
}

export class RecordCriteria extends SearchCriteria{
  public userId?: number;
  public personId?: number;
  public isValid?: boolean;
  public type?: number;
  constructor(value?: string) {
    super(value);
  }
}
