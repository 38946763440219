import {ClassNameMap, Persistent} from '../persistent/persistent';
import {User} from '../security/user';

export class Record extends Persistent {
  public user?: User;
  public number?: string;
  public description?: string;
  public type?: string | any;
  public isValid?: boolean;
  public validityPeriod?: {
    end?: Date,
    start?: Date,
  };
  public end?: Date;
  public start?: Date;

  constructor() {
    super();
    this.user = new User();
    this.validityPeriod = {
      end: null,
      start: null
    };
    this.classe = ClassNameMap.Record;
  }
}

export enum RecordType {
  PASSPORT,
  CNI,
  BIRTHCERTIFICATE,
  GENERALCONDITION,
}
