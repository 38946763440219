import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from '../../../@noble/services/config.service';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../model/security/user';
import {MemberShip} from '../../model/register/MemberShip';
import {State} from '../../store/login/reducers/authentication.reducer';
import {RegisterService} from '../../services/register/register.service';
import {Store} from '@ngrx/store';
import {LoginState, selectAuthenticationState} from '../../store/login/login.state';

interface CardItem {
  title: string;
  subtitle: string;
  imageSrc: string;
  button: {
    text: string;
    ref: string
  };
}

const CARD_ITEMS: CardItem[] = [
  {
    title: 'profile.card.password.title',
    subtitle: 'profile.card.password.subtitle',
    imageSrc: 'assets/images/profile/password.png',
    button: {
      text: 'profile.card.password.button.text',
      ref: '/manage/password'
    }
  },
  {
    title: 'profile.card.about.title',
    subtitle: 'profile.card.about.subtitle',
    imageSrc: 'assets/images/profile/about.svg',
    button: {
      text: 'profile.card.about.button.text',
      ref: '/manage/about'
    }
  },
  {
    title: 'profile.card.beneficiary.title',
    subtitle: 'profile.card.beneficiary.subtitle',
    imageSrc: 'assets/images/profile/beneficiary',
    button: {
      text: 'profile.card.about.beneficiary.text',
      ref: '/manage/beneficiary'
    }
  }
];

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  currentUser: User;
  currentMemberShip: MemberShip;

  public items: CardItem[] = [];

  constructor(
    private _configService: ConfigService,
    private registerService: RegisterService,
    private store: Store<LoginState>,
  )
  {
    this.authenticationState$ = this.store.select(selectAuthenticationState);
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };
    this.items = CARD_ITEMS;
    this.currentMemberShip = new MemberShip();
  }

  ngOnInit(): void
  {
    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.currentUser = state.user.user;
        this.registerService.findMemberShipByLogin(this.currentUser.login).then(memberShip => {
          this.currentMemberShip = memberShip;
        });
      }
    });
  }

  ngOnDestroy()
  {
    this.authenticationSubscription.unsubscribe();
  }

}
