import { City } from './City';
import { Persistent, ClassNameMap } from '../persistent/persistent';

export class Address extends Persistent {
    public number?: string;
    public street?: string;
    public latitude?: string;
    public longitude?: string;
    public locality?: City;
    public addressString?: string;

    constructor() {
        super();
        this.locality = new City();
        this.classe = ClassNameMap.Address;
    }
}
