import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {ConfigService} from '@noble/services/config.service';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../model/security/user';
import {SpinnerOverlayService} from '../../../services/spinner-overlay/spinner-overlay.service';
import {GlobalVariables} from '../../../services/global-variables';
import {ConfirmUser} from '../../../model/security/confirm-user';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private msgService: NotificationService,
    private _configService: ConfigService,
    private userService: UserAppService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerOverlayService: SpinnerOverlayService,
  ) {
    this._configService.config = {
      layout: {
        style   : 'app-navbar-out',
        appNavbarOut: {
          showLoginButton: true,
          showRegisterButton: true,
        },
      }
    };
  }

  static password;

  resetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  email: string;
  hide = true;
  hideConfirm = true;

  user: User;

  static validConfirmPassWord(control: AbstractControl): ValidationErrors | null{
    if (ResetPasswordComponent.password && control.value !== ResetPasswordComponent.password){
      return {mismatch: true};
    }
    return null;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.isValid()) {
      this.msgService.showSpiner.emit(true);
      this.userService.resetPassword(this.user).subscribe(res => {
        this.loading = false;
        this.goToLogin();
        this.msgService.log('reset.password.confirm.success', NotificationType.SUCCESS);
      }, error => {
        this.loading = false;
        this.msgService.log('reset.password.confirm.error', NotificationType.ERROR);
      });
    }
  }

  getObjectFromView(){
    if (this.user === undefined) { this.user = new User(); }
    this.user.password = this.resetPasswordForm.get('password').value;
    this.user.confirmPassword = this.resetPasswordForm.get('confirmPassword').value;
  }

  public isValid() {
    this.getObjectFromView();
    return this.resetPasswordForm.valid;
  }


  goToLogin() {
    this.router.navigate(['login']);
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      const data: {id?: string, token?: string} = (params as any).params;
      if (data.id !== undefined && data.token !== undefined && data.id !== null && data.token !== null) {
        this.spinnerOverlayService.show();
        const confirmUser = new ConfirmUser(Number.parseInt(data.id, 10), data.token);
        this.userService.resetPasswordConfirm(confirmUser).subscribe(user1 => {
          this.user = user1;
          this.spinnerOverlayService.hide();
          this.msgService.log('reset.password.confirm.info', NotificationType.INFO);
        }, error => {
          this.msgService.log('reset.password.confirm.error', NotificationType.ERROR);
          this.spinnerOverlayService.hide();
          this.goToLogin();
        });
      } else {
        this.router.navigate(GlobalVariables.HOME_ROUTE);
      }
    });

    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, ResetPasswordComponent.validConfirmPassWord]]
    });

    this.resetPasswordForm.get('password').valueChanges.subscribe(value => {
      ResetPasswordComponent.password = value;
    });
  }

}
