<p class="notice ml-2">{{'other.payment0.notice' | translate}}</p>
<ul class="link-ul">
  <li style="margin-bottom: 2px;">
    <span class="link-icon2">{{'other.payment.name' | translate}}</span>
    <span class="link-item2">
       Noble Retour asbl
    </span>
  </li>

  <li style="margin-bottom: 2px;">
    <span class="link-icon2">IBAN</span>
    <span class="link-item2">
      {{ "footer.link.contact.iban.label" | translate}}
    </span>
  </li>

  <li style="margin-bottom: 2px;">
    <span class="link-icon2">BIC</span>
    <span class="link-item2">
      {{ "footer.link.contact.bic.label" | translate}}
    </span>
  </li>
</ul>

<p class="notice ml-2">{{'other.payment1.notice' | translate}}</p>
<p class="notice ml-2">{{'other.payment2.notice' | translate}}</p>
