<div class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'document.list.header.title'"></app-header>
    </mat-card-content>
  </mat-card>
  <div id="cards" class="cards-width">
    <ng-template [ngIf]="'true'" *ngFor="let element of tableDataSource.data">
      <mat-card class="file-list-card">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{element.name | translate}}</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="{{element.imageBackground}}" alt="Photo">
        <mat-card-content>
          <p>{{element.description | translate}}</p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="download(element)">
            <mat-icon color="primary">get_app</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </ng-template>
  </div>
</div>

<file-downloader hidden #downloader class="hide"
                 [open-in-tab]="openFileInTab"
                 file-type="application/pdf"
                 succedCodeMessage="document.download.succeed"
                 failCodeMessage="document.download.fail"
></file-downloader>
