import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {NotificationService} from '../../../services/notification-service/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {Observable, of} from 'rxjs';
import {FailureGetRule, RuleActions, RuleActionType, StartGetRule, SuccessGetRule} from '../actions/rule.action';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

@Injectable()
export class RuleEffect {
  constructor(private actions: Actions,
              private router: Router,
              private message: NotificationService,
              private translate: TranslateService,
              private userAppService: UserAppService) {}

  @Effect()
  RuleGetBegin: Observable<any> = this.actions.pipe(
    ofType(RuleActionType.START_GET_RULE),
    map((action: StartGetRule) => action.payload),
    switchMap(date => {
      return this.userAppService.rules()
        .pipe(
          map((value ) => {
            const rules = value.authority ? value.authority : [];
            return new SuccessGetRule(rules);
          }),
          catchError((error) => {
            this.router.navigate(['home']);
            return of(new FailureGetRule('rule.get.failure'));
          })
        );
    }),
  );

  @Effect({ dispatch: false })
  SuccessGetRule: Observable<any> = this.actions.pipe(
      ofType(RuleActionType.SUCCESS_GET_RULE),
      tap((data: SuccessGetRule) => {
        const pageAfterLogin = this.userAppService.getHomeUrl();
        this.router.navigate(pageAfterLogin);
      })
  );
}
