<div  class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'family.edit.header.title'">
        <button
          mat-raised-button
          routerLink="/family/list"
          color="primary"
          aria-label="button with a add one icon">
          <mat-icon>list</mat-icon>
          <mat-label>{{"family.list.header.title" | translate}}</mat-label>
        </button>
      </app-header>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card col-xs-12  col-sm-12">
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content >
      <form [formGroup]="familyMemberForm" name='member-edit-form'>
        <div class="row">
          <mat-form-field class="col-xs-12 col-sm-6 col-md-4" >
            <input type="text" placeholder='{{"member.name.label" | translate}}'
                   [formControl]="familyMemberForm.get('name')" name='name'
                   matInput maxlength="255" [value]="currentObject.name" >
            <mat-error *ngIf="familyMemberForm.get('name').errors">{{ "member.name.empty.error" | translate}}</mat-error>
          </mat-form-field>
          <mat-form-field class="col-xs-12 col-sm-6 col-md-4" >
            <input type="text" placeholder='{{"member.surname.label" | translate}}'
                   [formControl]="familyMemberForm.get('surname')" name='surname'
                   matInput maxlength="255" [value]="currentObject.surname" >
            <mat-error *ngIf="familyMemberForm.get('surname').errors">{{ "member.surname.empty.error" | translate}}</mat-error>
          </mat-form-field>
          <mat-form-field  class="col-xs-12 col-sm-6 col-md-4">
            <mat-label>{{ 'register.form.birth.date.label' | translate }}</mat-label>
            <input [max]="maxDate" [formControl]="familyMemberForm.get('birthdate')" matInput #birthDateMatInput [matDatepicker]="picker"
                   placeholder="{{ 'register.form.birth.date.placeholder' | translate }}"
                   [(ngModel)]="currentObject.birthdate" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="col-xs-12 col-sm-6 col-md-4">
            <mat-label>{{ 'family-member.form.gender.label' | translate }}</mat-label>
            <input required
                   [formControl]="familyMemberForm.get('gender')" matInput
                   [matAutocomplete]="autoGender">
            <mat-autocomplete #autoGender="matAutocomplete" [displayWith]="display">
              <mat-option  *ngFor="let option of genders" [value]="option">
                {{ display(option) }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="col-xs-12 col-sm-6 col-md-4">
            <mat-label>{{ 'family-member.form.relation.label' | translate }}</mat-label>
            <input required
                   [formControl]="familyMemberForm.get('relationType')" matInput
                   [matAutocomplete]="autoRelation">
            <mat-autocomplete #autoRelation="matAutocomplete" [displayWith]="display">
              <mat-option  *ngFor="let option of relations" [value]="option">
                {{ display(option) }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field  class="col-xs-12 col-sm-4">
            <mat-label>{{ 'register.form.age.label' | translate }}</mat-label>
            <input matInput placeholder="{{ 'register.form.age.placeholder' | translate}}"
                   disabled='true' [value]="age">
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <app-buttons-bar *ngIf="buttons"
                       [functionality]="'family-member.edit'"
                       [actions]="buttons"
                       (action)="btnActionClick($event)">
      </app-buttons-bar>
    </mat-card-actions>
  </mat-card>
</div>
