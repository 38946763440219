<div class="row donation">
    <div class="col-12 col-sm-6 col-md-4">
        <mat-card class="donation-zone">
            <div class="mat-card-header-zone">
                <mat-card-header>
                    <mat-card-title> {{"make-donation.my-donation.form.title"| translate}}</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
            </div>
            
            <mat-card-content>
                <div class="give-once">
                    <div class="donations-frequency">
                        <h2 class="frequency-title">
                            {{"make-donation.my-donation.one-off.label"| translate}}
                        </h2>
    
                        <div class="amount-items">
                            <button *ngFor="let amount of giveOnce" (click)="selectAmount(amount)">
                                {{amount.label}}
                            </button>
                        </div>
                    </div>
                    <br>
    
                    <div class="align-items">
                        <mat-form-field appearance="outline">
                            <mat-label>{{"make-donation.my-donation.free-amount.label" | translate}}</mat-label>
                            <input type="text" [formControl]="amountControl" matInput rows="1">
                            <!-- <mat-error *ngIf="amountControl.errors">{{"news-management.editor.title.empty.error" | translate}}</mat-error> -->
                        </mat-form-field> 
                        <span class="currency">{{ "€" | translate}}</span>
                    </div>
                </div>

                <mat-divider></mat-divider>
                <br>

                <p style="text-align: center;">
                    {{"make-donation.my-donation.or.label"| translate}}
                </p>

                <div class="every-month">
                    <div class="donations-frequency">
                        <div class="frequency-title">
                            <h2>
                                {{"make-donation.my-donation.monthly.label"| translate}}
                            </h2>
                        </div>
                       
    
                        <div class="amount-items">
                            <button *ngFor="let amount of everyMonth" (click)="selectAmount(amount)">
                                {{amount.label}}
                            </button>
                        </div>
                    </div>
                    <br>
    
                    <div class="align-items">
                        <mat-form-field appearance="outline">
                            <mat-label>{{"make-donation.my-donation.free-amount.label" | translate}}</mat-label>
                            <input type="text" [formControl]="amountControl" matInput rows="1">
                            <!-- <mat-error *ngIf="amountControl.errors">{{"news-management.editor.title.empty.error" | translate}}</mat-error> -->
                        </mat-form-field> 
                        <span class="currency">{{ "€" | translate}}</span>
                    </div>
                </div>
               
            </mat-card-content>
        </mat-card>
    
    </div>

    <div class="col-12 col-sm-6 col-md-4">
        <mat-card class="details-zone">
            <div class="mat-card-header-zone">
                <mat-card-header>
                    <mat-card-title> {{"make-donation.my-details.form.title"| translate}}</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
            </div>
            
            <mat-card-content>
                <div class="row">
                    <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                        <mat-label>{{'make-donation.my-details.email.label' | translate}}</mat-label>
                        <input matInput [formControl]="emailControl" required >
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error *ngIf="emailControl.getError('required')">{{"make-donation.my-details.email.required"  | translate}}</mat-error>
                        <mat-error *ngIf="emailControl.getError('pattern')">{{ "pattern.email.error" | translate}}</mat-error>
                    </mat-form-field>

                    <mat-slide-toggle class="donor-toggle"
                        [color]="color"
                        [checked]="donorChecked"
                        (change)="donorChange($event)">
                        <span class="donor-toggle-label">
                            {{ 'make-donation.my-details.donor-type.toogle.label' | translate }}
                        </span>
                    </mat-slide-toggle>

                    <ng-container *ngIf="!donorChecked" class="col-xs-12 col-sm-12 col-md-12">
                        <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                            <mat-label>{{'make-donation.my-details.civility.label' | translate}}</mat-label>
                            <mat-select [formControl]="civilityControl" required>
                                <mat-option *ngFor="let civility of civilityList" [value]="civility.value">
                                    {{civility.text | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="civilityControl.invalid">{{ "make-donation.my-details.civility.required" | translate}}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                            <mat-label>{{'make-donation.my-details.surname.label' | translate}}</mat-label>
                            <input matInput [formControl]="surnameControl" required >
                            <mat-error *ngIf="surnameControl.invalid">{{ "make-donation.my-details.surname.required" | translate}}</mat-error>
                        </mat-form-field>
                    </ng-container>
                    
                    <ng-container *ngIf="donorChecked" class="col-xs-12 col-sm-12 col-md-12">
                        <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                            <mat-label>{{'make-donation.my-details.company.label' | translate}}</mat-label>
                            <input matInput [formControl]="nameControl" required >
                            <mat-error *ngIf="nameControl.invalid">{{ "make-donation.my-details.name.required" | translate}}</mat-error>
                        </mat-form-field>
                    </ng-container>
                   
                    <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                        <mat-label>{{'make-donation.my-details.name.label' | translate}}</mat-label>
                        <input matInput [formControl]="nameControl" required >
                        <mat-error *ngIf="nameControl.invalid">{{ "make-donation.my-details.name.required" | translate}}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                        <mat-label>{{'make-donation.my-details.street.label' | translate}}</mat-label>
                        <input matInput [formControl]="streetControl" required >
                        <mat-error *ngIf="streetControl.invalid">{{ "make-donation.my-details.street.required" | translate}}</mat-error>
                    </mat-form-field>

                    <div class="row col-xs-12 col-sm-12 col-md-12">
                        <mat-form-field class="col-xs-6 col-sm-6 col-md-6">
                            <mat-label>{{'make-donation.my-details.street-number.label' | translate}}</mat-label>
                            <input matInput [formControl]="streetNumberControl" required >
                            <mat-error *ngIf="streetNumberControl.invalid">{{ "rmake-donation.my-details.street-number.required" | translate}}</mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="col-xs-6 col-sm-6 col-md-6">
                            <mat-label>{{'make-donation.my-details.box.label' | translate}}</mat-label>
                            <input matInput [formControl]="boxControl" >
                            <mat-error *ngIf="boxControl.invalid">{{ "make-donation.my-details.box.required" | translate}}</mat-error>
                        </mat-form-field>
                    </div>
                   
                    <div class="row col-xs-12 col-sm-12 col-md-12">
                        <mat-form-field class="col-xs-6 col-sm-6 col-md-6">
                            <mat-label>{{'make-donation.my-details.postal-code.label' | translate}}</mat-label>
                            <input matInput [formControl]="postalControl" required >
                            <mat-error *ngIf="postalControl.invalid">{{ "make-donation.my-details.postal-code.required" | translate}}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-xs-6 col-sm-6 col-md-6">
                            <mat-label>{{"make-donation.my-details.city.label" | translate}} *</mat-label>
                            <mat-chip-list #cityChipList>
                              <mat-chip
                                *ngFor="let city of cities"
                                selectable="true"
                                [removable]="true"
                                [value]="city"
                                (removed)="removeCity()">
                                {{displayCityFn(donation.donor.address.locality)}}
                                <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
                              </mat-chip>
                              <input
                                [disabled]="false"
                                #cityInput
                                [formControl]="cityControl"
                                [matAutocomplete]="autoCity"

                                [matChipInputFor]="cityChipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="addCity($event)" required>
                            </mat-chip-list>
                              <mat-autocomplete #autoCity="matAutocomplete" 
                                (optionSelected)="selectedCity($event)" 
                                [displayWith]="displayCityFn">
                                <mat-option
                                    *ngFor="let city of cities"
                                    [value]="city">
                                    {{displayCityFn(city)}}
                                </mat-option>
                            </mat-autocomplete>
                              <mat-error *ngIf="cityControl.invalid">{{ "make-donation.my-details.city.required" | translate}}</mat-error>
                          </mat-form-field>
                    </div>
                   
                    <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                        <mat-label>{{"make-donation.my-details.country.label" | translate}} *</mat-label>
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let country of countries"
                            selectable="true"
                            [removable]="true"
                            [value]="country"
                            (removed)="removeCountry()">
                            {{displayCountryFn(donation.donor.address.locality.country)}}
                            <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
                          </mat-chip>
                          <input
                            [disabled]="false"
                            #residenceInput
                            [formControl]="countryControl"
                            [matAutocomplete]="autoCountry"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addCountries($event)" required>
                        </mat-chip-list>
                        <mat-autocomplete #autoCountry="matAutocomplete" 
                            (optionSelected)="selectedCountry($event)" 
                            [displayWith]="displayCountryFn">
                          <mat-option
                            *ngFor="let country of countries"
                            [value]="country">
                            {{displayCountryFn(country)}}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="countryControl.getError('required') != null">
                            {{ "make-donation.my-details.country.required" | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field  class="col-xs-12 col-sm-4 col-md-4">
                        <mat-label>{{ "make-donation.my-details.phone-code.label" | translate }}</mat-label>
                        <input matInput disabled='true' [value]="phoneCountryCode" />
                    </mat-form-field>
                    <mat-form-field class="col-xs-12 col-sm-8 col-md-8">
                        <mat-label>{{"make-donation.my-details.phone.label" | translate}}</mat-label>
                        <input matInput [formControl]="phoneControl" required >
                        <mat-icon matSuffix>phone_android</mat-icon>
                        <mat-error *ngIf="phoneControl.getError('required')">{{ "register.form.phone.required" | translate}}</mat-error>
                        <mat-error *ngIf="phoneControl.getError('pattern')">{{ "pattern.phone.error" | translate}}</mat-error>
                    </mat-form-field>
                </div>
               
            </mat-card-content>
        </mat-card>
    
    </div>
   
    <div class="col-12 col-sm-6 col-md-4">
        <mat-card class="donation-zone">
            <div class="mat-card-header-zone">
                <mat-card-header>
                    <mat-card-title> {{"make-donation.my-payment.form.title"| translate}}</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
            </div>
            
            <mat-card-content>
               <label>{{"make-donation.my-payment.form.description"| translate}}</label>
               <br>

               <button mat-raised-button mat-button (click)="validate()" class="validate-btn">
                    <mat-icon>check_circle</mat-icon> 
                    {{ 'make-donation.my-payment.btn.validate.label' | translate }}
                </button>
            </mat-card-content>
        </mat-card>
    
    </div>
</div>
