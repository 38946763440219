import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ProfileService} from '../../../../services/profile.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfigService} from '@noble/services/config.service';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {NotificationService, NotificationType} from '../../../../services/notification-service/notification.service';
import {UserAppService} from '../../../../services/user-service/user-app.service';
import {Router} from '@angular/router';
import {NobleValidators} from '../../../base/validators/noble-validators';
import { Location } from '@angular/common';
import {AccountCredentials} from '../../../../model/security/confirm-user';
import {LoginService} from '../../../../services/login/login.service';

@Component({
  selector: 'app-profile-password',
  templateUrl: './profile-password.component.html',
  styleUrls: ['./profile-password.component.scss']
})
export class ProfilePasswordComponent implements OnInit, OnDestroy
{

  static  newPassword: any;

  password: any;
  passwordForm: FormGroup;
  loading = false;
  submitted = false;
  oldPassword: string;
  checkOldPassword: boolean;
  hide = true;
  hideConfirm = true;

  private _unsubscribeAll: Subject<any>;
  name = '';

  static validConfirmPassWord(control: AbstractControl): ValidationErrors | null{
    if (ProfilePasswordComponent.newPassword && control.value !== ProfilePasswordComponent.newPassword){
      return {mismatch: true};
    }
    return null;
  }

  constructor(
    private _profileService: ProfileService,
    private _configService: ConfigService,
    private _formBuilder: FormBuilder,
    private _msgService: NotificationService,
    private _userService: UserAppService,
    private _router: Router,
    private _location: Location,
    protected _loginService: LoginService,
  )
  {
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };
    this.checkOldPassword = false;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void
  {
    this._profileService.passwordChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(password => {
        this.password = password;
      });

    this.passwordForm = this._formBuilder.group({
      oldPassword: ['', [Validators.required, NobleValidators.notEmpty]],
      newPassword: ['', [Validators.required, NobleValidators.notEmpty, Validators.minLength(8)]],
      confirmation: ['', [Validators.required, NobleValidators.notEmpty,
        ProfilePasswordComponent.validConfirmPassWord, Validators.minLength(8)]]
    });

    this.passwordForm.get('newPassword').valueChanges.subscribe(value => {
      ProfilePasswordComponent.newPassword = value;
    });

    this.name = this._location.getState()['n'];
  }

  ngOnDestroy(): void
  {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getObjectFromView(): Promise<AccountCredentials>
  {
    return new Promise<AccountCredentials>((resolve, reject) => {
      const credential = new AccountCredentials();
      credential.currentPassword = this.passwordForm.get('oldPassword').value;
      credential.newPassword = this.passwordForm.get('newPassword').value;
      credential.confirmation = this.passwordForm.get('confirmation').value;
      resolve(credential);
    });
  }

  public isValid()
  {
    return this.passwordForm.valid;
  }

  gotToAccount()
  {
    this._router.navigate(['manage/account']);
  }


  goToReset()
  {
    this._router.navigate(['reset-password']);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.isValid()) {
      this._msgService.showSpiner.emit(true);
      this.getObjectFromView().then(obj => {
        this._userService.checkPassword(obj).subscribe(res => {
          this.loading = false;
          this.checkOldPassword = true;
          this._msgService.log('profile.password.old-password.confirm.success', NotificationType.SUCCESS);
          this._loginService.logOut();
        }, error => {
          this.gotToAccount();
          this._msgService.log('profile.password.old-password.confirm.error', NotificationType.SUCCESS);
        });
      });
    }
  }
}
