import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'app/services/user-service/user-app.service';
import {NotificationService, NotificationType} from '../../services/notification-service/notification.service';
import {Store} from '@ngrx/store';
import {LoginState} from '../../store/login/login.state';
import {ProgressBarService} from '../../services/progress-bar/progress-bar.service';
import {FileDownloaderComponent} from '../file-downloader/file-downloader.component';
import {DownloadFile} from '../../model/file/downloadFile';
import {TranslateService} from '@ngx-translate/core';
import {SearchCriteria} from '../../model/data-page';
import {ClassNameMap} from '../../model/persistent/persistent';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  slides: {
    image: string, title?: string, description?: string,
    buttons?: { routerLink: string[], label: string, element?: DownloadFile}[]
  }[];

  /**
   * A reference on download component
   */
  @ViewChild('downloader')
  downloader: FileDownloaderComponent;
  openFileInTab = true;
  criteria: any | SearchCriteria = new SearchCriteria();

  nobleLink: {routerLink: string, label: string}[];
  usefulLink: {routerLink: string, label: string}[];
  infosLink: {routerLink: string, label: string}[];
  services: {title: string, content: string} [] = [
    {title: 'home.our-services-3.title.label', content: 'home.our-services-3.description.label'},
    {title: 'home.our-services-6.title.label', content: 'home.our-services-1.description.label'},
    {title: 'home.our-services-2.title.label', content: 'home.our-services-2.description.label'},
    {title: 'home.our-services-4.title.label', content: 'home.our-services-4.description.label'},
    {title: 'home.our-services-1.title.label', content: 'home.our-services-1.description.label'},
    {title: 'home.our-services-5.title.label', content: 'home.our-services-5.description.label'},
  ];


  menuPosition: any;
  constructor( private route: ActivatedRoute,
               private router: Router,
               private store: Store<LoginState>,
               private msgService: NotificationService,
               private nobleProgressBarService: ProgressBarService,
               private _activatedRoute: ActivatedRoute,
               protected translateService: TranslateService,
               private userService: UserAppService) {
    this.userService.isHomeURL.emit(true);
  }
  ngAfterViewInit() {
    this.userService.isHomeURL.emit(true);
  }

  ngOnInit(): void {
    // Slider Images
    this.slides = [
      {
        image: 'assets/images/new-images/carousel-1.jpg',
        title: 'slider.slide1.title.label',
        description: 'slider.slide1.description.label',
        buttons:  [
          {
            routerLink: ['register'],
            label: 'slider.btn.adhere.label',
          },
          {
            routerLink: ['login'],
            label: 'slider.btn.account.label',
          }
        ]
      },
      {
        image: 'assets/images/projet4.jpeg',
        title: 'slider.slide2.title.label',
        description: 'slider.slide2.description.label',
        buttons:  [
          {
            routerLink: ['home/about-us'],
            label: 'slider.btn.about-us.label',
          },
          {
            routerLink: ['home/contact-us'],
            label: 'slider.btn.contact-us.label',
          }
        ]
      },
      {
        image: 'assets/images/new-images/carousel-3.jpg',
        title: 'slider.slide3.title.label',
        description: 'slider.slide3.description.label',
        buttons:  [
          {
            routerLink: ['home/faq'],
            label: 'slider.btn.faq.label',
            element: {
              value: 'Foire_aux_questions',
              classe: ClassNameMap.DownloadFile,
            }
          },
          /*{
            routerLink: ['home/make-donation'],
            label: 'slider.btn.make-donation.label',
          }*/
        ]
      },
    ];

  }

  protected quickBtnClicked(btn: { routerLink: string[], label: string, element?: DownloadFile}) {
    if (btn.element) {
      this.beginDownload(btn.element);
    } else {
      this.router.navigate(btn.routerLink);
    }
  }

  aboutUs(){
    this.router.navigate(['home/about-us']);
  }


  ngOnDestroy(){
    this.userService.isHomeURL.emit(false);
  }

  beginDownload(element: DownloadFile) {
    this.criteria.lang = this.translateService.currentLang;
    this.criteria.value = element.value;
    this.criteria.code = element.value + '.pdf';

    this.downloader.criteria = this.criteria;
    this.downloader.criteria.classe = 'CriteriaSearch';
    this.downloader.link = 'organisation/documents/print';
    this.downloader.downloadFile({});
    this.downloader.event.subscribe( (wellDone) => {
      if (wellDone === false) {
        this.msgService.log('form.print.error', NotificationType.ERROR);
      }
    });
  }

}
