<div id="mail-confirm" fxLayout="column" xmlns="http://www.w3.org/1999/html">

  <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="mail-confirm-form">

      <div class="logo">
        <mat-icon class="mat-accent s-96">email</mat-icon>
      </div>

      <div class="title">{{'confirm.email.title' | translate}}</div>

      <div class="subtitle">
        <p>
          {{'confirm.email.subtitle' | translate}}<b>{{userName}}, </b>.
        </p><br/>
        <p>
          {{'confirm.email.subtitle.pa' | translate}}
        </p><br/><br/>

        <p>
          {{'confirm.email.subtitle.end' | translate}}
        </p>
      </div>

      <div class="message">
        <a class="link" [routerLink]="'/login'">{{'confirm.email.message' | translate}}</a>
      </div>

    </div>

  </div>

</div>
<app-footer-bar></app-footer-bar>
