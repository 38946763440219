<div class="app">
  <form [formGroup]="stripeForm">
    <mat-form-field appearance="outline">
      <mat-label>Name on Card</mat-label>
      <input matInput formControlName="cardName"/>
    </mat-form-field>

    <!--<mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput formControlName="cardEmail" placeholder="name@exemple.com"/>
    </mat-form-field>-->

    <ngx-stripe-card
      [options]="cardOptions"
      [elementsOptions]="elementsOptions"></ngx-stripe-card>

    <button class="stripe-btn" mat-button type="submit" (click)="createToken()">
      <span style="color: white">Pay {{depositAmount | amount}}</span>
    </button>
  </form>

  <p class="notice">{{'stripe.notice' | translate}}</p>
</div>
