<div  class="app-record-doc-show">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'record-doc.show.header.title'">
        <button
          mat-raised-button
          routerLink="/record-doc/list"
          color="primary"
          aria-label="button with a add one icon">
          <mat-icon>list</mat-icon>
          <mat-label>{{"record-doc.list.header.title" | translate}}</mat-label>
        </button>
      </app-header>
    </mat-card-content>
  </mat-card>
  <ng-template [ngIf]="recordDoc">
    <div class="row" style="padding: 10px;">
      <mat-card class="record-doc-card">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>{{recordDoc.record.number | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>{{recordDoc.record.description | translate}}</p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="edit($event)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-button (click)="view($event)">
            <mat-icon color="primary">visibility</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-template>
</div>
