import {
  Component,
  OnInit,
  Inject,
  Injectable,
  ViewContainerRef,
  ViewChild,
  ComponentRef,
  ComponentFactoryResolver,
  EventEmitter,
  Type,
  AfterViewInit,
  ElementRef,
  Renderer2,
  AfterViewChecked,
  OnDestroy
} from '@angular/core';
import {IFormActionBarButton} from '../buttons-bar/buttons-bar.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/overlay';
/**
 * Component made to render Modal
 */
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit , AfterViewInit, AfterViewChecked, OnDestroy{

  // The zone where the modal will be rendered
  @ViewChild('modalContainer', { read: ViewContainerRef })
  public viewContainerRef: ViewContainerRef;

  // Reference on modal component
  public componentRef: ComponentRef<any>;

  // parameters of buttons bar to render on the bottom of the modal
  public footerButtonBar: IButtonBarConfig = null;

  // Title of the modal
  public headerTitle: string;

  // is header close button must be shown?
  public headerCloseButton: boolean;

  constructor( private el: ElementRef,
               private renderer: Renderer2,
               public dialogRef: MatDialogRef<any>,
               @Inject(MAT_DIALOG_DATA) public data: IModalData,
               private resolver: ComponentFactoryResolver) {
      if (data.title) {
        this.headerTitle = data.title;
        this.headerCloseButton = true;
      }
      if (data.footer) {
        this.footerButtonBar = data.footer.buttonBar;
      }
      console.log({headerTitle: this.headerTitle, footerButtonBar: this.footerButtonBar});
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const factory = this.resolver.resolveComponentFactory(this.data.component as Type<IModalComponent | IShowModalComponent>);
    this.componentRef = this.viewContainerRef.createComponent(factory); // Create Modal component
    console.log({componentRef: this.componentRef});
  }

  ngAfterViewChecked() {
    this.setContentMaxHeight();
  }

  // To set the maxHeight of the modal content and make it scrollable
  protected setContentMaxHeight() {
    // Set maximum modal content size
    const dialogContent = (this.el.nativeElement as HTMLElement).querySelector('.mat-dialog-content');
    if (dialogContent) {
      const modal = this.el.nativeElement.parentElement;
      const modalHeight = (modal as any).offsetHeight;
      const maxHeight = (modalHeight - 135) + 'px';
      this.renderer.setStyle(dialogContent, 'max-height', maxHeight);
    }
  }

  ngOnDestroy() {// Destroy the modal component when this component is destroy
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // Catch bouton click event
  public btnBarActionClick(event): void {
    const data = (this.componentRef.instance as any).getData();
    this.dialogRef.close({value: data, btn: event});
  }

  // Close the modal when the user click on close button
  public onCloseClicked(event): void {
    this.dialogRef.close(null);
  }
}

export abstract class IModalComponent {
  public abstract getData();
}

export abstract class IShowModalComponent {
}

/**
 * Service called to render Modal
 */
@Injectable({
  providedIn: 'root'
})
export class ModalComponentService {

  constructor( public dialog: MatDialog){}

  public openModal(component: ComponentType<IModalComponent | IShowModalComponent>,
                   title: string,
                   footer: FooterModalConfig,
                   config?: MatDialogConfig<any>): MatDialogRef<any>{
    if (!config){ config = {}; }
    config.data = { component, title, footer, data: config.data };
    const dialogRef = this.dialog.open(ModalComponent, config);
    return dialogRef;
  }

}

/**
 * Configuration pattern class
 */
export class NkapMatDialogConfig {
  public dialog: MatDialogRef<any>;
  public events: EventEmitter<any>;
  constructor(dialog: MatDialogRef<any> ){
    this.dialog = dialog;
    this.events = new EventEmitter<any>();
  }
}

/**
 * Pattern of data gave to the Modal
 */
export interface IModalData{
  component: ComponentType<IModalComponent>;
  title?: string;
  footer?: FooterModalConfig;
  data?: MatDialogConfig;
}

/**
 * Header data pattern
 */
export interface HeaderModalConfig {
  title?: string;
  showCloseBtn?: boolean;
}

/**
 * Footer data pattern
 */
export interface FooterModalConfig {
  buttonBar: IButtonBarConfig;
}

/**
 * Footer buton configuration pattern
 */
export interface IButtonBarConfig {
  functionality?: string ;
  functionalities?: string[];
  action?: string ;
  buttons?: IFormActionBarButton[];
}
