export enum Role {
  ADMIN = 'ADMIN-NOBLE',
  DASHBOARD = 'DASHBOARD',
  MAKE_DEPOSIT = 'MAKE DEPOSIT',
  ASSOCIATION = 'ASSOCIATION',
  CREATE_MEMBER = 'CREATE MEMBER',
  SHOW_MEMBER = 'SHOW MEMBER',
  TRANSACTION = 'TRANSACTION',
  NOTIFICATION = 'NOTIFICATION',
  SHOW_PARTNER = 'SHOW PARTNER',
  SHOW_FAMILY = 'SHOW FAMILY'
}
