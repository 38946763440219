import { Persistent, ClassNameMap } from '../persistent/persistent';

export class News extends Persistent {
    public tilte?: string;
    public description?: string;
    public image?: string;

    constructor(){
        super();
        this.classe = ClassNameMap.News;
    }

}
