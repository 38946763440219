import {RuleActions, RuleActionType} from '../actions/rule.action';

export interface UserRuleState {
  userRules: string[];
  errorGetRule: string | Error;
}

export const initialiseRuleState: UserRuleState = {
  userRules: [],
  errorGetRule: null
};

export function userRuleReducer(state = initialiseRuleState, action: RuleActions) {
  switch (action.type) {
    case RuleActionType.SUCCESS_GET_RULE:
      return {
        ...state,
        userRules: action.payload,
        errorGetRule: null
      };

    case RuleActionType.FAILURE_GET_RULE:
      return {
        ...state,
        userRules: [],
        errorGetRule: action.payload
      };
    default:
      return state;
  }
}
