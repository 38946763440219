import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormAction, IFormActionBarButton } from 'app/components/base/buttons-bar/buttons-bar.component';
import { News } from 'app/model/organisation/news';
import { HttpService } from 'app/services/http-service/http.service';
import { NotificationService, NotificationType } from 'app/services/notification-service/notification.service';

@Component({
  selector: 'app-news-management-editor',
  templateUrl: './news-management-editor.component.html',
  styleUrls: ['./news-management-editor.component.scss']
})
export class NewsManagementEditorComponent implements OnInit {

  news = new News();

  newsFormGroup: FormGroup;

  titleControl = new FormControl(null, [Validators.required]);

  descriptionControl = new FormControl();

  buttons: IFormActionBarButton[];

  constructor(private httpService: HttpService<any>,public router: Router,
    private msgService: NotificationService, private route: ActivatedRoute) {

    this.buttons = [
      {id: "save", value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: []},
      {id: "cancel", value: FormAction.VIEW, icon: {type : 'cancel'},
        text: 'btn.cancel.label', disabled: false, functionalities: []}
    ];
  }

  ngOnInit(): void {
    this.newsFormGroup = new FormGroup({
      titleControl: this.titleControl,
      descriptionControl: this.descriptionControl,
    })
  }

  getObjectFromView(): News{
    return this.news;
  }

  btnActionClick(event) {
    if(event && event.id === this.buttons[0].id){
      this.save();
    } else if(event && event.id === this.buttons[1].id){
      this.cancel();
    }
  }

  save(){
    if (this.isValid()) {
     console.log(this.news);
    } else {
      this.msgService.log("news-management.data.invalid", NotificationType.ERROR);
    }
  }

  /**
   * Cancel change and redirect to the navigation screen
   */
  cancel(){
    this.msgService.confirm("stepper.cancel.confirm.text").subscribe( (result) => {
      if(result === true){
        this.router.navigate(['news-management/navigate', {search:true}]);
      }
    });
  }

  isValid(){
    this.getObjectFromView();

    return this.newsFormGroup.valid;
  }
}
