import { Injectable, EventEmitter } from '@angular/core';
import {Component, Inject} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponentService } from 'app/components/base/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public newMessage: EventEmitter<any> = new EventEmitter();

  public showSpiner: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private _snackBar: MatSnackBar , public dialog: MatDialog,
              public modalService: ModalComponentService,
              public translate: TranslateService) { }

  log(message: string, messageType?: NotificationType) {
    this._snackBar.open(
      this.translate.instant(message), 'x',
      {
        panelClass: NotificationTypeBgClassMapping[NotificationType[messageType]],
        data: {message}
      }
    );
  }

  confirm(message: string, title?: string, config?: any): Observable<boolean> {
    return new Observable( (observer) => {
      const dialogConfig = config ? config : {
          width: '65%',
          height: '35%',
          position: {left: '10%', right: '10%'},
        disableClose: true
      };
      dialogConfig.data = {title: title ? title : 'notification.service.confirm.title', message};
      const dialogRef = this.modalService.openModal(ConfirmDialogComponent,
          undefined, undefined, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          observer.next(result === 'ok' ? true : false);
          observer.complete();
      });
    });
  }
}

@Component({
  selector: 'your-snack-bar',
  template: `<div class="row">
              <div class="col-xs-9 col-md-9">{{data.message  | translate}} </div>
              <div class="col-xs-1 col-md-1 ">
                <button>x</button>
              </div>
              <div class="col-xs-1 col-md-1 ">
                <button>x</button>
              </div>
             </div>`,
})
export class SnackBarMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: DialogInputData) { }
}
interface DialogInputData {
  message: string;
}

@Component({
  selector: 'confirm-dialog',
  template: `
  <div class="warning-header">
    <div mat-dialog-title >
        <mat-icon class="text-warning icon" >report_problem</mat-icon>
        {{ data.title | translate}}
    </div >
  </div >
  <div mat-dialog-content>
      <div class="confirm-text">
          {{data.message | translate}}
      </div>
  </div>
  <div  mat-dialog-actions class="d-flex justify-content-center">
          <div class="float-right">
            <button mat-stroked-button (click)="onNoClick()" cdkFocusInitial class="text-light bg-secondary mat-button">
            <mat-icon >cancel</mat-icon>
            {{"confirm-dialog.cancel.btn.label" | translate}}</button>
            <button mat-stroked-button [mat-dialog-close]="'ok'" class="text-light bg-secondary mat-button">
            <mat-icon >check</mat-icon>
            {{"confirm-dialog.ok.btn.label" | translate}}</button>
          </div>
  </div>
    `,
})
export class ConfirmDialogComponent {

  public data: {title?: string, message: string};

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any) {
      this.data = modalData.data;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-progress-bar',
  template: ``,
})
export class AppProgressBarComponent {
  constructor() { }
}
const NotificationTypeBgClassMapping = {
  ERROR : 'bg-danger',
  INFO : 'bg-info',
  WARNING : 'bg-warning',
  SUCCESS : 'success-snackbar'
};
export enum NotificationType {
  ERROR,
  INFO,
  WARNING,
  SUCCESS
}
