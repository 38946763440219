import {
  Component,
  OnInit, Input,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef, OnChanges, SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {RuleState, selectRuleState} from '../../../store/rule/rule.state';
import {Observable, Subscription} from 'rxjs';
import {UserRuleState} from '../../../store/rule/reducers/rule.reducer';
import {UserAppService} from '../../../services/user-service/user-app.service';

@Component({
  selector: 'app-buttons-bar',
  templateUrl: './buttons-bar.component.html',
  styleUrls: ['./buttons-bar.component.css']
})
export class ButtonsBarComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * List of actions/buttons gived by the user
   */
  @Input() actions: IFormActionBarButton[] = [];

  /**
   * This emitter will inform of all btn click
   */
  @Output() action: EventEmitter<any> = new EventEmitter();
  /**
   * The fonctionality where the buttons bar is used
   */
  @Input() functionality: string;
  /**
   * The fonctionalities where the buttons bar is used
   */
  @Input() functionalities: string[];

  /**
   * The action representing the form on witch the action bar is
   */
  @Input() formAction: string;
  /**
   * List of allowed actions
   */
  public allowActions: IFormActionBarButton[];

  rule$: Observable<any>;
  ruleSubscription: Subscription;

  /**
   * Default constructor
   */
  constructor(private cd: ChangeDetectorRef,
              private el: ElementRef,
              private userAppService: UserAppService,
              protected ruleStore: Store<RuleState>,
              public translateService: TranslateService) {

    this.rule$ = this.ruleStore.select(selectRuleState);
  }

  initButtonsList() {
    this.buildAllowedButtons();
  }

  ngOnInit() {
    this.initButtonsList();

    this.ruleSubscription = this.rule$.subscribe((state: UserRuleState) => {
      this.initButtonsList();
    });
  }

  ngOnDestroy() {
    this.ruleSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.actions || changes.functionality) {
      this.buildAllowedButtons();
    }
  }

  private btnClick(event, action) {
      if ( this.action != null && !action.disabled ) { // we emit the action only if the button is active
          this.action.emit(action);
      }
  }

  /**
   * Method to change the status of buttons
   */
  public setButtonStatus(btn: IFormActionBarButton) {
      const newButtons: IFormActionBarButton[] = [].concat(this.allowActions);
      const buttonFound: any = newButtons.find( (button) => button.id === btn.id);
      if (buttonFound) {
          buttonFound.disabled = btn.disabled;
      }
      this.allowActions = newButtons;
      this.cd.detectChanges();
  }

  /**
   * Method to change the status of buttons
   */
  public setButtonsStatus(statusList: IFormActionBarButton[]) {
      if ( statusList != null) {
          const newButtons: IFormActionBarButton[] = [].concat(this.allowActions);
          statusList.forEach( (status) => {
            const buttonFound: any = newButtons.find( (button) => button.id === status.id);
            if (buttonFound) {
                  buttonFound.disabled = status.disabled;
              }
          });
          this.allowActions = newButtons;
          this.cd.detectChanges();
      }
  }

  protected buildAllowedButtons() {
      this.allowActions = [];
      if (this.actions){
        this.allowActions = this.actions.filter(b => {
            b = this.allowAccessToFunctionality(b);
            return b !== null;
        });
      }
      this.cd.detectChanges();
  }

  public allowAccessToFunctionality(button: IFormActionBarButton) {
    if (!button.functionalities || button.functionalities.length === 0) { return  button; }

    const functionalitiesAllowed = button.functionalities.filter(f => {
      return (this.userAppService.hasAccess(f) === true);
    });

    if (functionalitiesAllowed.length === 0) { return null; }

    return button;
  }

  getToolTip(action: IFormActionBarButton): string {
    if (action.toolTip) { return action.toolTip; }
    return action.text;
  }
}

export interface IFormActionBarButton {
  id: string;
  name?: string;
  value?: string;
  number?: number;
  text?: string;
  cssClass?: string;
  disabled?: boolean;
  functionalities?: string[];
  icon?: {type: string, color?: string};
  data?: any;
  toolTip?: string;
}

export class FormAction {
  public static CREATE = 'CREATE';
  public static VIEW = 'VIEW';
  public static DELETE = 'DELETE';
  public static UPDATE = 'UPDATE';
  public static VALIDATE = 'UPDATE';
  public static SEND_MAIL = 'SEND_MAIL';
  public static CLOSE = 'CLOSE';
  public static CANCEL = 'CANCEL';
  public static PRINT = 'PRINT';
  public static NEXT = 'NEXT';
  public static BACK = 'BACK';
}
