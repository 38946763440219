import {Record} from './record';
import {ClassNameMap} from '../persistent/persistent';
import {Document} from './document';

export class RecordDoc extends Document {
  public record?: Record;

  constructor() {
    super();
    this.record = new Record();
    this.classe = ClassNameMap.RecordDoc;
  }
}
