import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Member} from '../../../../model/organisation/Member';
import {ProfilePictureComponent} from '../../../base/profile-picture/profile-picture.component';
import {environment} from '../../../../../environments/environment';
import {FileUploadService} from '../../../../services/document/file.upload.service';

@Component({
  selector: 'app-family-member-picture',
  templateUrl: './family-member-picture.component.html',
  styleUrls: ['./family-member-picture.component.scss']
})
export class FamilyMemberPictureComponent implements OnInit, AfterViewInit, OnChanges {

  public editEnabled = true;
  public picurl: string;

  newFileName: string;

  @ViewChild('profilePictureComponent') profilePictureComponent: ProfilePictureComponent;

  @Input() member: Member;

  @Output() update: EventEmitter<Member> = new EventEmitter<Member>();
  @Output() endUpdate: EventEmitter<Member> = new EventEmitter<Member>();
  @Output() removePicture: EventEmitter<Member> = new EventEmitter<Member>();

  @Input() path: string = environment.apiHost + environment.downloadImg;

  loading = false;
  disabledSave = true;
  @Input()useRemove = false;

  constructor(private fileUploadService: FileUploadService) { }

  ngOnInit(): void {
    this.endUpdate.subscribe(m => {
      this.member = m;
      this.updatePicture();
      this.disableLoading();
    });
  }

  ngAfterViewInit() {
    this.profilePictureComponent.addingFile.subscribe(() => {
      this.profilePictureComponent.onSubmit().then(res => {
        this.newFileName = res.name;
        this.save();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['member']) {
      this.updatePicture();
    }
  }
  updatePicture() {
    if (this.member && this.member.image !== null && this.member.image !== undefined) {
      this.picurl = `${this.path}/${this.member.image}`;
    }
  }

  disableLoading() {
    this.loading = false;
  }

  public clear() {
    this.picurl = '';

    /**
     * We should subscribe to this event and remove image inside a member entity.
     */
    this.removePicture.emit(this.member);
  }

  save() {
    this.loading =  true;
    const entity = this.member;
    entity.image = this.newFileName;
    this.update.emit(entity);
  }

}
