import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'app/services/user-service/user-app.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  ourProjects: string [];
  values: {title: string, description: string}[];
  teams: {image?: string, name: string, office: string, email?: string}[];

  constructor(private route: ActivatedRoute, private router: Router,
              private userService: UserAppService) {
    this.userService.isHomeURL.emit(true);
  }

  ngOnInit(): void {
    this.ourProjects = [
      'home.our-mission-1.description.label',
      'home.our-mission-2.description.label',
      'home.our-mission-3.description.label',
      'home.our-mission-4.description.label',
    ];

    this.values = [
      {
        title: 'our-project.valeur.title-item2.label',
        description: 'our-project.valeur.description-item2.label'
      },
      {
        title: 'our-project.valeur.title-item4.label',
        description: 'our-project.valeur.description-item4.label'
      },
      {
        title: 'our-project.valeur.title-item1.label',
        description: 'our-project.valeur.description-item1.label'
      },

      {
        title: 'our-project.valeur.title-item3.label',
        description: 'our-project.valeur.description-item3.label'
      },

    ];

    this.teams = [
      {
        image: 'assets/images/regiss.jpg',
        name: 'our-team.president.name.label',
        office: 'our-team.president.office.label',
        email: 'k.regiss@noble-retour.org'
      },
      /*{
        image: 'assets/images/florence.jpg',
        name: 'our-team.vice-president.name.label',
        office: 'our-team.vice-president.office.label',
        email: 'm.florence@noble-retour.org'
      },*/
      {
        image: 'assets/images/bertrand.jpg',
        name: 'our-team.treasurer.name.label',
        office: 'our-team.treasurer.office.label',
        email: 'n.bertrand@noble-retour.org'
      },
      {
        image: 'assets/images/cyriaque.jpg',
        name: 'our-team.founding-member.name.label',
        office: 'our-team.founding-member.office.label',
        email: 'n.cyriaque@noble-retour.org'
      },
      {
        image: 'assets/images/honorine.jpg',
        name: 'our-team.secretary.name.label',
        office: 'our-team.secretary.office.label',
        email: 't.honorine@noble-retour.org'
      },
    ];

  }

  becomeMember(){
  }

  ourProject(){
    this.router.navigate(['home/our-project']);
  }

  register(){
    this.router.navigate(['register']);
  }

  ngOnDestroy(){
    this.userService.isHomeURL.emit(false);
  }

}
