import {AfterViewInit, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {Member} from '../../../model/organisation/Member';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '../../../../@noble/services/config.service';
import {HttpService} from '../../../services/http-service/http.service';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {RegisterService} from '../../../services/register/register.service';
import {FamilyMemberUpdateComponent} from '../family-member-edit/family-member-update/family-member-update.component';
import {FamilyMemberPictureComponent} from '../family-member-edit/family-member-picture/family-member-picture.component';

@Component({
  selector: 'app-family-member-show',
  templateUrl: './family-member-show.component.html',
  styleUrls: ['./family-member-show.component.scss']
})
export class FamilyMemberShowComponent implements OnInit, AfterViewInit {

  member: Member;

  @ViewChild('familyMemberUpdateComponent') updateComponent: FamilyMemberUpdateComponent;
  @ViewChild('familyMemberPictureComponent') pictureComponent: FamilyMemberPictureComponent;

  constructor(private fb: FormBuilder,
              private httpService: HttpService<Member>,
              private router: Router,
              private route: ActivatedRoute,
              private msgService: NotificationService,
              protected translateService: TranslateService,
              private _configService: ConfigService,
              private userService: UserAppService,
              private registerService: RegisterService) {
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };

    this.member = new Member();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'organisation/member', true).subscribe( (object) => {
          this.member = object;
          this.updateComponent.endUpdate.next(object);
        }, (errors) => {
          this.msgService.log('member.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  ngAfterViewInit() {
    this.updateComponent.update.subscribe(entity => {
      this.up(entity, this.updateComponent.endUpdate);
    });

    this.pictureComponent.update.subscribe(entity => {
      this.up(entity, this.pictureComponent.endUpdate);
    });
  }

  up(persistence: Member, ev: EventEmitter<Member>) {
    this.registerService.saveMember(persistence).then(res => {
      this.msgService.log('member.edit.save.succed', NotificationType.SUCCESS);
      ev.next(res);
    }).catch(err => {
      console.log(err);
      this.msgService.log('member.edit.save.fail', NotificationType.ERROR);
    });
  }

  getYear(element: Member) {
    if (element === undefined || element === null || element.birthdate === undefined || element.birthdate === null) {
      return 0;
    }
    return  new Date(Date.now()).getFullYear() - new Date(element.birthdate).getFullYear();
  }
}
