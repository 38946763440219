import * as register from './reducers/register.reducer';
import {createFeatureSelector} from '@ngrx/store';

export interface RegisterState {
  registerState: register.MemberShipState;
}

export const registerReducer = {
  register: register.memberShipReducer
};

export const selectRegisterState = createFeatureSelector<RegisterState>('register');
