import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Register, RegisterActionType, RegisterFailure, RegisterSuccess} from '../actions/registerActions';
import {map, switchMap, tap} from 'rxjs/operators';
import {RegisterService} from '../../../services/register/register.service';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class RegisterEffect {
  constructor(private actions: Actions,
              private router: Router,
              private message: NotificationService,
              private translate: TranslateService,
              private registerService: RegisterService) {
  }

  @Effect()
  RegisterStart: Observable<any> = this.actions.pipe(
    ofType(RegisterActionType.REGISTER_START),
    map((action: Register) => action.payload),
    switchMap(memberShip => {
      return this.registerService.saveMemberShip(memberShip).then(result => {
        return new RegisterSuccess({email: memberShip.emailAddress, username: memberShip.surname});
      }).catch(err => {
        return new RegisterFailure(err);
      });
    })
  );

  @Effect({ dispatch: false })
  RegisterSuccess: Observable<any> = this.actions.pipe(
    ofType(RegisterActionType.REGISTER_SUCCESS),
    tap((action: RegisterSuccess) => {
      console.log(action.payload);
      if ( typeof action.payload.email === 'string') {
        this.message.log('register.form.save.success', NotificationType.SUCCESS);
        this.router.navigate(['home/email']);
      }
    })
  );

  @Effect({ dispatch: false })
  RegisterFailure: Observable<any> = this.actions.pipe(
    ofType(RegisterActionType.REGISTER_FAILURE),
    tap((action: RegisterFailure) => {
      if ( typeof action.payload === 'string') {
        this.translate.get(action.payload).subscribe(val => {
          this.message.log(val, NotificationType.ERROR);
        });
      } else {
        throw new Error('error.server');
      }
    })
  );
}
