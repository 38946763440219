import {Person} from '../organisation/Person';
import {Country} from '../country/Country';
import {City} from '../country/City';
import {GodFather} from './GodFather';
import {User} from '../security/user';
import {ClassNameMap, Persistent} from '../persistent/persistent';

export class MemberShip extends Person {
  nativeCountry?: Country;
  residenceCountry?: Country;
  city?: City;
  godFather: GodFather;
  user: User;
  readTerms: boolean;
  methodsNews?: string[];
  options?: string;
  constructor() {
    super();
    this.nativeCountry = new Country();
    this.methodsNews = [];
    this.residenceCountry = new Country();
    this.city = new City();
    this.godFather = new GodFather();
    this.user = new User();
    this.classe = ClassNameMap.MemberShip;
  }
}

export class Count extends Persistent {
  public label?: string;
  public total?: number;

  constructor(){
    super();
    this.classe = ClassNameMap.Count;
  }
}
