import {Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, OnDestroy} from '@angular/core';
import {FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, FormBuilder} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Country } from '../../../model/country/Country';
import { HttpService } from 'app/services/http-service/http.service';
import { NotificationService, NotificationType } from 'app/services/notification-service/notification.service';
import {RegisterService} from '../../../services/register/register.service';
import {MemberShip} from '../../../model/register/MemberShip';
import { City } from 'app/model/country/City';
import {Store} from '@ngrx/store';
import {RegisterState, selectRegisterState} from '../../../store/member-ship/register.state';
import {Register} from '../../../store/member-ship/actions/registerActions';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent, MatChipList} from '@angular/material/chips';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatInput} from '@angular/material/input';
import {GodFather} from '../../../model/register/GodFather';
import {PotentialUser} from '../../../model/security/potential-user';
import {ThemePalette} from '@angular/material/core';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {NobleValidators} from '../../base/validators/noble-validators';
import {ConfigService} from '@noble/services/config.service';
import {SearchCriteria} from '../../../model/data-page';
import { FileDownloaderComponent } from 'app/components/file-downloader/file-downloader.component';


import * as _ from 'lodash';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {Observable, Subscription} from 'rxjs';
import {MemberShipState} from '../../../store/member-ship/reducers/register.reducer';
import {Address} from '../../../model/country/Address';
import {User} from '../../../model/security/user';
import parsePhoneNumberFromString from 'libphonenumber-js';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class RegisterComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(public translate: TranslateService,
              private registerStore: Store<RegisterState>,
              private fb: FormBuilder,
              private httpService: HttpService<any>,
              private msgService: NotificationService,
              private ref: ChangeDetectorRef,
              private authService: UserAppService,
              private registerService: RegisterService,
              private _configService: ConfigService,
  ) {

    // Configure the layout
    this._configService.config = {
      layout: {
        style   : 'app-navbar-out',
        appNavbarOut: {
          showLoginButton: true,
          showRegisterButton: false,
        },
      }
    };

    this.registerState$ = this.registerStore.select(selectRegisterState);

    RegisterComponent.password = null;

    this.nativeCountryControl.valueChanges.subscribe(result => {

        if (this.nativeListChips !== undefined && this.nativeListChips !== null) {
          this.nativeListChips.errorState = this.nativeCountryControl.status === 'INVALID';
        }

        if (!result || typeof result === 'string') {
          this.countriesN = [];
          this.getNativeCountry(result);
        } else {
          this.currentObject.nativeCountry = result;
        }
      });

    this.residenceCountryControl.valueChanges.subscribe(result => {

        if (this.residenceListChips !== undefined && this.residenceListChips !== null) {
          this.residenceListChips.errorState = this.residenceCountryControl.status === 'INVALID';
        }

        if (!result || typeof result === 'string') {
          this.countriesR = [];
          this.getResidenceCountry(result);
        } else {
          this.currentObject.residenceCountry = result;
          this.selectedResidenceCountry = result.isoCode;
          this.resetPhoneNumber();
          this.phoneCountryCode = `+${result.phoneCode}`;
        }
      });

    this.godFatherControl.valueChanges.subscribe(result => {
        if (!result || typeof result === 'string') {
          this.getGodFather(result);
        } else {
          this.currentObject.godFather = result;
        }
      });

    this.cityControl.valueChanges.subscribe(result => {

        if (this.cityListChips !== undefined && this.cityListChips !== null) {
          this.cityListChips.errorState = this.cityControl.status === 'INVALID';
        }

        if (!result || typeof result === 'string') {
          this.citiesC = [];
          this.getCity(result);
        } else {
          this.currentObject.city = result;
        }
      });

    this.birthDateControl.valueChanges.subscribe((result) => {
        if (result) {
          this.age = new Date(Date.now()).getFullYear() - new Date(result).getFullYear();
          this.currentObject.birthdate = (new Date(result)).toISOString();
        }
      });

    this.passwordControl.valueChanges.subscribe(result => {
        RegisterComponent.password = result;
      });
    this.nativeCountryControl.setValue('');
    this.residenceCountryControl.setValue('');
  }

  static password;

  loading = false;

  color: ThemePalette = 'accent';
  checked = false;
  disabled2 = false;
  @ViewChild('birthDateMatInput')
  public birthDateMatInputCmp: MatInput;
  public age = 0;
  public maxDate = new Date(Date.now());

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autoResidence') matAutocompleteR: MatAutocomplete;
  @ViewChild('autoCity') matAutocompleteCity: MatAutocomplete;
  @ViewChild('autoGodFather') matAutocompleteGodFather: MatAutocomplete;

  @ViewChild('nativeInput') nativeInput: ElementRef<HTMLInputElement>;
  @ViewChild('residenceInput') nativeInputR: ElementRef<HTMLInputElement>;
  @ViewChild('cityInput') cityInput: ElementRef<HTMLInputElement>;
  @ViewChild('godFatherInput') godFatherInput: ElementRef<HTMLInputElement>;

  isLinear = true;
  isOptional = false;
  isEditable = true;
  internet = false;
  network = false;
  specified: 'yes' | 'no' = 'no';
  specifiedChose =  false;
  chose1 =  true;
  chose: 'yes' | 'no' = 'yes';

  public currentObject = new MemberShip();

  formGroup1: FormGroup;
  formGroup2: FormGroup;
  formGroup3: FormGroup;
  optionGroup: FormGroup;
  formGroup4: FormGroup;
  formGroup5: FormGroup;

  alreadySave = false;
  currentPotentialUser = new PotentialUser();

  registerState$: Observable<any>;
  registerSubscription: Subscription;

  /**
   * Name control of member
   */
  nameControl = new FormControl(null, [Validators.required, NobleValidators.notEmpty, NobleValidators.notNumber]);

  /**
   * User name control of member
   */
  userNameControl = new FormControl(null, [Validators.required, NobleValidators.notEmpty, NobleValidators.notNumber]);

  birthDateControl = new FormControl(null, [Validators.required]);

  /**
   * E-mail control of member
   */
  emailControl = new FormControl('', [Validators.required, Validators.pattern(/[+a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)]);

  /**
   * Native country control of member
   */
  nativeCountryControl = new FormControl('', [Validators.required, NobleValidators.notObject, NobleValidators.notEmpty]);
  @ViewChild('chipList') nativeListChips: MatChipList;
  /**
   * Residence country control of member
   */
  residenceCountryControl = new FormControl('', [Validators.required, NobleValidators.notObject, NobleValidators.notEmpty]);

  @ViewChild('chipList2') residenceListChips: MatChipList;
  /**
   * Phone control of member
   */
  phoneControl = new FormControl(null, [Validators.required, this._validatePhoneNumberInput.bind(this)]);

  /**
   * City control of member
   */
  cityControl = new FormControl(null, [Validators.required, NobleValidators.notObject, NobleValidators.notEmpty]);
  optionControl = new FormControl(null, [Validators.required]);

  @ViewChild('chipList3') cityListChips: MatChipList;

  /**
   * Address control of member
   */
  addressControl = new FormControl('', [Validators.required]);

  /**
   * Postal Code control of member
   */
  postalCodeControl = new FormControl(null, [Validators.required]);

   /**
    * God Father control of member
    */
  godFatherControl = new FormControl('');

  specifiedControl = new FormControl('');

  /**
   * Password control of member
   */
  passwordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);

  /**
   * Confirm password control of member
   */
  confirmPasswordControl = new FormControl(null, [Validators.required, RegisterComponent.validConfirmPassWord]);

  /**
   * Terms use checkbox
   */
  checkTermsUse = false;
  checkTermsUse2 = false;

  @ViewChild('downloader')
  downloader: FileDownloaderComponent;
  openFileInTab = true;
  criteria: any | SearchCriteria = new SearchCriteria();


  /**
   * Member native country list
   */
  public nativeList: Country[];

  public godFathers: GodFather[];

  favoriteOption: {text: string, value: string, description: string};
  options: {text: string, value: string, description: string}[] = [
    {
      text: 'register.option.one.text.short',
      value: 'option-1',
      description: 'register.option.one.text'
    },
    {
      text: 'register.option.two.text.short',
      value: 'option-2',
      description: 'register.option.two.text'
    }
  ];

  /**
   * Member residence country list
   */
  public residenceList: Country[];
  selectedResidenceCountry: any = 'US';
  selectedPhoneNumber: any;

  public cities: City[];

  phoneCountryCode = '';

  disabled = false;
  removable = true;
  addOnBlur = true;
  countriesN: Country[] = [];
  countriesR: Country[] = [];
  citiesC: City[] = [];
  godFatherR: GodFather[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  static validConfirmPassWord(control: AbstractControl): ValidationErrors | null{
    if (RegisterComponent.password && control.value !== RegisterComponent.password){
      return {mismatch: true};
    }
    return null;
  }

  ngOnDestroy() {
    this.registerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.getCity('');
    this.getNativeCountry('');
    this.getResidenceCountry('');
    this.getGodFather('');
  }

  ngOnInit() {

    this.registerSubscription = this.registerState$.subscribe((state: MemberShipState) => {
      if (state && state.errorRegister !== null && this.loading === true) {
        this.loading = false;
      }
    });

    this.formGroup1 = new FormGroup({
      nameControl : this.nameControl,
      userNameControl: this.userNameControl,
      birthDateControl: this.birthDateControl,
      emailControl: this.emailControl,
    });

    this.formGroup2 = new FormGroup({
      nativeCountryControl : this.nativeCountryControl,
      residenceCountryControl: this.residenceCountryControl,
      phoneControl: this.phoneControl,
    });

    this.formGroup3 = new FormGroup({
      cityControl : this.cityControl,
      addressControl: this.addressControl,
      postalCodeControl: this.postalCodeControl,
    });

    this.optionGroup = new FormGroup({
      option : this.optionControl,
    });

    this.formGroup4 = new FormGroup({
      godFatherControl : this.godFatherControl,
    });

    this.formGroup5 = new FormGroup({
      passwordControl : this.passwordControl,
      confirmPasswordControl: this.confirmPasswordControl,
    });

    this.formGroup1.valueChanges.subscribe(form1 => {
      if (this.formGroup1.valid) {
        this.currentPotentialUser.email = this.currentObject.emailAddress;
        this.currentPotentialUser.phoneNumber = this.currentObject.officePhone;

        this.savePotentialUser();
      }
    });

    this.getNativeCountry('');
    this.getResidenceCountry('');

  }

  _validatePhoneNumberInput(c: AbstractControl): object {
    if (c && c.value && this.selectedResidenceCountry) {
      const inputValue: string = c.value.toString();
      const phoneNumber: any = parsePhoneNumberFromString(inputValue, this.selectedResidenceCountry);
      if (phoneNumber){
        if (this.selectedResidenceCountry === 'BE' && phoneNumber.nationalNumber.length === 9) {
          return null;
        }
        if (phoneNumber.isValid()){
          return null;
        } else {
          return {
            phoneNumber: {
              valid: false
            }
          };
        }
      } else {
        return {
          phoneNumber: {
            valid: false
          }
        };
      }
    } else {
      return null;
    }
  }

  resetPhoneNumber(): void {
    this.phoneControl.setValue('');
    this.selectedPhoneNumber = null;
  }

  formatPhoneNumber(event: any): void {
    const inputValue: any = this.phoneControl.value;
    const phoneNumber: any = parsePhoneNumberFromString(inputValue, this.selectedResidenceCountry);
    if (phoneNumber){
      this.selectedPhoneNumber = phoneNumber.number;
      this.phoneControl.setValue(phoneNumber.formatInternational());
    }
  }



  getNativeCountry(value) {
    this.registerService.getCountry(value).then(countries => {
      this.nativeList = countries ? countries : [];
    });
  }


  getGodFather(value) {
    this.registerService.getGodFather(value).then(godFathers$ => {
      this.godFathers = godFathers$ ? godFathers$ : [];
    });
  }

  getResidenceCountry(value) {
    this.registerService.getCountry(value).then(countries => {
      this.residenceList = countries ? countries : [];
    });
  }

  getCity(value) {
    const countryId = this.currentObject.residenceCountry ?
      ( this.currentObject.residenceCountry.id ? this.currentObject.residenceCountry.id : null ) : null;
    this.registerService.getCity(value, countryId).then(citiesData => {
      this.cities = citiesData ? citiesData : [];
    });
  }


  displayCountryFn(country?: Country): string {
    return country ? `${country.name}` : '';
  }

  displayCityFn(city?: City): string {
    return city ? `${city.name}` : '';
  }

  displayGodFatherFn(godFather?: GodFather): string {
    const name = godFather ? `${godFather.name}` : '';
    const surname = godFather ? `${godFather.surname}` : '';
    const code = godFather ? `${godFather.code}` : '';
    return `${name}-${surname}-${code}`;
  }

  /**
   * First step validation
   */
  isStep1Valid(){
    return this.formGroup1.valid;
  }

  /**
   * 2nd step validation
   */
  isStep2Valid(){
    return this.formGroup2.valid;
  }

  /**
   * 3st step validation
   */
  isStep3Valid(){
    return this.formGroup3.valid;
  }

  optionStepValid(){
    return this.optionGroup.valid;
  }

  /**
   * 4st step validation
   */
  isStep4Valid(){
    return this.formGroup4.valid;
  }

  /**
   * 5st step validation
   */
  isStep5Valid(){
    return this.formGroup5.valid;
  }

  /**
   * All steps validation
   */
  isAllStepValid(){
    this.getObjectFromView();
    return this.isStep1Valid() && this.isStep2Valid() && this.isStep3Valid() && this.optionStepValid() && this.isStep4Valid();
  }


  /**
   * Validation of the passage from the first step to the next step
   */
  step1Next(){
    if (!this.isStep1Valid()) {
      this.msgService.log('stepper.data.invalid', NotificationType.ERROR);
      }
  }


  /**
   * Validation of the passage from the 2nd step to the next step
   */
  step2Next(){
    if (!this.isStep2Valid()) {
      this.msgService.log('stepper.data.invalid', NotificationType.ERROR);
      }
  }

  /**
   * Validation of the passage from the 3st step to the next step
   */
  step3Next(){
    if (!this.isStep3Valid()) {
      this.msgService.log('stepper.data.invalid', NotificationType.ERROR);
      }
  }

  optionStepNext(){
    if (!this.optionStepValid()) {
      this.msgService.log('stepper.data.invalid', NotificationType.ERROR);
    }
  }

   /**
    * Validation of the passage from the 4st step to the next step
    */
  step4Next(){
    if (!this.isStep4Valid()) {
      this.msgService.log('stepper.data.invalid', NotificationType.ERROR);
      }
  }

  removeNativeCountry() {
    this.countriesN = [];
    this.nativeCountryControl.setValue(null);
  }

  removeResidenceCountry() {
    this.countriesR = [];
    this.residenceCountryControl.setValue(null);
  }

  removeCity() {
    this.citiesC = [];
    this.cityControl.setValue(null);
  }

  removeGodFather() {
    this.godFatherR = [];
    this.godFatherControl.setValue(null);
  }

  clickSpecified() {
    this.specifiedChose = !this.specifiedChose;
    this.specified = this.specifiedChose ? 'yes' : 'no';
    this.chose1 = this.specifiedChose ? false : this.chose1;
    this.chose = this.specifiedChose ? 'no' : this.chose;
  }

  clickChose() {
    this.chose1 = !this.chose1;
    this.chose = this.chose1 ? 'yes' : 'no';
    this.specifiedChose = this.chose1 ? false : this.specifiedChose;
    this.specified = this.chose1 ? 'no' : this.specified;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.countriesN = [].concat([value]);
      this.nativeCountryControl.setValue(value);
    }

    if (input) {
      input.value = '';
    }
  }

  add2(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.countriesR = [].concat([value]);
      this.residenceCountryControl.setValue(value);
    }

    if (input) {
      input.value = '';
    }
  }

  addCity(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.citiesC = [].concat([value]);
      this.cityControl.setValue(value);
    }

    if (input) {
      input.value = '';
    }
  }

  addGodfather(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.godFatherR = [].concat([value]);
      this.godFatherControl.setValue(value);
    }

    if (input) {
      input.value = '';
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.nativeCountryControl.setValue(event.option.value);
    this.countriesN = [].concat([event.option.value]);
  }

  selected2(event: MatAutocompleteSelectedEvent): void {
    this.residenceCountryControl.setValue(event.option.value);
    this.countriesR = [].concat([event.option.value]);
  }
  selectedCity(event: MatAutocompleteSelectedEvent): void {
    this.cityControl.setValue(event.option.value);
    this.citiesC = [].concat([event.option.value]);
  }
  selectedGodFather(event: MatAutocompleteSelectedEvent): void {
    this.godFatherControl.setValue(event.option.value);
    this.godFatherR = [].concat([event.option.value]);
  }

  radioChange(event) {
    this.favoriteOption = event.value;
  }


  getObjectFromView() {

    let address: Address = Object.assign(this.currentObject.address);
    address = {
      ...address,
      locality: this.currentObject.city
    };

    const nationality: Country = Object.assign(this.currentObject.nativeCountry);

    let godFather = Object.assign(this.currentObject.godFather);
    godFather = {
      ...godFather,
      address,
      nationality
    };

    let city: City = Object.assign(this.currentObject.city);
    city = {
      ...city,
      postCode: this.postalCodeControl.value
    };

    let user: User = Object.assign(this.currentObject.user);
    user = {
      ...user,
      password: this.passwordControl.value,
      login: this.currentObject.emailAddress
    };

    const mobilePhone = this.currentObject.mobilePhone;

    const options = this.favoriteOption.value;

    this.currentObject = {
      ...this.currentObject,
      address,
      nationality,
      city,
      user,
      mobilePhone,
      godFather,
      options
    };


    if (this.specified === 'yes') {
      let methods: string[] = [];
      methods = this.internet ?  methods.concat(['internet']) : methods;
      methods = this.network ?  methods.concat(['network']) : methods;

      this.currentObject = {
        ...this.currentObject,
        methodsNews: methods
      };
    }

    if (this.chose === 'yes') {
      const value = this.specifiedControl.value;

      this.currentObject = {
        ...this.currentObject,
        methodsNews: this.currentObject.methodsNews.concat([value])
      };
    }

  }

  doBeforSave(): Promise<MemberShip> {
    return  new Promise<MemberShip>((resolve, reject) => {
      this.getObjectFromView();
      this.currentObject.readTerms = this.checkTermsUse;
      resolve(this.currentObject);
    });
  }

  save(){
    if (!this.isAllStepValid() || !this.checkTermsUse || !this.checkTermsUse2) {
      this.msgService.log('register.step.checkTermsUse.invalid', NotificationType.ERROR);
      return;
    }
    this.doBeforSave().then(ob => {

      this.loading = true;

      this.registerStore.dispatch(new Register(ob));
    }).catch(err => {
      this.currentObject = _.cloneDeep(this.currentObject);
    });
  }

  savePotentialUser() {
    if (!this.alreadySave && this.isStep1Valid()) {
      this.registerService.sendPotentialUser(this.currentPotentialUser)
        .then((res => {
          this.alreadySave = true;
        }));
    }
  }

  hasError(event) {
    console.log(event);
  }

  getNumber(event) {
    console.log(event);
  }

  telInputObject(event) {
    console.log(event);
  }

  onCountryChange(event: any) {
    console.log(event);
  }

  startDownload(val: string) {
    this.criteria.lang = this.translate.currentLang;
    this.criteria.value = val;
    this.criteria.code = val + '.pdf';

    this.downloader.criteria = this.criteria;
    this.downloader.criteria.classe = 'CriteriaSearch';
    this.downloader.link = 'organisation/documents/print';
    this.downloader.downloadFile({});
    this.downloader.event.subscribe( (wellDone) => {
      if (wellDone === false) {
        this.msgService.log('form.print.error', NotificationType.ERROR);
      }
    });
  }
}
