<form [formGroup]="memberShipForm" name='profile-about-update-form'>
  <div class="row">
    <mat-form-field class="col-xs-12 col-sm-6 col-md-6" >
      <input type="text" placeholder='{{"member.fax.label" | translate}}'
             [formControl]="memberShipForm.get('fax')" name='fax'
             [value]="memberShip.fax"
             matInput maxlength="255" >
    </mat-form-field>
    <mat-form-field class="col-xs-12 col-sm-6 col-md-6" >
      <input type="text" placeholder='{{"member.phone.label" | translate}}'
             [formControl]="memberShipForm.get('mobilePhone')" name='mobilePhone'
             [value]="memberShip.mobilePhone"
             matInput maxlength="255">
      <mat-error *ngIf="memberShipForm.get('mobilePhone').errors">{{ "member.name.mobilePhone.error" | translate}}</mat-error>
    </mat-form-field>
  </div>
  <div>
    <button style="margin-right: 5px" mat-raised-button mat-button [disabled]="disabledSave"
            (click)="save()" class="register-btn">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <mat-icon>check_circle</mat-icon> {{ 'member.update.btn.label' | translate }}
    </button>
    <button mat-raised-button mat-button
            (click)="cancel()" class="register-btn">
      <mat-icon>cancel</mat-icon> {{ 'btn.cancel.label' | translate }}
    </button>
  </div>
</form>
