import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalVariables } from '../global-variables';
import {NotificationService, NotificationType} from '../notification-service/notification.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHttpInterceptor implements HttpInterceptor {


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(catchError(err => {
      if (err.status === 501 &&
        (err.url.includes('organisation/member-ship/save') ||
          err.url.includes('organisation/partner/save'))) {
        const errors: {user: string, email: string, address: string} = Object.assign(err.error);

        if (errors.user || errors.email) {
          const msg = errors.user || errors.email;
          this.translate.get(msg).subscribe(val => {
            this.message.log(val, NotificationType.ERROR);
          });
        }

        if (errors.address) {
          this.translate.get(errors.address).subscribe(val => {
            this.message.log(val, NotificationType.ERROR);
          });
        }
      }
      if (err.status === 401) {
            // auto logout if 401 response returned from api
            this.router.navigate(GlobalVariables.AFTER_LOGIN_ROUTE);
        }
      if (err.status === 501) {
          console.log(err);
        }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }

  constructor( private router: Router,
               private message: NotificationService,
               private translate: TranslateService) { }
}
