import {Injectable} from '@angular/core';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {UserAppService} from 'app/services/user-service/user-app.service';
import {
  AuthenticationActionTypes,
  Login,
  LoginFailure,
  LoginSuccess,
  Logout, LogoutFailure, RefreshLogin, RefreshBegin, EmailConfirm
} from '../actions/authentication.action';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {GlobalVariables} from '../../../services/global-variables';
import {AccessToken} from '../../../model/security/access-token';
import {HttpClient} from '@angular/common/http';
import {ProgressBarService} from '../../../services/progress-bar/progress-bar.service';
import {SpinnerOverlayService} from '../../../services/spinner-overlay/spinner-overlay.service';
import {User} from '../../../model/security/user';
import {ClassNameMap} from '../../../model/persistent/persistent';

@Injectable()
export class AuthenticationEffect {
  constructor(
    private actions: Actions,
    private router: Router,
    private authService: UserAppService,
    private spinnerOverlayService: SpinnerOverlayService,
    protected http: HttpClient,
    private translate: TranslateService,
    private nobleProgressBarService: ProgressBarService,
    private message: NotificationService
  ) {}

  @Effect()
  LogIn: Observable<any> = this.actions.pipe(
    ofType(AuthenticationActionTypes.LOGIN),
    map((action: Login) => action.payload),
    switchMap(user => {
      return this.authService.login(user)
      .pipe(
        map((accessToken) => {
          this.nobleProgressBarService.hide();
          if ( typeof  accessToken.access_token === 'undefined')
          {
            return new LoginFailure('login.not.token.define');
          }
          return new LoginSuccess(accessToken);
        }),
       catchError((error) => {
         this.nobleProgressBarService.hide();
         return of(new LoginFailure(error));
        })
      );
    })
  );

  @Effect()
  EmailConfirm: Observable<any> = this.actions.pipe(
    ofType(AuthenticationActionTypes.EMAIL_CONFIRM),
    map((action: EmailConfirm) => action.payload),
    switchMap(confirmUser => {
      return this.authService.confirm(confirmUser)
        .pipe(
          map((accessToken) => {
            if ( typeof  accessToken.access_token === 'undefined')
            {
              return new LoginFailure('confirm.account.error');
            }
            this.spinnerOverlayService.hide();
            return new LoginSuccess(accessToken);
          }),
          catchError((error) => {
            this.spinnerOverlayService.hide();
            return of(new LoginFailure('confirm.account.error'));
          })
        );
    })
  );

  @Effect()
  RefreshLogIn: Observable<any> = this.actions.pipe(
    ofType(AuthenticationActionTypes.REFRESH_LOGIN),
    map((action: RefreshLogin) => action.payload),
    switchMap(refreshToken => {
      return this.authService.refreshToken(refreshToken)
        .pipe(
          map((accessToken) => {
            if ( typeof  accessToken.access_token === 'undefined')
            {
              return new LoginFailure('error.refresh.login');
            }
            accessToken.user = localStorage.getItem(GlobalVariables.APP_USER_STORE_KEY) ?
                JSON.parse(localStorage.getItem(GlobalVariables.APP_USER_STORE_KEY)) : null
            return new LoginSuccess(accessToken);
          }),
          catchError((error) => {
            return of(new LoginFailure(error));
          })
        );
    })
  );

  @Effect()
  RefreshBegIn: Observable<any> = this.actions.pipe(
    ofType(AuthenticationActionTypes.REFRESH_BEGIN),
    map((action: RefreshBegin) => action.payload),
    switchMap(refreshToken => {
      localStorage.removeItem(GlobalVariables.APP_TOKEN_STORE_KEY);
      return of(new RefreshLogin(refreshToken));
    })
  );

  @Effect({ dispatch: false })
    LogInSuccess: Observable<any> = this.actions.pipe(
        ofType(AuthenticationActionTypes.LOGIN_SUCCESS),
        tap((data: LoginSuccess) => {
            this.message.showSpiner.emit(false);
            localStorage.setItem(GlobalVariables.APP_TOKEN_STORE_KEY, JSON.stringify(data.payload.access_token));
            const userLoggedIn: User = {login: data.payload.user.login, classe: ClassNameMap.User, id: data.payload.user.id};
            localStorage.setItem(GlobalVariables.APP_USER_STORE_KEY, JSON.stringify(userLoggedIn));
            localStorage.setItem(GlobalVariables.APP_REFRESH_TOKEN_STORE_KEY, JSON.stringify(data.payload.refresh_token));
        })
    );

    @Effect({ dispatch: false })
    LogInFailure: Observable<any> = this.actions.pipe(
        ofType(AuthenticationActionTypes.LOGIN_FAILURE),
        tap((error: LoginFailure) => {
          if ( typeof error.payload === 'string') {
            this.translate.get(error.payload).subscribe(val => {
              this.message.log(val, NotificationType.ERROR);
            });
          } else {
            this.translate.get('login.authentication.effect.error').subscribe(val => {
              this.message.log(val, NotificationType.ERROR);
            });
          }
          this.router.navigate(['home']);
        })
    );

    // effects logout
    @Effect({ dispatch: false })
    public LogOut: Observable<any> = this.actions.pipe(
        ofType(AuthenticationActionTypes.LOGOUT),
        tap((logout: Logout) => {
            localStorage.removeItem(GlobalVariables.APP_TOKEN_STORE_KEY);
            localStorage.removeItem(GlobalVariables.APP_USER_STORE_KEY);
            localStorage.removeItem(GlobalVariables.APP_REFRESH_TOKEN_STORE_KEY);
            this.authService.logout(logout.payload);
            this.router.navigate(['home']);
        })
    );

  @Effect({ dispatch: false })
  LogOutSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthenticationActionTypes.LOGOUT_SUCCESS),
    tap(() => {
      this.router.navigate(['home']);
    })
  );

  @Effect({ dispatch: false })
  LogOutFailure: Observable<any> = this.actions.pipe(
    ofType(AuthenticationActionTypes.LOGOUT_FAILURE),
    tap((error: LogoutFailure) => {
      if ( typeof error.payload === 'string') {
        this.translate.get(error.payload).subscribe(val => {
          this.message.log(val, NotificationType.ERROR);
        });
      } else if (error.payload === null) {
      } else {
        throw new Error('error.server');
      }
      this.router.navigate(['home']);
    })
  );
}
