import {
  OnInit,
  OnChanges,
  AfterViewInit,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  EventEmitter,
  Component, Directive
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { IFormActionBarButton } from '../../base/buttons-bar/buttons-bar.component';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SearchCriteria} from '../../../model/data-page';
import { Persistent } from 'app/model/persistent/persistent';
import { GlobalVariables } from 'app/services/global-variables';
import { NotificationService } from 'app/services/notification-service/notification.service';
import { HttpService } from 'app/services/http-service/http.service';
import { UserAppService } from 'app/services/user-service/user-app.service';
/**
 * Base component of grid components
 */
@Directive({
  selector: 'app-list-abstract'
})
export class ListAbstractComponent<P extends Persistent> implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  /**
   * Control for text criteria
   */
  searchFormControl: FormControl = new FormControl();

  /**
   * Table data source
   */
  tableDataSource: MatTableDataSource<P> = new MatTableDataSource([]);

  // view on mat-table
  @ViewChild(MatTable)// @ts-ignore
  tableCmp: MatTable<any>;
  // selection tool
  selection: SelectionModel<P> = new SelectionModel<P>(false, []);

  /**
   * Criteria object send to the server to filter data
   */
  criteria: any | SearchCriteria;

  /**
   * Parameters send via url
   */
  params: any;

  /**
   * Paginator parameters
   */
  paginatorParams: {length?: number, pageSize?: number, pageSizeOptions?: number[], page?: number };

  /**
   * Indicator to kow if the zone of 'more criteria' must be hide or show
   */
  moreCriteriaShow = false;

  // tanslation pipe. Used to translate text out of the template
  translate: TranslatePipe;

  /**
   * The base url to use when calling http.search to perform filtering
   */
  searchBaseUrl: string;

  // List of buttons to render in buttons-bar
  public buttons: IFormActionBarButton[];

  /**
   * Emit when the selection change: When an element is selected or when all element are unselected
   */
  elmtSelected: EventEmitter<any> = new EventEmitter();

  /**
   * Emit a set of data
   */
  dataEmitter: EventEmitter<P[]> = new EventEmitter<P[]>();

  /**
   * Contain the current lang choosed by the user
   */
  protected lang = GlobalVariables.AVAILABLE_LANG ? GlobalVariables.AVAILABLE_LANG[0] : null;

  constructor(protected ref: ChangeDetectorRef, protected httpService: HttpService<P>,
              protected router: Router, protected route: ActivatedRoute, protected userService: UserAppService,
              protected msgService: NotificationService, protected translateService: TranslateService) {
       this.translate = new TranslatePipe(translateService, ref);
       this.criteria = new SearchCriteria();
       this.paginatorParams = {
        pageSize: GlobalVariables.PAGINATOR_DEFAULT_SIZE,
        pageSizeOptions: GlobalVariables.PAGINATOR_SIZE_OPTIONS,
        page: 0
      };
       this.criteria.pageSize = GlobalVariables.PAGINATOR_DEFAULT_SIZE;
       this.lang = this.translateService.currentLang;
       this.translateService.onLangChange.subscribe( (lang) => {
        this.lang = lang;
      });
  }

  ngOnInit() {
    // initialisation of search control
    this.searchFormControl = new FormControl('');
    this.searchFormControl.updateValueAndValidity();
    // initialise cache data management
    this.initCacheDataManagement();
    // get parameter set in route
    this.route.paramMap.subscribe(params => {
      this.params = (params as any).params;
      if (this.params.search) {
        this.searchClicked({});
      }
    });
  }

  /**
   * Initialisation of cache data management
   */
  initCacheDataManagement() {
    // retrieving cache data
    const cachedNavigationData = this.userService.getNavigationData(this.constructor.name);
    if (cachedNavigationData != null && cachedNavigationData.criteria != null) {
      this.criteria = Object.assign(this.criteria != null ? this.criteria : {}, cachedNavigationData.criteria);
    }
    // set cache data in criteria to perform filter with them
    this.setCriteriaDataInView(cachedNavigationData);
  }

ngOnChanges(changes: SimpleChanges): void {

}

ngAfterViewInit(): void {
  this.listSelectionChanged();
}

/**
 * Call when the user change the page he wan to see
 * event
 */
  pageChanged(event) {
    this.criteria.value = this.searchFormControl.value;
    this.criteria.pageSize = event.pageSize;
    this.criteria.page = event.pageIndex >= 0 ? event.pageIndex : 0;
    this.search(this.criteria);
    this.selection.clear();
    this.elmtSelected.emit(null);
    this.listSelectionChanged();
  }

  /**
   * Method that perform filtering on server
   * criteria
   */
  protected search(criteria: any) {
    this.criteria.allPages = null;
    this.httpService.search(criteria, this.searchBaseUrl, true).subscribe( (result) => {
      this.tableDataSource.data = result ? result.datas : [];
      this.paginatorParams = {
        pageSize: GlobalVariables.PAGINATOR_DEFAULT_SIZE,
        pageSizeOptions: GlobalVariables.PAGINATOR_SIZE_OPTIONS,
        length: result.totalItemCount
      };
      this.tableCmp?.renderRows();
      this.listSelectionChanged();
    }, (error) => {// search fail
      this.handleSearchError(error);
    });
  }

  /**
   * Render error when search fail
   * error
   */
  protected handleSearchError(error) {
    this.msgService.log('list.search.fail.error');
  }
  /**
   * Call to filter the grid when the search btn is cliecked
   * event
   */
  searchClicked(event) {
    this.criteria.value =  this.searchFormControl && this.searchFormControl.value ? this.searchFormControl.value : '';
    this.criteria.page = 0;
    this.elmtSelected.emit(null);
    this.search(this.criteria);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.tableDataSource.data.forEach(row => this.selection.select(row));

    this.elmtSelected.emit(this.selection.selected);
    this.listSelectionChanged();
  }

  /**
   * Checkbox status changed
   * element
   */
  checkBoxChange(element) {
    this.selection.toggle(element);
    this.elmtSelected.emit(this.selection.selected);
    this.listSelectionChanged();
  }

  /**
   * Merge cached data to default criteria data
   * cachedNavigationData
   */
  protected setCriteriaDataInView(cachedNavigationData: any) {
    if (cachedNavigationData == null) { return ; }
    console.log('setCriteriaDataInView ', cachedNavigationData);
    if (cachedNavigationData.criteria != null) {
      this.searchFormControl.setValue( cachedNavigationData.criteria.value);
    }
    if (cachedNavigationData.moreCriteriaShow != null) {
      this.moreCriteriaShow = cachedNavigationData.moreCriteriaShow;
    }
  }
  /**
   * Before destroying the view, we store data
   */
  ngOnDestroy() {
    this.userService.updateNavigationData( this.constructor.name, this.getNavigationDataToStore());
  }

  /**
   * return criteria and status of more-criteria zone
   */
  protected getNavigationDataToStore(): any {
    return Object.assign({}, {criteria : this.criteria, moreCriteriaShow: this.moreCriteriaShow});
  }

  listSelectionChanged() {
    this.dataEmitter.emit(this.tableDataSource.data);
  }
}
