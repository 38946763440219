
<div class="nkap-modal-container">
  <div *ngIf="headerTitle || headerCloseButton == true" mat-dialog-title class="row">
      <div *ngIf="headerTitle" class="col-xs-12 col-sm-12 col-md-12" >{{ headerTitle | translate}}
        <div class="modal-close-btn">
          <button type="button" *ngIf="headerCloseButton == true" mat-icon-button
          (click)="onCloseClicked($event)">
          <mat-icon>clear</mat-icon>
        </button>
        </div>
      </div>
  </div >

  <ng-template #modalContainer ></ng-template>

  <!--<div class="{{ footerButtonBar? 'mat-dialog-content' : ''}}" >
    <ng-template #modalContainer></ng-template>
  </div>-->

  <div *ngIf="footerButtonBar" mat-dialog-actions class="d-flex justify-content-center">
    <app-buttons-bar *ngIf="footerButtonBar.buttons"
      [functionality]="footerButtonBar.functionality"
      [actions]="footerButtonBar.buttons"
      (action)="btnBarActionClick($event)">
    </app-buttons-bar>
  </div>
</div>
