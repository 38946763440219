import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {environment} from '../../../../environments/environment';
import {FileUploadService} from '../../../services/document/file.upload.service';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.css']
})
export class ProfilePictureComponent implements OnInit {

  constructor(private fileUploadService: FileUploadService) {
    this.uploader = new FileUploader({
      url: this.path,
      disableMultipart: false,
      autoUpload: false,
      itemAlias: 'recordFile'
    });
  }

  static file: any;
  public uploader: FileUploader;
  private hasDragOver = false;

  fileUpload = { status: '', message: '', filePath: '', name: '', externalId: '' };

  error: string;

  @Input()
  public editmode = false;

  @Input()
  public url = '';

  @Input() path: string = environment.apiHost + environment.uploadFile;

  @Output()
  private urlChange = new EventEmitter();

  @Output()
  public addingFile = new EventEmitter();

  public fileOver(e: any): void {
    this.hasDragOver = e;
  }

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file: any) => {
      ProfilePictureComponent.file = file.some;
      this.addingFile.next();
    };
  }

  onSubmit(): Promise<{name: string, externalId: string}>{
    return new Promise<{name: string, externalId: string}>((resolve, reject) => {
      this.fileUploadService
        .uploadItem(ProfilePictureComponent.file, this.path)
        .subscribe(
          res => {
            if (res.status === 'progress') {
              this.fileUpload = res;
            } else if (res.status === 'done') {
              this.fileUpload = res;
              this.fileUploadService.onPhotoUploaded.next(
                this.fileUpload
              );
              // this.show = !this.show;
              resolve({name: res.record_name, externalId: res.externalId});
            }
          },
          err => {
            this.error = err;
            reject(err);
          }
        );
    });
  }

}
