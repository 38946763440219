import { Pipe, PipeTransform } from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(value: string, currencyCode: string = 'EUR',
            display: | 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
            digitsInfo: string = '2.2-2',
            local: string = 'fr'): string | null {
    return formatCurrency(
      Number.parseInt(value, 10),
      local,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo
    );
  }

}
