import {AuthenticationActionTypes, AuthenticationActions} from '../actions/authentication.action';
import {User} from '../../../model/security/user';
import {GlobalVariables} from '../../../services/global-variables';
import {ClassNameMap} from '../../../model/persistent/persistent';

export interface State {
  isAuthenticated: boolean;
  getRule: boolean;
  user: {
    token: string,
    refreshToken: string,
    user: User,
  };
  errorLogin: string | Error;
  errorLogout: string | null | Error;
}

export const initialState: State = {
  isAuthenticated: localStorage.getItem(GlobalVariables.APP_TOKEN_STORE_KEY) !== null,
  user: {
      token: localStorage.getItem(GlobalVariables.APP_TOKEN_STORE_KEY),
      refreshToken: localStorage.getItem(GlobalVariables.APP_REFRESH_TOKEN_STORE_KEY),
      user: localStorage.getItem(GlobalVariables.APP_USER_STORE_KEY) ?
        JSON.parse(localStorage.getItem(GlobalVariables.APP_USER_STORE_KEY)) : null
  },
  errorLogin: null,
  errorLogout: null,
  getRule: false,
};

export function authReducer(state = initialState, action: AuthenticationActions): State {
  switch (action.type) {
    case AuthenticationActionTypes.LOGIN_SUCCESS: {
        return {
            ...state,
            isAuthenticated: true,
            user: {
                token: action.payload.access_token,
                refreshToken: action.payload.refresh_token,
                user: {
                  login: action.payload.user.login,
                  id: action.payload.user.id,
                  classe: ClassNameMap.User,
                }
            },
          errorLogin: null,
          getRule: true
        };
    }
    case AuthenticationActionTypes.LOGIN_FAILURE: {
        return {
            ...state,
            errorLogin: action.payload,
            getRule: false,
            isAuthenticated: false
        };
    }
    case AuthenticationActionTypes.LOGOUT_FAILURE: {
      return {
        ...state,
        errorLogout: action.payload,
        getRule: false
      };
    }
    case AuthenticationActionTypes.LOGOUT: {
      return initialState;
    }
    case AuthenticationActionTypes.REFRESH_BEGIN: {
      return {
        ...state,
        user: {
          token: null,
          refreshToken: action.payload,
          user: {
            login: state.user.user.login,
            id: state.user.user.id,
            classe: ClassNameMap.User,
          }
        },
        getRule: false
      };
    }
    default: {
        return state;
    }
}

}
