import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpService} from '../../../../services/http-service/http.service';
import {Member} from '../../../../model/organisation/Member';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService, NotificationType} from '../../../../services/notification-service/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../../../../@noble/services/config.service';
import {UserAppService} from '../../../../services/user-service/user-app.service';
import {RegisterService} from '../../../../services/register/register.service';
import {RecordService} from '../../../../services/document/record.service';
import {RecordDoc} from '../../../../model/document/record-doc';
import {FormAction, IFormActionBarButton} from '../../../base/buttons-bar/buttons-bar.component';
import {RecordInformationComponent} from './record-information/record-information.component';
import {RecordDocumentComponent} from './record-document/record-document.component';
let self;

@Component({
  selector: 'app-record-edit',
  templateUrl: './record-edit.component.html',
  styleUrls: ['./record-edit.component.scss'],
  providers: [
    { provide: 'USE_MODAL', useValue: false },
  ]
})
export class RecordEditComponent implements OnInit {

  RecordDocForm: FormGroup;
  currentObject: RecordDoc;
  buttons: IFormActionBarButton[];

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  paramId: string;

  @ViewChild('recordInformationComponent')
  recordInformationComponent: RecordInformationComponent;

  @ViewChild('recordDocumentComponent')
  recordDocumentComponent: RecordDocumentComponent;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService<Member>,
    private router: Router,
    private route: ActivatedRoute,
    private msgService: NotificationService,
    protected translateService: TranslateService,
    private _configService: ConfigService,
    private userService: UserAppService,
    private registerService: RegisterService,
    private recordService: RecordService,
  ) {
    self = this;
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };

    this.currentObject = new RecordDoc();

    this.buttons = [
      {id: 'save', value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: []},
      {id: 'cancel', value: FormAction.CANCEL, icon: {type : 'cancel'},
        text: 'btn.cancel.label', disabled: false, functionalities: []}
    ];
  }

  ngOnInit(): void {
    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required]
    });

    this.route.paramMap.subscribe(params => {
      const data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.paramId = data.id;
        this.httpService.findById(data.id, 'document/record-doc', true).subscribe( (object) => {
          this.setObjectInView(object);
        }, (errors) => {
          this.msgService.log('form.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  setObjectInView(recordDoc: RecordDoc) {
    if (recordDoc === undefined) { recordDoc = new RecordDoc(); }
    this.recordInformationComponent.setObjectInView(recordDoc);
    this.recordDocumentComponent.setObjectInView(recordDoc);
  }

  getObjectFromView(): Promise<RecordDoc> {
    return new Promise(((resolve, reject) => {
      this.recordDocumentComponent.onSubmit().then((el) => {
        this.recordInformationComponent.getData().then(record => {
          const persistence = new RecordDoc();
          persistence.record = record;
          persistence.name =  el.name;
          persistence.externalId = el.externalId;
          resolve(persistence);
        });
      }).catch(err => {
        reject(err);
      });
    }));
  }

  doBeforeSave(): Promise<RecordDoc> {
    return new Promise(((resolve, reject) => {
      this.getObjectFromView().then(obj => {
        this.userService.getMemberShip().then((membership) => {
          this.currentObject.record.user = membership.user;
          obj.record.user = membership.user;
          this.recordService.saveRecord(obj.record).then(record => {
            if (this.paramId === null || this.paramId === undefined) {
              this.currentObject.record = record;
              this.currentObject.name = obj.name;
              this.currentObject.externalId = obj.externalId;
              resolve(this.currentObject);
            } else {
              this.httpService.findById(this.paramId, 'document/record-doc', true).subscribe( (object) => {
                object.name = obj.name;
                object.externalId = obj.externalId;
                resolve(object);
              }, (errors) => {
                this.msgService.log('form.edit.get.data.error', NotificationType.ERROR);
              });
            }
          }).catch(err => {
            reject(err);
          });
        });
      });
    }));
  }

  isValid() {
    let isValid = true;
    if (this.recordInformationComponent.isValid() === false) {
      isValid = false;
    }

    if (this.recordDocumentComponent.isValid() === false) {
      isValid = false;
    }

    return isValid;
  }

  save() {
    if (this.isValid() === true) {
      this.doBeforeSave().then(object => {
        this.recordService.saveRecordDoc(object).then((entity: RecordDoc) => {
          this.translateService.get('record-doc.save.succeed.message', [this.currentObject.record.number]).subscribe(
            (text) => {
              if (entity != null && entity.id != null && entity.id !== undefined) {
                this.currentObject.id = entity.id;
                this.msgService.log(text, NotificationType.SUCCESS);
              }else {
                this.msgService.log(text, NotificationType.ERROR);
              }
            }
          );
          this.currentObject = new RecordDoc();
          this.router.navigate(['/record-doc/list']);
        }, (error => {
          console.log('error', error);
          if (error != null ) {
            try {
              this.translateService.get(error).subscribe(val => {
                this.msgService.log(val, NotificationType.ERROR);
              });
            }catch (e) { console.error(e); }
          } else {
            this.translateService.get('record-doc.save.error').subscribe(val => {
              this.msgService.log(val, NotificationType.ERROR);
            });
          }
          console.error(error);
        }));
      });
    }
  }

  initForm() {
    this.RecordDocForm = this.fb.group({
      number: [null,  [Validators.required]],
      type: [null,  [Validators.required]],
      description: [null, [Validators.required]],
      enDate: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      valid: [null, [Validators.required]],
    });
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id){
      this.save();
    } else if (event && event.id === this.buttons[1].id){
      this.router.navigate(['record-doc/list']);
    }
  }

}
