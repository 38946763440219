<form [formGroup]="familyMemberForm" name='member-update-form'>
  <section class="example-section">
    <mat-checkbox
      class="example-margin"
      [formControl]="familyMemberForm.get('dead')" name="dead"
      [(indeterminate)]="indeterminate"
      [labelPosition]="labelPosition">
      {{"family-member-update-dead.label" | translate}}
    </mat-checkbox>
  </section>
  <div class="row">
    <mat-form-field class="col-xs-12 col-sm-6 col-md-6" >
      <input type="number" placeholder='{{"member.phone.label" | translate}}'
             [formControl]="familyMemberForm.get('mobilePhone')" name='mobilePhone'
             matInput maxlength="255">
      <mat-error *ngIf="familyMemberForm.get('mobilePhone').errors">{{ "member.name.mobilePhone.error" | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-xs-12 col-sm-6 col-md-6" >
      <input type="email" placeholder='{{"member.emailAddress.label" | translate}}'
             [formControl]="familyMemberForm.get('emailAddress')" name='surname'
             matInput maxlength="255" >
      <mat-error *ngIf="familyMemberForm.get('emailAddress').errors">{{ "family-member-update.emailAddress.error" | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-xs-12 col-sm-6 col-md-6" >
      <input type="text" placeholder='{{"member.fax.label" | translate}}'
             [formControl]="familyMemberForm.get('fax')" name='fax'
             matInput maxlength="255" >
    </mat-form-field>
  </div>
  <button mat-raised-button mat-button [disabled]="disabledSave"
          (click)="save()" class="register-btn">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    <mat-icon>check_circle</mat-icon> {{ 'member.update.btn.label' | translate }}
  </button>
</form>
