import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ListAbstractComponent} from '../../../base/abstract/list-abstract-component';
import {RecordDoc} from '../../../../model/document/record-doc';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../../../services/http-service/http.service';
import {Member} from '../../../../model/organisation/Member';
import {UserAppService} from '../../../../services/user-service/user-app.service';
import {NotificationService, NotificationType} from '../../../../services/notification-service/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../../../../@noble/services/config.service';
import {Store} from '@ngrx/store';
import {LoginState, selectAuthenticationState} from '../../../../store/login/login.state';
import {RegisterService} from '../../../../services/register/register.service';
import {RecordService} from '../../../../services/document/record.service';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../../../model/security/user';
import {MemberShip} from '../../../../model/register/MemberShip';
import {Organisation} from '../../../../model/organisation/Organisation';
import {FormAction} from '../../../base/buttons-bar/buttons-bar.component';
import {State} from '../../../../store/login/reducers/authentication.reducer';
import {RecordCriteria} from '../../../../model/data-page';
import {ModalComponentService} from '../../../base/modal/modal.component';
import {RecordDocumentComponent} from '../record-edit/record-document/record-document.component';
import {DocumentViewerComponent} from '../../document-viewer/document-viewer.component';
import {RecordDocumentModalComponent} from "../record-edit/record-document-modal/record-document-modal.component";

@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.scss']
})
export class RecordListComponent extends ListAbstractComponent<RecordDoc> implements OnInit, OnDestroy, AfterViewInit {

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  currentUser: User;
  currentOwner: MemberShip | Organisation;

  constructor(
    protected ref: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected httpService: HttpService<Member>,
    protected userService: UserAppService,
    protected msgService: NotificationService,
    protected translateService: TranslateService,
    private _configService: ConfigService,
    private store: Store<LoginState>,
    private recordService: RecordService,
    private registerService: RegisterService,
    public modalService: ModalComponentService,
  ) {
    super(ref, httpService, router, route, userService, msgService, translateService);
    this.authenticationState$ = this.store.select(selectAuthenticationState);
    this.criteria = new RecordCriteria();
    this.searchBaseUrl = 'document/record';
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };

    this.buttons = [
      {id: 'edit', value: FormAction.UPDATE, text: 'btn.update.label', cssClass: 'btn-update' , disabled: false,
        icon: {type : 'edit', color: '#ff9933'}},
      {id: 'delete', value: FormAction.DELETE, text: 'btn.delete.label', cssClass: 'btn-delete' , disabled: false,
        icon: {type : 'delete_forever', color: '#ff9933'}},
    ];
  }

  ngOnInit(): void{
    super.ngOnInit();
    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.currentUser = state.user.user;
        this.registerService.findMemberShipByLogin(this.currentUser.login).then(memberShip => {
          this.currentOwner = memberShip.user;
          this.criteria = {
            ...this.criteria,
            userId: this.currentOwner.id
          };
          this.searchClicked({});
        });
      }
    });
    /*this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.currentUser = state.user.user;

        if (this.currentUser != null &&
          this.currentUser.isOrganisation !== undefined &&
          this.currentUser.isOrganisation === true) {
          this.registerService.findOrganisationByLogin(this.currentUser.login).then(organisation => {
            this.currentOwner = organisation;
            this.criteria = {
              ...this.criteria,
              userId: this.currentOwner.id
            };
          });
        }

        if (this.currentUser != null &&
          this.currentUser.isOrganisation !== undefined &&
          this.currentUser.isOrganisation === false) {
          this.registerService.findMemberShipByLogin(this.currentUser.login).then(memberShip => {
            this.currentOwner = memberShip;
            this.criteria = {
              ...this.criteria,
              userId: this.currentOwner.id
            };
          });
        }
      }
    });*/
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  public viewElement(element) {

    if (element.id != null) {
      this.httpService.findById(element.id, 'document/record-doc', true).subscribe( (res: RecordDoc) => {
        element = res;
        const modalData = {modalTitle: 'record-doc.view.label', record: element};
        const dialogRef = this.modalService.openModal(DocumentViewerComponent, modalData.modalTitle, null, {
          disableClose: false,
          data: modalData.record.name,
          width: '70%',
          height: '90%'
        });

      }, (errors) => {
        this.msgService.log('record-doc.edit.get.data.error', NotificationType.ERROR);
      });
    }
  }

  public editElement(element: RecordDoc) {

    if (element.id != null) {
      this.httpService.findById(element.id, 'document/record-doc', true).subscribe( (res: RecordDoc) => {
        element = res;

        const modalData = {modalTitle: 'record-doc.update.label', record: element};
        const dialogRef = this.modalService.openModal(RecordDocumentModalComponent, modalData.modalTitle, null, {
          disableClose: false,
          data: modalData.record,
          width: '50%',
          height: '50%'
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            if (result === 'Unknown Error' || result === 'Internal Server Error') {
              this.msgService.log('record-doc.show.update.error', NotificationType.SUCCESS);
            } else if (result.name && result.externalId) {
              this.msgService.log('record-doc.show.update.success', NotificationType.SUCCESS);
              element.name = result.name;
              element.externalId = result.externalId;
              this.recordService.saveRecordDoc(element).then(ob => {
                this.viewElement(ob);
              });
            }
          }
        });

      }, (errors) => {
        this.msgService.log('record-doc.edit.get.data.error', NotificationType.ERROR);
      });
    }
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id ){
      if ( this.selection.selected && this.selection.selected.length === 1) {
        this.router.navigate(['record-doc/edit', {id: this.selection.selected[0].id}]);
      }else{
        this.msgService.log('record-doc.list.select.to.modify.error', NotificationType.ERROR);
      }
    } else if (event && event.id === this.buttons[1].id){
      if ( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log('record-doc.list.select.to.delete.error', NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform('record-doc.select.to.delete.message')).subscribe( (result) => {
      if (result === true){
        const memberSelected = this.selection.selected[0];
        this.httpService.deleteById(memberSelected.id, 'document/record-doc').subscribe( (val) => {
          this.msgService.log('record-doc.delete.succeed', NotificationType.SUCCESS);

          this.searchClicked({});
        }, (error) => {
          this.msgService.log('record-doc.delete.fail', NotificationType.ERROR);
        });
      }
    });
  }

  ngOnDestroy()  {
    this.authenticationSubscription.unsubscribe();
  }

}
