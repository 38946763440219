import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {Person} from '../../model/organisation/Person';
import {ContactPerson} from '../../model/organisation/contact-person';
import {NobleValidators} from '../base/validators/noble-validators';

@Component({
  selector: 'app-single-contact-person',
  templateUrl: './single-contact-person.component.html',
  styleUrls: ['./single-contact-person.component.css']
})
export class SingleContactPersonComponent implements OnInit, OnChanges {

  contactPersonForm: FormGroup;

  public translator: TranslatePipe;

  @Input() title: string;

  @Input() displayContact: ContactPerson;

  @Input() doctorContact: boolean;

  contactPerson: ContactPerson;

  public age = 0;
  public maxDate = new Date(Date.now());
  public minDate = new Date(Date.now());

  constructor(public _ref: ChangeDetectorRef,
              private formBuilder: FormBuilder,
              private translate: TranslateService) {
    this.translator = new TranslatePipe(translate, _ref);
    this.contactPerson = new ContactPerson();
    this.contactPerson.person = new Person();
    this.contactPerson.person.address = undefined;
    this.doctorContact = false;
  }

  ngOnInit() {

    this.initForm();

    if (this.displayContact) {
      if (this.displayContact.person !== null && this.displayContact.person !== undefined){
        this.contactPerson = this.displayContact;
        this.contactPerson.toAddOrUpdate = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayContact || changes.doctorContact || changes.title) {
      this.ngOnInit();
    }
  }

  phoneErrorMessage() {

    let textError1 = '';
    let textError2 = '';
    if (this.contactPersonForm.controls.phone.hasError('required')) {
      textError1 = this.translator.transform('phone.enter.error');
    }

    if (this.contactPersonForm.controls.phone.hasError('pattern')) {
      textError2 = this.translator.transform('format.phone.error');
    }

    return textError1 + ' ' + textError2;
  }

  isValid() {
    return this.contactPersonForm.valid;
  }

  getData(): Person {
    const person = new Person();
    return {
      ...person,
      name: this.contactPersonForm.controls.fullName.value,
      mobilePhone: this.contactPersonForm.controls.phone.value,
      emailAddress: this.contactPersonForm.controls.email.value
    };
  }

  initForm() {
    this.contactPersonForm = this.formBuilder.group({
      fullName: ['', [Validators.required, NobleValidators.notEmpty, NobleValidators.notNumber]],
      phone: ['', [Validators.pattern(/[+0-9]{9}/)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }
}
