import {GlobalVariables} from './global-variables';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '../components/navbar';
import {DateAdapter} from '@angular/material/core';
import {UserAppService} from './user-service/user-app.service';
import {Injectable} from '@angular/core';

@Injectable()
export class LanguageService {

  currentLanguage: Language;
  availableLanguages: Language[];

  constructor(private translate: TranslateService, private dateAdapter: DateAdapter<any>,
              private userService: UserAppService) {
  }


  init() {
    this.translate.addLangs(GlobalVariables.AVAILABLE_LANG);
    const availableLanguages = [];
    GlobalVariables.AVAILABLE_LANG.forEach((lang) => {
      availableLanguages.push({value: lang, labelCode: 'app.lang.' + lang + '.label'});
    });
    this.availableLanguages = availableLanguages;
    const browserLang = this.translate.getBrowserLang();
    const langIndex = this.availableLanguages.findIndex((lang) => lang.value === browserLang);
    this.currentLanguage = this.availableLanguages[langIndex !== -1 ? langIndex : 0];
    this.translate.setDefaultLang(this.currentLanguage.value);
    this.translate.use(this.currentLanguage.value);
    this.dateAdapter.setLocale(this.currentLanguage.value);
  }

  changeLanguage(lang: Language) {
    this.currentLanguage = lang;
    this.translate.use(this.currentLanguage.value);
    this.dateAdapter.setLocale(this.currentLanguage.value);
    if (this.userService) {
      this.userService.userLangChanged.emit(lang);
    }
  }
}
