import {ClassNameMap, Persistent} from '../persistent/persistent';

export class Country extends Persistent {
    isoCode?: string;
    name?: string;
    description?: string;
    phoneCode?: string;

    constructor() {
      super();
      this.classe = ClassNameMap.Country;
    }
}
