import {ClassNameMap, Persistent} from '../persistent/persistent';

export class Document extends Persistent {
  public name?: string;

  public externalId?: string;

  constructor() {
    super();
    this.classe = ClassNameMap.Document;
  }
}
