import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {RegisterState, selectRegisterState} from '../../../store/member-ship/register.state';
import {MemberShipState} from '../../../store/member-ship/reducers/register.reducer';

@Component({
  selector: 'app-mail-confirm',
  templateUrl: './mail-confirm.component.html',
  styleUrls: ['./mail-confirm.component.scss']
})
export class MailConfirmComponent implements OnInit, OnDestroy {

  registerState$: Observable<any>;
  email: string;
  userName: string;
  registerSubscription: Subscription;
  constructor(private userService: UserAppService,
              private registerStore: Store<RegisterState>
  ) {
    this.userService.isHomeURL.emit(true);
    this.registerState$ = this.registerStore.select(selectRegisterState);
  }

  ngOnInit(): void {
    this.registerSubscription = this.registerState$.subscribe((state: MemberShipState) => {
      this.email = state.email;
      this.userName = state.userName;
    });
  }

  ngOnDestroy(): void {
    this.registerSubscription.unsubscribe();
  }

}
