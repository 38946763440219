<div id="mail-confirm" fxLayout="column">

  <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="mail-confirm-form">

      <div class="logo">
        <mat-icon class="mat-accent s-96">build_circle</mat-icon>
      </div>

      <div class="title">{{'confirm.account.title' | translate}}</div>

      <div class="subtitle">
        <p>
          {{'confirm.account.subtitle' | translate}}
        </p>
        <p>
        </p>
      </div>
    </div>

  </div>

</div>
<app-footer-bar></app-footer-bar>
