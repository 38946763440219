import {ClassNameMap, Persistent} from '../persistent/persistent';

export class Basket extends Persistent{
  static MAX_YEAR = 59;
  static NOBLE_INSCRIPTION_LESS_THAN_59_YEAR = 50;
  static NOBLE_INSCRIPTION_MORE_THAN_59_YEAR = 200;
  static NOBLE_SUBSCRIPTION_AMOUNT = 26;
  currency?: string;
  amount?: string;
  name?: string;
  email?: string;

  constructor(props: {amount: string,  name?: string, email?: string}) {
    super();
    this.amount = props.amount;
    this.email = props.email;
    this.name = props.name;
    this.currency = 'EUR';
    this.classe = ClassNameMap.Basket;
  }
}
