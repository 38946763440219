import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../../model/menu-item';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input()
  menuItem: MenuItem;

  @Input()
  level?: number;

  constructor(private router: Router) {
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
  }


  protected menuItemClicked = (menuItem: MenuItem) => {
    if (menuItem && menuItem.routeData) {
      this.router.navigate(menuItem.routeData);
    }
  }

}
