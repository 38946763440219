import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService implements Resolve<any> {

  about: any;
  beneficiary: any;
  password: any;

  aboutOnChanged: BehaviorSubject<any>;
  beneficiaryOnChanged: BehaviorSubject<any>;
  passwordChanged: BehaviorSubject<any>;

  constructor(private _httpClient: HttpClient) {
    this.aboutOnChanged = new BehaviorSubject<any>({});
    this.beneficiaryOnChanged = new BehaviorSubject<any>({});
    this.passwordChanged = new BehaviorSubject<any>({});
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
    return new Promise<any>((resolve, reject) => {
      Promise.all([
        this.getAbout(),
        this.getBeneficiary(),
        this.getPassword()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get about
   */
  getAbout(): Promise<any>
  {
    return new Promise<any>((resolve, reject) => {

    });
  }

  /**
   * Get Beneficiary
   */
  getBeneficiary(): Promise<any>
  {
    return new Promise<any>((resolve, reject) => {

    });
  }

  /**
   * Get Password
   */
  getPassword(): Promise<any>
  {
    return new Promise<any>((resolve, reject) => {

    });
  }
}
