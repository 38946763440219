<div class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'family.list.header.title'">
        <button
          mat-raised-button
          routerLink="/family/edit"
          color="primary"
          aria-label="button with a add one icon">
          <mat-icon>add</mat-icon>
          <mat-label>{{"family.list.member.new" | translate}}</mat-label>
        </button>
      </app-header>
      <mat-form-field>
        <input (keydown.enter)="searchClicked($event)" #searchText type="text" matInput placeholder="{{'family.search.label' | translate}}"
               [formControl]="searchFormControl" maxlength="255"/>
        <button matSuffix mat-button matTooltip="{{ 'family.search.label' | translate}}"
                (click)="searchClicked($event)"><mat-icon >search</mat-icon></button>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <table style="width: 100%;" #table mat-table [dataSource]="tableDataSource"  class="mat-elevation-z4" >
        <!-- Checkbox column-->
        <ng-container matColumnDef="checked">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? checkBoxChange(element) : null"
                          [checked]="selection.isSelected(element)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Registration number Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef > {{ "family.list.header.name.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.fullname}} </td>
        </ng-container>

        <!-- Age date Column -->
        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef > {{ "family.list.header.year-old.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{getYear(element)}} </td>
        </ng-container>

        <!-- Creation date Column -->
        <ng-container matColumnDef="since">
          <th mat-header-cell *matHeaderCellDef > {{ "family.list.header.since.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.creationDate | date }} </td>
        </ng-container>


        <!-- relation type Column -->
        <ng-container matColumnDef="relation">
          <th mat-header-cell *matHeaderCellDef > {{ "family.list.header.relation-type.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.relationType }} </td>
        </ng-container>

        <!-- is live Column -->
        <ng-container matColumnDef="dead">
          <th mat-header-cell *matHeaderCellDef > {{ "family.list.header.is-live.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.dead }} </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 2em;" > </th>
          <td mat-cell *matCellDef="let element" style="width: 2em;">
            <button mat-icon-button (click)="viewElement(element)" style="color: orange;" matTooltip="{{ 'btn.show.label' | translate}}">
              <mat-icon>visibility</mat-icon></button>
          </td>
        </ng-container>
        <!-- pagination on footer -->
        <ng-container matColumnDef="pagination">
          <td mat-footer-cell *matFooterCellDef colspan="5" class="footer-td" >
            <mat-paginator *ngIf="paginatorParams" [length]="paginatorParams.length"
                           (page)="pageChanged($event)"
                           [pageSize]="paginatorParams.pageSize"
                           [pageSizeOptions]="paginatorParams.pageSizeOptions">
            </mat-paginator>
          </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter</td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="['checked','name', 'year', 'relation', 'since', 'action']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['checked','name', 'year', 'relation', 'since', 'action'];" (click)="checkBoxChange(row)"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" ></tr>
      </table>
    </div>

    <app-buttons-bar
      #btnBar *ngIf="buttons"
      [functionality]="'family-list'"
      [actions]="buttons"
      (action)="btnActionClick($event)">
    </app-buttons-bar>
  </div>
</div>
