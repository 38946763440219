<mat-card class="">
    <mat-card-header>
      <mat-card-title> {{title| translate}}</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
    <form [formGroup]="contactPersonForm" class="formContact">

      <mat-form-field class="formContactInput">
        <input required matInput placeholder="{{'single.contact.person.name.label' | translate}}" name="nameContact"
           formControlName="fullName">
        <mat-error *ngIf="contactPersonForm.controls.fullName.errors && contactPersonForm.controls.fullName.hasError('required')">{{ "member.name.empty.error" | translate}}</mat-error>
        <mat-error *ngIf="contactPersonForm.controls.fullName.errors && !contactPersonForm.controls.fullName.errors?.notEmpty">{{ "validator.not.empty" | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field class="formContactInput">
        <input required matInput
               placeholder="{{'single.contact.person.phone.label' | translate}}"
               name="phoneContact"
               maxlength="200"
               formControlName="phone">
        <mat-icon matSuffix>phone_android</mat-icon>
        <mat-error *ngIf="contactPersonForm.controls.phone.invalid">{{phoneErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
        <mat-label>{{'single.contact.person.email.label' | translate}}</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="contactPersonForm.controls.email.errors && contactPersonForm.controls.email.hasError('required')">{{ "register.partner.general.Information.form.association-email-address.required" | translate}}</mat-error>
        <mat-error *ngIf="contactPersonForm.controls.email.errors?.email">{{ "pattern.email.error" | translate}}</mat-error>
      </mat-form-field>
</form>
</mat-card-content>
</mat-card>
