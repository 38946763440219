<div>
  <div class="app">
    <form [formGroup]="headerForm" name='record-information-edit-form-header'>
      <div class="row">
        <mat-form-field class="col-xs-12 col-sm-6 col-md-6" >
          <input
            type="text"
            placeholder="{{'record-information.edit.documentNumber.label'| translate}}"
            [formControl]="headerForm.get('number')"
            name='documentNumber'
            matInput maxlength="255"
            [value]="_currentObject.record.number" >
          <mat-error *ngIf="headerForm.get('number').errors">{{ "record-information.edit.documentNumber.empty.error" | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="col-xs-12 col-sm-6 col-md-6">
          <mat-label>{{ 'record-information.form.type.label' | translate }}</mat-label>
          <input required
                 [formControl]="headerForm.get('type')" matInput
                 [matAutocomplete]="autoType">
          <mat-autocomplete #autoType="matAutocomplete" [displayWith]="display">
            <mat-option  *ngFor="let option of types" [value]="option">
              {{ display(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field class="col-xs-12 col-sm-6 col-md-6">
          <input
            matInput
            [max]="maxDate"
            [matDatepicker]="startP"
            [value]="_currentObject.record.start"
            name='start'
            placeholder="{{'record-information.edit.start.label' | translate}}"
            [formControl]="headerForm.get('start')">
          <mat-datepicker-toggle matSuffix [for]="startP"></mat-datepicker-toggle>
          <mat-datepicker #startP></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-xs-12 col-sm-6 col-md-6">
          <input
            matInput
            [min]="maxDate"
            [matDatepicker]="period"
            [value]="_currentObject.record.end"
            name='end'
            placeholder="{{'record-information.edit.end.label' | translate}}"
            [formControl]="headerForm.get('end')">
          <mat-datepicker-toggle matSuffix [for]="period"></mat-datepicker-toggle>
          <mat-datepicker #period></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-xs-12 col-sm-12 col-md-12" >
          <textarea
            type=""
            placeholder='{{"record-information.edit.description.label" | translate}}'
            [formControl]="headerForm.get('description')"
            name='lastAmount'
            matInput maxlength="255"
            [value]="_currentObject.record.description" ></textarea>
          <mat-error *ngIf="headerForm.get('description').errors">{{ "record-information.edit.description.empty.error" | translate}}</mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
