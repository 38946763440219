import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Member} from '../../../../model/organisation/Member';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpService} from '../../../../services/http-service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification-service/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../../../../@noble/services/config.service';
import {UserAppService} from '../../../../services/user-service/user-app.service';
import {RegisterService} from '../../../../services/register/register.service';

@Component({
  selector: 'app-family-member-update',
  templateUrl: './family-member-update.component.html',
  styleUrls: ['./family-member-update.component.scss']
})
export class FamilyMemberUpdateComponent implements OnInit {

  @Input() member: Member;

  @Output() update: EventEmitter<Member> = new EventEmitter<Member>();
  @Output() endUpdate: EventEmitter<Member> = new EventEmitter<Member>();

  familyMemberForm: FormGroup;

  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  loading = false;
  disabledSave = true;

  static numeric(control: AbstractControl) {
    const val = control.value;

    if (val === null || val === '')  {
      return null;
    }

    if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) {
      return { invalidNumber: true };
    }

    return null;
  }

  constructor(private fb: FormBuilder,
              private httpService: HttpService<Member>,
              private router: Router,
              private route: ActivatedRoute,
              private msgService: NotificationService,
              protected translateService: TranslateService,
              private _configService: ConfigService) { }

  ngOnInit(): void {
    this.initForm();

    this.familyMemberForm.valueChanges.subscribe(va => {
      if (this.familyMemberForm.valid  && va.dead !== this.member.dead) {
        this.disabledSave = false;
      } else if (this.familyMemberForm.valid && va.mobilePhone !== this.member.mobilePhone) {
        this.disabledSave = false;
      } else if (this.familyMemberForm.valid &&
        (va.emailAddress !== null && va.emailAddress.trim() !== '') && va.emailAddress !== this.member.emailAddress) {
        this.disabledSave = false;
      } else if (this.familyMemberForm.valid && (va.fax !== null && va.fax.trim() !== '') && va.fax !== this.member.fax) {
        this.disabledSave = false;
      } else  {
        this.disabledSave = true;
      }
    });

    this.endUpdate.subscribe(res => {
      this.member = res;
      this.familyMemberForm.setValue({
        dead: this.member.dead,
        mobilePhone: this.member.mobilePhone,
        emailAddress: this.member.emailAddress,
        fax: this.member.fax
      });
      this.loading = false;
    });
  }

  initForm() {
    this.familyMemberForm = this.fb.group({
      dead: [this.member.dead,  []],
      fax: [this.member.fax,  []],
      mobilePhone: [this.member.mobilePhone,  [FamilyMemberUpdateComponent.numeric]],
      emailAddress: [this.member.emailAddress, [Validators.email]],
    });
  }

  save() {
    this.loading =  true;
    const entity = this.member;
    entity.dead = this.familyMemberForm.value.dead;
    entity.mobilePhone = this.familyMemberForm.value.mobilePhone;
    entity.emailAddress = this.familyMemberForm.value.emailAddress;
    entity.fax = this.familyMemberForm.value.fax;
    this.update.emit(entity);
  }

}
