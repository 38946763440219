import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAppService } from 'app/services/user-service/user-app.service';
import {ConfigService} from '@noble/services/config.service';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {

  memberConditions: string [];

  constructor(private route: ActivatedRoute, private router: Router,
              private userService: UserAppService,
              private _configService: ConfigService,
  ) {
    // Configure the layout
    this._configService.config = {
      layout: {
        style   : 'app-navbar-home',
      }
    };

    this.userService.isHomeURL.emit(true);
  }

  ngOnInit(): void {
    this.memberConditions = [
      'membership.become-member.description2-item1.label',
      'membership.become-member.description2-item2.label',
      'membership.become-member.description2-item3.label',
    ];
  }

  register(){
    this.router.navigate(['register']);
  }

  contactUs(){
    this.router.navigate(['home/contact-us']);
  }

}
