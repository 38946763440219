<div class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'deposit.first.title'"></app-header>
    </mat-card-content>
  </mat-card>

  <div class="flex">
    <ng-template [ngIf]="'true'">
      <div class="description">
        <span style='font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; color: black'>{{'deposit.make.title.option' | translate}}</span>
        <div style="max-width: 400px"><p>{{'deposit.option.description' | translate}}</p></div>

        <mat-card>
          <mat-card-header>
            <mat-card-title>{{"make.deposit.add.member.label" | translate}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-4">
                <section class="example-section">
                  <mat-checkbox
                    class="example-margin"
                    [checked]="true"
                    (change)="check($event)" name="add"
                    [(indeterminate)]="indeterminate"
                    [labelPosition]="labelPosition">
                    {{"make.deposit.add.me.label"| translate}}
                  </mat-checkbox>
                </section>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4">
                <section class="example-section">
                  <mat-checkbox
                    class="example-margin"
                    [(ngModel)]="selectAll"
                    (change)="checkAllMeme($event)" name="all"
                    [(indeterminate)]="indeterminate"
                    [labelPosition]="labelPosition">
                    {{"make.deposit.add.all.label"| translate}}
                  </mat-checkbox>
                </section>
              </div>
            </div>
            <div class="row">
              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                <mat-label>{{"make.deposit.input.label" | translate}} *</mat-label>
                <mat-chip-list #chipList>
                  <mat-chip
                    *ngFor="let m of selectedMember"
                    selectable="true"
                    [removable]="removable"
                    (removed)="remove(m)">
                    {{displayMemberFn(m)}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input
                    #nativeInput
                    [formControl]="memberControl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)" required>
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayMemberFn">
                  <mat-option  *ngFor="let row of showMember" [value]="row">{{displayMemberFn(row)}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          (change)="changeOption($event)"
          [(ngModel)]="favoriteOption">
          <ng-container *ngFor="let option of options">
            <mat-radio-button class="example-radio-button"  [value]="option">
              <span style='font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; color: black'>{{option.text | translate}}</span>
            </mat-radio-button>
          </ng-container>
        </mat-radio-group>
        <div style="max-width: 400px"><p>{{favoriteOption?.description | translate}}</p></div>
      </div>
    </ng-template>

    <div class="deposit">
      <div class="list-items">
        <div class="description-title">
          <ng-template [ngIf]="favoriteOption?.value">
            <span style='font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; color: black'>
            {{'deposit.title.plan' | translate}} {{favoriteOption.value}} euros
          </span>
          </ng-template>
        </div>
      </div>
      <mat-card class="form-deposit">
        <mat-card-content>

          <mat-tab-group mat-stretch-tabs dynamicHeight="true" (selectedIndexChange)="changeTap($event)">

            <mat-tab>
              <ng-template mat-tab-label>
                <button style="color: #ff6400" mat-raised-button>
                  Credits cards
                </button>
              </ng-template>
              <app-stripe-payment [depositAmount]="favoriteOption?.value" #stripePaymentComponent></app-stripe-payment>
            </mat-tab>
            <!--<mat-tab>
              <ng-template mat-tab-label>
                <button style="color: #ff6400" mat-raised-button>
                  PayPal
                </button>
              </ng-template>
              <app-paypal-payment></app-paypal-payment>
            </mat-tab>-->
            <mat-tab>
              <ng-template mat-tab-label>
                <button style="color: #ff6400" mat-raised-button>
                  Virement
                </button>
              </ng-template>
              <app-other-payment></app-other-payment>
            </mat-tab>
          </mat-tab-group>


<!--          <form >-->
<!--            <mat-form-field class="amount">-->
<!--              <input matInput placeholder="{{'deposit.make.amount.label' | translate}}" name="nameContact"-->
<!--                     [(ngModel)]="amount" [formControl]="depositAmountControl">-->
<!--              <mat-error *ngIf="depositAmountControl.invalid">{{'deposit.make.error.message' | translate}}</mat-error>-->
<!--            </mat-form-field>-->
<!--            <div style="margin-left: 3%;">-->
<!--              <app-buttons-bar-->
<!--                #btnBar *ngIf="buttons"-->
<!--                [functionality]="'deposit.make.btn.list.label'"-->
<!--                [actions]="buttons"-->
<!--                (action)="btnActionClick($event)">-->
<!--              </app-buttons-bar>-->
<!--            </div>-->
<!--          </form>-->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
