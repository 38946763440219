export enum Funtions {
  dashboard = 'dashboard',
  make_deposit = 'make.deposit',
  association = 'association',
  member_btn_new_fct = 'member.btn.new.label',
  member_btn_list_fct = 'member.btn.list.label',
  transaction = 'transaction',
  notification = 'notification',
  partner_btn_list_fct = 'partner.btn.list.label',
  document_btn_list_fct = 'document.btn.list.label',
  family_btn_edit_fct = 'family.btn.edit.label',
  family_btn_list_fct = 'family.btn.list.label',
}
