import * as rule from './reducers/rule.reducer';
import {createFeatureSelector} from '@ngrx/store';

export interface RuleState {
  ruleState: rule.UserRuleState;
}

export const ruleReducer = {
  rule: rule.userRuleReducer,
};

export const selectRuleState = createFeatureSelector<RuleState>('rule');

