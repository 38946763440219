import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileDownloaderComponent } from 'app/components/file-downloader/file-downloader.component';
import { UserAppService } from 'app/services/user-service/user-app.service';
import {DownloadFile} from '../../../model/file/downloadFile';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {SearchCriteria} from '../../../model/data-page';
import {TranslateService} from '@ngx-translate/core';
import {ClassNameMap} from '../../../model/persistent/persistent';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit {
  criteria: any | SearchCriteria = new SearchCriteria();


  nobleLink: {routerLink: string, label: string}[];
  usefulLink: {routerLink: string, label: string, element: DownloadFile}[];
  infosLink: {routerLink: string, label: string, element: DownloadFile}[];

  /**
   * A reference on download component
   */
  @ViewChild('downloader')
  downloader: FileDownloaderComponent;
  openFileInTab = true;

  COOKIES_AND_PRIVCACY = 'footer.link.legal.cookies-and-privacy.label';
  file1 = 'http://localhost:4200/assets/documents/declaration_relative_a_la%20vie_privee_NR-ASBL.pdf';
  file2 = 'http://localhost:4200/assets/documents/politique_relative_aux_cookies-_NR-ASBL.pdf';

  constructor(private route: ActivatedRoute,
              protected translateService: TranslateService,
              protected msgService: NotificationService,
              private router: Router,
              private userService: UserAppService) {
  }

  ngOnInit(): void {

    this.nobleLink = [
      {
        routerLink: '/home',
        label: 'app.home.label',
      },
      {
        routerLink: '/home/about-us',
        label: 'app.about-us.label',
      },
      {
        routerLink: '/home/our-project',
        label: 'app.our-project.label',
      },
      {
        routerLink: '/home/membership',
        label: 'app.membership.label',
      },
      {
        routerLink: '/home/contact-us',
        label: 'app.contact-us.label',
      },
      /*{
        routerLink: '/home/news',
        label: 'footer.link.noble-retour.news.label',
      },
      {
        routerLink: '/home/make-donation',
        label: 'footer.link.noble-retour.make-donation.label',
      },*/
    ];


    this.usefulLink = [
      {
        routerLink: '/home/faq',
        label: 'footer.link.usefull.faq.label',
        element: {
          value: 'NR_Faq',
          classe: ClassNameMap.DownloadFile,
        }
      },
      {
        routerLink: '/home/confidentiality',
        label: 'membership.btn.adhere.label',
        element: {
          classe: ClassNameMap.DownloadFile,
          value: 'NR_Formulaire_adhesion_Membre'
        }
      },
      /*{
        routerLink: '/home/faq',
        label: 'footer.link.usefull.important-documents.label',
      },*/
      // {
      //   routerLink: '/home/use-conditions',
      //   label: "footer.link.usefull.use-conditions.label",
      // },

      /*{
        routerLink: '/home/partners',
        label: 'footer.link.usefull.partners.label',
      },*/
    ];

    this.infosLink = [
      {
        routerLink: '/home/use-conditions',
        label: 'footer.link.legal.use-conditions.label',
        element: {
          classe: ClassNameMap.DownloadFile,
          value: 'NR_Conditions_Generales'
        }
      },
      {
        routerLink: '/home/cookies-and-privacy',
        label: this.COOKIES_AND_PRIVCACY,
        element: {
          classe: ClassNameMap.DownloadFile,
          value: 'Politique_relatif_aux_cookies'
        }
      },
      {
        routerLink: '/home/confidentiality',
        label: 'footer.link.legal.confidentiality.label',
        element: {
          classe: ClassNameMap.DownloadFile,
          value: 'NR_Declaration_relative_a_la_vie_priveee'
        }
      },
    ];

  }

  beginDownload(element: DownloadFile) {
    this.criteria.lang = this.translateService.currentLang;
    this.criteria.value = element.value;
    this.criteria.code = element.value + '.pdf';

    this.downloader.criteria = this.criteria;
    this.downloader.criteria.classe = 'CriteriaSearch';
    this.downloader.link = 'organisation/documents/print';
    this.downloader.downloadFile({});
    this.downloader.event.subscribe( (wellDone) => {
      if (wellDone === false) {
        this.msgService.log('form.print.error', NotificationType.ERROR);
      }
    });
  }

  protected quickBtnClicked(btn) {
    this.router.navigate(btn.routerLink);
  }

  // onClickBtn(btn){
  //   console.log(btn)
  //   if(btn.label === this.COOKIES_AND_PRIVCACY){
  //     const file1 = "http://localhost:4200/assets/documents/declaration_relative_a_la%20vie_privee_NR-ASBL.pdf";
  //     const file2 = "http://localhost:4200/assets/documents/politique_relative_aux_cookies-_NR-ASBL.pdf";
  //     // this.downloaderComponent.launchFileDownload(file1);
  //     this.router.navigate(file1);
  //     // this.router.navigate(file1);
  //   }else{
  //     this.router.navigate(btn.routerLink);
  //   }
  // }
}
