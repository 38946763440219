import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Donation, Civility, Amount } from 'app/model/organisation/Donation';
import { Company, Party } from 'app/model/organisation/Party';
import { GenderType, Person } from 'app/model/organisation/Person';
import { UserAppService } from 'app/services/user-service/user-app.service';
import { Country } from '../../../model/country/Country';
import { City } from 'app/model/country/City';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { RegisterService } from 'app/services/register/register.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Address } from 'app/model/country/Address';

@Component({
  selector: 'app-make-donation',
  templateUrl: './make-donation.component.html',
  styleUrls: ['./make-donation.component.scss']
})
export class MakeDonationComponent implements OnInit, OnDestroy{

  amountControl = new FormControl();
  emailControl = new FormControl('', [Validators.required, Validators.pattern(/[+a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)]);
  surnameControl = new FormControl(null, [Validators.required]);
  nameControl = new FormControl('', [Validators.required]);
  streetControl = new FormControl('', [Validators.required]);
  streetNumberControl = new FormControl('', [Validators.required]);
  boxControl = new FormControl();
  postalControl = new FormControl('', [Validators.required]);
  cityControl = new FormControl('', [Validators.required]);
  countryControl = new FormControl('', [Validators.required]);

  donation = new Donation();
  party: {emailAddress: string, surname?: string, name: string, gender?: string, address: Address};

  giveOnce: {label: string, value: number}[];
  everyMonth: {label: string, value: number}[];

  donationFormGroup: FormGroup;

  /**
   * Donor type toggle properties
   */
  color: ThemePalette = 'accent';
  donorChecked = false;

  /**
   * Civiltity list
   */
  protected civilityList: {value?: any, text?: any}[];
  protected civilitySelected: Civility;

  /**
   * Civiltity Form control
   */
  civilityControl: FormControl = new FormControl(null, [Validators.required]);

   /**
    * Phone control of member
    */
  phoneControl = new FormControl(null, [Validators.required, Validators.pattern(/[+0-9]{9}/)]);

  countries: Country[] = [];
  cities: City[] = [];

  phoneCountryCode = '';

  separatorKeysCodes: number[] = [ENTER, COMMA];

  protected MALE = GenderType[GenderType.MALE];
  protected FEMALE = GenderType[GenderType.FEMALE];

  constructor(private userService: UserAppService, private registerService: RegisterService) {

    this.civilityList = [
      {value: null , text: ''},
      {value: Civility[Civility.M],
        text: 'civility.' + Civility[Civility.M] + '.label'},
      {value: Civility[Civility.MME],
        text: 'civility.' + Civility[Civility.MME] + '.label'},
      {value: Civility[Civility.OTHER],
        text: 'civility.' + Civility[Civility.OTHER] + '.label'},
    ];

    this.party = {
      emailAddress: '',
      name: '',
      address: new Address(),
    };

    this.party.address.locality = new City();
    this.party.address.locality.country = new Country();

    this.amountControl.valueChanges.subscribe(result => {
      this.donation.amount.value = result;
      this.donation.amount.currency = '€';
    });

    this.emailControl.valueChanges.subscribe(result => {
      this.party.emailAddress = result;
    });

    this.surnameControl.valueChanges.subscribe(result => {
      this.party.surname = result;
    });

    this.nameControl.valueChanges.subscribe(result => {
      this.party.name = result;
    });

    this.streetControl.valueChanges.subscribe(result => {
      this.party.address.street = result;
    });

    this.streetNumberControl.valueChanges.subscribe(result => {
      this.party.address.number = result;
    });

    this.boxControl.valueChanges.subscribe(result => {
      this.party.address.locality.boxCode = result;
    });

    this.postalControl.valueChanges.subscribe(result => {
      this.party.address.locality.postCode = result;
    });

    this.cityControl.valueChanges.subscribe(result => {
      this.party.address.locality.name = result.name;
    });

    this.countryControl.valueChanges.subscribe(result => {
      this.party.address.locality.country = result;
    });

    this.civilityControl.valueChanges.subscribe(result => {
      if (result ===  this.civilityList[1].value) {
        this.party.gender = this.MALE;
      } else if (result ===  this.civilityList[2].value){
        this.party.gender = this.FEMALE;
      }else{
        this.party.gender = Civility[Civility.OTHER];
      }
    });

    this.countryControl.valueChanges.subscribe(result => {
      if (!result || typeof result === 'string') {
        this.getCountry(result);
      } else {
        this.donation.donor.address.locality.country = result;
        this.phoneCountryCode = `+${result.phoneCode}`;
      }
    });
  }

  ngOnInit(): void {
    this.userService.isHomeURL.emit(true);

    this.donationFormGroup = new FormGroup({
      amountControl: this.amountControl,
      emailControl: this.emailControl,
      civilityControl: this.civilityControl,
      surnameControl: this.surnameControl,
      nameControl: this.nameControl,
      streetControl: this.streetControl,
      streetNumberControl: this.streetNumberControl,
      boxControl: this.boxControl,
      phoneControl: this.phoneControl,
      postalControl: this.postalControl,
      cityControl: this.cityControl,
      countryControl: this.countryControl,

    });

    this.giveOnce = [
      {
        label: '10 €',
        value: 10,
      },
      {
        label: '25 €',
        value: 25,
      },
      {
        label: '50 €',
        value: 50,
      },
      {
        label: '100 €',
        value: 100,
      },
    ];

    this.everyMonth = [
      {
        label: '10 €',
        value: 10,
      },
      {
        label: '20 €',
        value: 20,
      },
      {
        label: '30 €',
        value: 30,
      },
    ];

  }

  selectAmount(amount){
    console.log(amount);
    this.amountControl.setValue(amount);
  }

  /**
   * @param event
   * Check each time you click on the donor type toggle
   */
  donorChange(event){
    console.log(event);
    console.log(this.donorChecked);

    if (this.donorChecked){
      this.donation.donor = new Party();
    }else{
      this.donation.donor = new Person();
    }

    this.donorChecked = event.checked;
  }

  getCity(value) {
    const countryId = this.donation.donor.address.locality.country ?
      ( this.donation.donor.address.locality.country.id ?
        this.donation.donor.address.locality.country.id : null ) : null;
    this.registerService.getCity(value, countryId).then(citiesData => {
      this.cities = citiesData ? citiesData : [];
    });
  }

 getCountry(value) {
    this.registerService.getCountry(value).then(countries => {
      this.countries = countries ? countries : [];
    });
  }

  displayCountryFn(country?: Country): string {
    return country ? `${country.name}` : '';
  }

  displayCityFn(city?: City): string {
    return city ? `${city.name}` : '';
  }

  addCountries(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.countries = [].concat([value]);
      this.countryControl.setValue(value);
    }

    if (input) {
      input.value = '';
    }
  }

  addCity(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.cities = [].concat([value]);
      this.cityControl.setValue(value);
    }

    if (input) {
      input.value = '';
    }
  }

  selectedCountry(event: MatAutocompleteSelectedEvent): void {
    this.countryControl.setValue(event.option.value);
    this.countries = [].concat([event.option.value]);
  }

  selectedCity(event: MatAutocompleteSelectedEvent): void {
    this.cityControl.setValue(event.option.value);
    this.cities = [].concat([event.option.value]);
  }

  removeCountry() {
    this.countries = [];
    this.countryControl.setValue(null);
  }

  removeCity() {
    this.cities = [];
    this.cityControl.setValue(null);
  }

  getDatas(): Donation {
    this.donation.donor = Object.assign(this.donation.donor, this.party);
    return this.donation;
  }

  validate(){
    console.log(this.donation);
  }

  ngOnDestroy(){
    this.userService.isHomeURL.emit(false);
  }

}
