<div class="banner">
  <div class="banner-body" style="flex-direction: column">
    <div class="banner-title">{{ "membership.title" | translate }}</div>

    <div class="mt-5">
      <button mat-raised-button color="primary"
              (click)="register()">
        {{ "membership.btn.adhere.label" | translate}}
      </button>

      <button class="ml-2" mat-raised-button
              (click)="contactUs()">{{ "membership.btn.contact-us.label" | translate}}
      </button>

    </div>
  </div>
  <img class="banner-bg-img" src="assets/images/adhesion-60ans.jpg"  alt="banner img">
  <div class="banner-glass"></div>

</div>


<div class="page-body d-flex justify-content-between">

  <div style="max-width: 880px">
    <div class="p-3">
      <h1 class="section-title">{{"membership.why-become-member.title.label"| translate}}</h1>
      <div class="paragraph">
        {{"membership.why-become-member.description.label"| translate}}
      </div>
    </div>

    <div class="p-3">
      <h1 class="section-title">{{"membership-conditions.title.label"| translate}}</h1>
      <div class="paragraph">
        <p>{{"membership-conditions.description1.label"| translate}}</p>
        <p>{{"membership-conditions.description2.label"| translate}}</p>
        <p>{{"membership-conditions.description3.label"| translate}}</p>
      </div>
    </div>
  </div>
  <div>
    <img src="assets/images/become-member2.jpg" style="width: 100%; height: auto" class="d-none d-sm-block">
  </div>

</div>


<div class="become-member-zone">
  <div class="row become-align-items become-member-container">
    <h1 class="section-title">{{"membership.become-member.title.label"| translate}}</h1>

    <div class="paragraph">
      <p class="become-member-description">
        {{"membership.become-member.description1.label"| translate}}
      </p>
      <p class="become-member-description">
        {{"membership.become-member.description5.label"| translate}}
      </p>

      <ul class="become-member-description">
        <li *ngFor="let condition of memberConditions">
          <b> {{condition | translate}} </b>
        </li>
      </ul>

      <p class="become-member-description">
        {{"membership.become-member.description3.label"| translate}}
      </p>
    </div>
    <div class="text-center mt-4">
      <button mat-raised-button color="primary"
              (click)="register()">
        <span>{{"membership.btn.register.label" | translate}}</span>
      </button>
    </div>
  </div>

</div>
<br><br>

<app-footer-bar></app-footer-bar>
