import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  onPhotoUploaded: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  uploadItem(fileToUpload: File | FileList, path: string): Observable<any> {
    return this.upload(fileToUpload, path);
  }

  updateItem(fileToUpload: File | FileList, path: string, id: string): Observable<any> {
    return this.update(fileToUpload, path, id);
  }

  uploadStore(fileToUpload: File): Observable<any> {
    return this.upload(
      fileToUpload,
      environment.apiHost +
      environment.uploadFile +
      environment.uploadStore
    );
  }

  upload(fileToUpload: File | FileList, path: string): Observable<any> {
    let formData: FormData;
    if (fileToUpload instanceof File) {
      formData = new FormData();
      formData.append('recordFile', fileToUpload, fileToUpload.name);
    } else if (fileToUpload instanceof FileList) {
      formData = new FormData();

      for (const field in fileToUpload) {
        if (
          fileToUpload[field] &&
          field !== 'length' &&
          field !== '__proto__' &&
          field !== 'item'
        ) {
          formData.append(
            'recordFile',
            fileToUpload[field],
            fileToUpload[field].name
          );
        }
      }
    }

    return this.http.post(path, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map(event => {
        const msg = this.getEventMessage(event, formData);
        return msg;
      })
    );
  }

  update(fileToUpload: File | FileList, path: string, id: string): Observable<any> {
    let formData: FormData;
    if (fileToUpload instanceof File) {
      formData = new FormData();
      formData.append('recordFile', fileToUpload, fileToUpload.name);
    } else if (fileToUpload instanceof FileList) {
      formData = new FormData();

      for (const field in fileToUpload) {
        if (
          fileToUpload[field] &&
          field !== 'length' &&
          field !== '__proto__' &&
          field !== 'item'
        ) {
          formData.append(
            'recordFile',
            fileToUpload[field],
            fileToUpload[field].name
          );
        }
      }
    }

    return this.http.put(`${path}/${id}`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map(event => {
        const msg = this.getEventMessage(event, formData);
        return msg;
      })
    );
  }

  getImage(imageName: string): Observable<any> {
    return this.http.get(environment.apiHost + environment.downloadImg + '/' + imageName);
  }

  private getEventMessage(event: HttpEvent<any>, formData): any {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

      default:
        return `File "${
          formData.get('recordFile').name
        }" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event): any {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    return {status: 'progress', message: percentDone};
  }

  private apiResponse(event): any {
    return {
      status: 'done',
      record_name: event.body.record_name,
      externalId: event.body._id,
      creationAt: event.body.creationAt
    };
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
}
