import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {ProfileService} from '../../../../services/profile.service';
import {takeUntil} from 'rxjs/operators';
import {ConfigService} from '@noble/services/config.service';
import {Beneficiary} from '../../../../model/register/Beneficiary';
import {User} from '../../../../model/security/user';
import {MemberShip} from '../../../../model/register/MemberShip';
import {State} from '../../../../store/login/reducers/authentication.reducer';
import {RegisterService} from '../../../../services/register/register.service';
import {LoginState, selectAuthenticationState} from '../../../../store/login/login.state';
import {Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GenderType} from '../../../../model/organisation/Person';
import {TranslateService} from '@ngx-translate/core';
import {FormAction, IFormActionBarButton} from '../../../base/buttons-bar/buttons-bar.component';
import {Router} from '@angular/router';
import {NotificationService, NotificationType} from '../../../../services/notification-service/notification.service';
import {UserAppService} from '../../../../services/user-service/user-app.service';
import {NobleValidators} from "../../../base/validators/noble-validators";
let self;

@Component({
  selector: 'app-profile-beneficiary',
  templateUrl: './profile-beneficiary.component.html',
  styleUrls: ['./profile-beneficiary.component.scss']
})
export class ProfileBeneficiaryComponent implements OnInit, OnDestroy
{
  beneficiary: any;

  beneficiaryForm: FormGroup;

  mode: 'edit' | 'view' = 'view';

  currentObject: Beneficiary;

  private _unsubscribeAll: Subject<any>;

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  public maxDate: Date;
  public age = 0;

  currentUser: User;
  currentMemberShip: MemberShip;

  selectedGender: {value: string, text: string};
  genders: any[] = [
    {value: GenderType[GenderType.FEMALE], text: 'translate.women'},
    {value: GenderType[GenderType.MALE], text: 'translate.boy'},
  ];

  buttons: IFormActionBarButton[];

  constructor(
    private _profileService: ProfileService,
    private _configService: ConfigService,
    private _registerService: RegisterService,
    private msgService: NotificationService,
    private userService: UserAppService,
    private router: Router,
    private store: Store<LoginState>,
    private fb: FormBuilder,
    protected translateService: TranslateService,
  )
  {
    self = this;
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };
    this._unsubscribeAll = new Subject();
    this.authenticationState$ = this.store.select(selectAuthenticationState);

    const date = new Date();
    this.maxDate = new Date(date.getFullYear() , date.getMonth(), date.getDate());

    this.buttons = [
      {id: 'save', value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: []},
      {id: 'cancel', value: FormAction.CANCEL, icon: {type : 'cancel'},
        text: 'btn.cancel.label', disabled: false, functionalities: []}
    ];
  }

  ngOnInit(): void
  {
    this.initForm();

    this._profileService.beneficiaryOnChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(beneficiary => {
        this.beneficiary = beneficiary;
      });

    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.currentUser = state.user.user;
        this._registerService.findMemberShipByLogin(this.currentUser.login).then(memberShip => {
          this.currentMemberShip = memberShip;
          this._registerService.findBeneficiaryByMemberShipId(`${this.currentMemberShip.id}`).then(b => {
            this.currentObject = b;
            this.setForm();
          });
        });
      }
    });
  }

  ngOnDestroy(): void
  {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.authenticationSubscription.unsubscribe();
  }

  addBeneficiary() {
    this.mode = 'edit';
    this.initForm();
  }

  editBeneficiary() {
    this.mode = 'edit';
    this.setForm();
  }

  initForm() {
    this.beneficiaryForm = this.fb.group({
      name: [null,  [Validators.required]],
      surname: [null,  [Validators.required]],
      birthdate: [null, [Validators.required]],
      gender: [null, [Validators.required]],
      fax: [null, [Validators.required]],
      emailAddress: [null, [Validators.required, Validators.email]],
      mobilePhone: [null, [Validators.required, NobleValidators.numeric]],
      description: [null, []]
    });
  }

  setForm() {
    if (this.currentObject) {
      this.genders.forEach((s: {value: string, text: string}) => {
        if (s.value === this.currentObject.gender) {
          this.selectedGender = s;
          this.beneficiaryForm.setValue({
            name: this.currentObject.name,
            surname: this.currentObject.surname,
            fax: this.currentObject.fax,
            emailAddress: this.currentObject.emailAddress,
            mobilePhone: this.currentObject.mobilePhone,
            description: this.currentObject.description,
            birthdate: this.currentObject.birthdate,
            gender: s
          });
        }
      });
    }
  }

  display(val: {value: string, text: string}) {
    let response = '';
    if (val) {
      response = self.translateService.instant(val.text);
    }
    return response;
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id){
      this.save();
    } else if (event && event.id === this.buttons[1].id){
      this.mode = 'view';
    }
  }

  getObjectFromView(): Promise<Beneficiary>{
    return new Promise((resolve, reject) => {
      let persistent = new Beneficiary();
      if (this.currentObject) {
        persistent = Object.assign(this.currentObject, this.beneficiaryForm.value);
      } else {
        persistent.name = this.beneficiaryForm.get('name').value;
        persistent.surname = this.beneficiaryForm.get('surname').value;
        persistent.fax = this.beneficiaryForm.get('fax').value;
        persistent.emailAddress = this.beneficiaryForm.get('emailAddress').value;
        persistent.mobilePhone = this.beneficiaryForm.get('mobilePhone').value;
        persistent.description = this.beneficiaryForm.get('description').value;
        persistent.birthdate = this.beneficiaryForm.get('birthdate').value;
      }
      persistent.gender = this.beneficiaryForm.get('gender').value?.value;
      persistent.memberShip = this.currentMemberShip;
      delete persistent.address;
      delete persistent.nationality;
      resolve(persistent);
    });
  }

  isValid(): boolean{
    return this.beneficiaryForm.valid;
  }

  save() {
    this.getObjectFromView().then((persistence: Beneficiary) => {
      if (this.isValid()) {
        this._registerService.saveBeneficiary(persistence).then(res => {
          this.msgService.log('beneficiary.edit.save.success', NotificationType.SUCCESS);
          this.currentObject = res;
          this.mode = 'view';
        }).catch(err => {
          this.msgService.log('beneficiary.edit.save.fail', NotificationType.ERROR);
        });
      } else {
        this.msgService.log('beneficiary.edit.save.fail', NotificationType.ERROR);
      }
    });
  }

  getYear(element: Beneficiary) {
    if (element === undefined || element === null || element.birthdate === undefined || element.birthdate === null) {
      return 0;
    }
    return  new Date(Date.now()).getFullYear() - new Date(element.birthdate).getFullYear();
  }
}
