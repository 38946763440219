<div  class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'family.show.header.title'">
        <button
          mat-raised-button
          routerLink="/family/list"
          color="primary"
          aria-label="button with a add one icon">
          <mat-icon>list</mat-icon>
          <mat-label>{{"family.list.header.title" | translate}}</mat-label>
        </button>
      </app-header>
    </mat-card-content>
  </mat-card>

  <mat-card class="example-card col-xs-12  col-sm-12">
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf='member'>
      <div class="row"  style="margin-bottom: 15px">
        <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="member.fullname">
          <label style="margin-right: 15px">{{"family-member.name.label" | translate}}</label>
          <span>{{ member.fullname }}</span>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="member.relationType" >
          <label style="margin-right: 15px">{{"family-member.relation-type.label" | translate}}</label>
          <span>{{member.relationType}}</span>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="member.birthdate" >
          <label style="margin-right: 15px">{{"family.list.header.year-old.label" | translate}}</label>
          <span>{{getYear(member)}}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="member.status" >
          <label style="margin-right: 15px">{{"family-member.status.label" | translate}}</label>
          <span>{{member.status}}</span>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-6" *ngIf="member.group" >
          <span>
            <mat-chip-list aria-label="Fish selection">
              <mat-chip color="accent" selected>{{member.group.emailAddress}}</mat-chip>
            </mat-chip-list>
          </span>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>

    </mat-card-actions>
  </mat-card>
  <mat-card style="margin-top: 15px">
    <mat-card-header>
      <mat-card-title>{{"family-member-update.title.label" | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-family-member-update [member]="member" #familyMemberUpdateComponent></app-family-member-update>
    </mat-card-content>
  </mat-card>
  <mat-card style="margin-top: 15px">
    <mat-card-header>
      <mat-card-title>{{"family-member-picture.title.label" | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-family-member-picture [member]="member" #familyMemberPictureComponent></app-family-member-picture>
    </mat-card-content>
  </mat-card>
</div>
