import { Injectable } from '@angular/core';
import {HttpService} from '../http-service/http.service';
import {MemberShip} from '../../model/register/MemberShip';
import {UserAppService} from '../user-service/user-app.service';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../notification-service/notification.service';
import {Country} from '../../model/country/Country';
import {City} from '../../model/country/City';
import {DataPage, SearchCriteria} from 'app/model/data-page';
import {GodFather} from '../../model/register/GodFather';
import {PotentialUser} from '../../model/security/potential-user';
import {DownloadFile} from '../../model/file/downloadFile';
import {ClassNameMap} from '../../model/persistent/persistent';
import {Observable} from 'rxjs';
import {Organisation} from '../../model/organisation/Organisation';
import {User} from '../../model/security/user';
import {GlobalVariables} from '../global-variables';
import {Contact} from '../../model/organisation/contact.model';
import {Member} from '../../model/organisation/Member';
import {Beneficiary} from "../../model/register/Beneficiary";

@Injectable({
  providedIn: 'root'
})
export class RegisterService extends HttpService<any>{

  constructor(public userService: UserAppService ,
              public http: HttpClient, public notificationService: NotificationService) {
    super(userService, http, notificationService);
  }

  getCountry(value?): Promise<Country[]> {
     const criteria: SearchCriteria = new SearchCriteria(value);
     return new Promise<Country[]>((resolve, reject) => {
        this.search(criteria, 'location/country').subscribe((res) => {
          resolve(res.datas);
        }, err => {
          reject(err);
        });
     });
  }

  getCity(value?, countryId?): Promise<City[]> {
     const criteria: SearchCriteria = new SearchCriteria(value);
     if (countryId) {
       criteria.countryId = countryId;
     }
     return new Promise<City[]>((resolve, reject) => {
        this.search(criteria, 'location/city').subscribe((res) => {
          resolve(res.datas);
        }, err => {
          reject(err);
        });
     });
  }

  getDocument(): Observable<DataPage<DownloadFile>> {
    return new Observable<DataPage<DownloadFile>>(ob => {
      const documentList: DataPage<DownloadFile> = {
        datas : [
          {
            name: 'documents.condition.name',
            type: 'pdf',
            value: 'NR_Conditions_Generales',
            description: 'documents.condition.description',
            imageBackground: 'assets/images/documents/document.jpg',
            classe: ClassNameMap.DownloadFile
          },
          {
            name: 'documents.contract.name',
            type: 'pdf',
            value: 'R.O.I.NR',
            description: 'documents.contract.description',
            imageBackground: 'assets/images/documents/document.jpg',
            classe: ClassNameMap.DownloadFile
          },

          {
            name: 'documents.private.name',
            type: 'pdf',
            value: 'NR_Declaration_relative_a_la_vie_priveee',
            description: 'documents.private.description',
            imageBackground: 'assets/images/documents/document.jpg',
            classe: ClassNameMap.DownloadFile
          },
          {
            name: 'documents.faq.name',
            type: 'pdf',
            value: 'NR_Faq',
            description: 'documents.faq.description',
            imageBackground: 'assets/images/documents/document.jpg',
            classe: ClassNameMap.DownloadFile
          },
          {
            name: 'documents.formulaire.association.name',
            type: 'pdf',
            value: 'NR_Formulaire_adheesion_Association',
            description: 'documents.formulaire.association.description',
            imageBackground: 'assets/images/documents/document.jpg',
            classe: ClassNameMap.DownloadFile
          },
          {
            name: 'documents.formulaire.member.name',
            type: 'pdf',
            value: 'NR_Formulaire_adhesion_Membre',
            description: 'documents.formulaire.member.description',
            imageBackground: 'assets/images/documents/document.jpg',
            classe: ClassNameMap.DownloadFile
          },
          {
            name: 'documents.general.protection.name',
            type: 'pdf',
            value: 'NR_Reglement_General_Protection_Donnees',
            description: 'documents.general.protection.description',
            imageBackground: 'assets/images/documents/document.jpg',
            classe: ClassNameMap.DownloadFile
          },
        ],
        page: 1,
        pageSize: 1,
        totalItemCount: 2
      };
      ob.next(documentList);
    });
  }

  getGodFather(value?): Promise<GodFather[]> {
    const criteria: SearchCriteria = new SearchCriteria(value);
    return new Promise<GodFather[]>((resolve, reject) => {
      /*this.search(criteria, 'organisation/god-father').subscribe((res) => {
        resolve(res.datas);
      }, err => {
        reject(err);
      });*/
      const godFather = new GodFather();
      godFather.name = 'KAPTUE W.';
      godFather.surname = 'REGISS';

      const godFather2 = new GodFather();
      godFather2.name = 'Wepiwé';
      godFather2.surname = 'Giscar';

      const godFather3 = new GodFather();
      godFather3.name = 'NGAMENI';
      godFather3.surname = 'Bertrand';

      const godFather4 = new GodFather();
      godFather4.name = 'NGUEMELEU';
      godFather4.surname = 'Cyriaque';

      const godFather5 = new GodFather();
      godFather5.name = 'TCHOUAMENI';
      godFather5.surname = 'Honorine';
      const godFathers: GodFather[] = [].concat([godFather3, godFather, godFather2, godFather4, godFather5]);
      resolve(godFathers);
    });
  }

  sendPotentialUser(user: PotentialUser) {
    return  new Promise<string>((resolve, reject) => {
      this.save(user, 'organisation/potential-member').subscribe((ob: string) => {
        resolve(ob);
      }, error => {
        reject(error);
      });
    });
  }

  saveMemberShip(entity: MemberShip): Promise<MemberShip> {
    return  new Promise<MemberShip>((resolve, reject) => {
      this.save(entity, 'organisation/member-ship').subscribe((ob: MemberShip) => {
        resolve(ob);
      }, error => {
        reject(error);
      });
    });
  }

  updateMemberShip(entity: MemberShip): Promise<MemberShip> {
    return  new Promise<MemberShip>((resolve, reject) => {
      this.save(entity, 'organisation/member-ship-update').subscribe((ob: MemberShip) => {
        resolve(ob);
      }, error => {
        reject(error);
      });
    });
  }

  saveMember(entity: Member): Promise<Member> {
    return  new Promise<Member>((resolve, reject) => {
      this.save(entity, 'organisation/member').subscribe((ob: Member) => {
        resolve(ob);
      }, error => {
        reject(error);
      });
    });
  }

  saveBeneficiary(entity: Beneficiary): Promise<Beneficiary> {
    return  new Promise<Beneficiary>((resolve, reject) => {
      this.save(entity, 'organisation/beneficiary').subscribe((ob: Beneficiary) => {
        resolve(ob);
      }, error => {
        reject(error);
      });
    });
  }

  saveContactForm(entity: Contact): Promise<Contact> {
    return new Promise<Contact>((resolve, reject) => {
      this.save(entity, 'organisation/contact-form/send').subscribe((ob: Contact) => {
        resolve(ob);
      }, error => {
        reject('contact-form.save.without.email.empty.error');
      });
    });
  }

  saveOrganisation(entity: Organisation): Promise<Organisation> {
    return new Promise<Organisation>((resolve, reject) => {
      this.save(entity, 'organisation/partner').subscribe((ob: Organisation) => {
        resolve(ob);
      }, error => {
        reject('organisation.save.without.email.empty.error');
      });
    });
  }

  findOrganisationByLogin(login: string): Promise<Organisation> {
    return new Promise<Organisation>((resolve, reject) => {
      this.http.get<any>(
        `${GlobalVariables.CONTEXT_PATH}/organisation/find-by-login/${login}`,
      ).subscribe((organisation: Organisation) => {
        resolve(organisation);
      }, (error => {
        reject(error);
      }));
    });
  }

  findMemberShipByLogin(login: string): Promise<MemberShip> {
    const cri: SearchCriteria = new SearchCriteria(login);
    return new Promise<MemberShip>((resolve, reject) => {
      this.http.post<any>(
        `${GlobalVariables.CONTEXT_PATH}/organisation/member-ship/find-by-login`,
        cri,
        this.getHttpOptions()
      ).subscribe((memberShip: MemberShip) => {
        resolve(memberShip);
      }, (error => {
        reject(error);
      }));
    });
  }

  findUserByLogin(login: string): Promise<User> {
    const cri: SearchCriteria = new SearchCriteria(login);
    return new Promise<User>((resolve, reject) => {
      this.http.post<any>(
        `${GlobalVariables.CONTEXT_PATH}/organisation/user/find-by-login`,
        cri,
        this.getHttpOptions()
      ).subscribe((user: User) => {
        resolve(user);
      }, (error => {
        reject(error);
      }));
    });
  }

  findBeneficiaryByMemberShipId(id: string): Promise<Beneficiary> {
    const cri: SearchCriteria = new SearchCriteria(id);
    return new Promise<Beneficiary>((resolve, reject) => {
      this.http.post<any>(
        `${GlobalVariables.CONTEXT_PATH}/organisation/beneficiary/find-by-member-ship`,
        cri,
        this.getHttpOptions()
      ).subscribe((beneficiary: Beneficiary) => {
        resolve(beneficiary);
      }, (error => {
        reject(error);
      }));
    });
  }
}
