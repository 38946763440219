import { Action } from '@ngrx/store';
import {User} from '../../../model/security/user';
import {AccessToken} from '../../../model/security/access-token';
import {ConfirmUser} from '../../../model/security/confirm-user';

export enum AuthenticationActionTypes {
  LOGIN = '[Authentication] Login',
  REFRESH_BEGIN = '[Authentication] Refresh Begin',
  REFRESH_LOGIN = '[Authentication] Refresh Login',
  LOGIN_SUCCESS = '[Authentication] Login Success',
  LOGIN_FAILURE = '[Authentication] Login Failure',
  LOGOUT = '[Authentication] Logout',
  LOGOUT_FAILURE = '[Authentication] Logout Failure',
  LOGOUT_SUCCESS = '[Authentication] Logout Success',
  EMAIL_CONFIRM = '[Authentication] Login Confirm',
}

export class Login implements Action {
  readonly type = AuthenticationActionTypes.LOGIN;
  constructor(public payload: User) {}
}

export class RefreshLogin implements Action {
  readonly type = AuthenticationActionTypes.REFRESH_LOGIN;
  constructor(public payload: string) {}
}

export class RefreshBegin implements Action {
  readonly type = AuthenticationActionTypes.REFRESH_BEGIN;
  constructor(public payload: string) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthenticationActionTypes.LOGIN_SUCCESS;
  constructor(public payload: AccessToken) {}
}

export class LoginFailure implements Action {
  readonly type = AuthenticationActionTypes.LOGIN_FAILURE;
  constructor(public payload: Error | string) {}
}

export class Logout implements Action {
  readonly type = AuthenticationActionTypes.LOGOUT;
  constructor(public payload: string) {}
}

export class LogoutFailure implements Action {
  readonly type = AuthenticationActionTypes.LOGOUT_FAILURE;
  constructor(public payload: Error | string | null ) {}
}

export class LogoutSuccess implements Action {
  readonly type = AuthenticationActionTypes.LOGOUT_SUCCESS;
}

export class EmailConfirm implements Action {
  readonly type = AuthenticationActionTypes.EMAIL_CONFIRM;
  constructor(public payload: ConfirmUser ) {}
}

export type AuthenticationActions =
  | Login
  | RefreshLogin
  | RefreshBegin
  | LoginSuccess
  | LoginFailure
  | Logout
  | LogoutFailure
  | EmailConfirm
  | LogoutSuccess;
