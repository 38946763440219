<div>
  <div class="app">
    <ng-container>
      <div *ngIf="fileUpload.status==='progress'">
        <mat-progress-bar mode="determinate" value="{{fileUpload.message}}"></mat-progress-bar>
      </div>
      <form [formGroup]="headerForm" name='document-form-header'>
        <div class="row">
          <input #inputFile (change)="onSelectFile($event)" hidden type="file">
          <mat-form-field class="col-xs-10 col-sm-4 col-md-4" floatLabel="never">
            <input
              name="recordFile"
              matInput
              placeholder="{{'document.upload' | translate}}"
              readonly
              [formControl]="headerForm.get('recordFile')">
            <mat-error *ngIf="headerForm.get('recordFile').errors">{{headerForm.controls.recordFile.errors?.validateFile | translate}}</mat-error>
          </mat-form-field>
          <button color="accent" class="col-xs-2 col-sm-4 col-md-4" style="height: 42px; font-size: xx-small"
                  mat-raised-button matTooltip="{{'document.upload.tooltip' | translate}}"
                  (click)="inputFile.click()">
            {{"document.upload.label" | translate}}
          </button>
        </div>
      </form>
    </ng-container>
  </div>
  <div *ngIf="modalData && modalData.data" mat-dialog-actions class="d-flex justify-content-center">
    <app-buttons-bar *ngIf="buttons"
                     [actions]="buttons"
                     [functionality]="'record-doc.edit'"
                     (action)="btnActionClick($event)">
    </app-buttons-bar>
  </div>
</div>
