<section class="web_homePage">
  <msCarousel width="100%" class="home-page-carousel">
    <msCarouselItem
      *ngFor="let slide of slides; let i = index">

      <div class="banner" style="height: 100%">
        <div class="banner-body" style="flex-direction: column">
          <div class="carousel-title">{{ slide.title | translate }}</div>
          <div class="carousel-text py-3">{{ slide.description | translate }}</div>

          <div class="text-center">
            <button *ngFor="let btn of slide.buttons" mat-raised-button color="primary" class="m-2"
                    (click)="quickBtnClicked(btn)">
              {{ btn.label | translate}}
            </button>
          </div>
        </div>

        <img src="{{slide.image}}" class="banner-bg-img">
        <div class="banner-glass"></div>
      </div>

    </msCarouselItem>
  </msCarousel>

  <div class="row about-align-items about-us-zone page-body">
    <div class="col-xs-12 col-sm-8 col-md-4">
      <img class="mx-auto apropos-img" src="assets/images/new-images/home-1.jpg">
    </div>
    <div class="row col-xs-12 col-sm-8 col-md-7 about-container">
      <h1 class="section-title mt-2">{{"home.about-us.title.label"| translate}}</h1>

      <div class="paragraph">

        <p> {{"home.about-us.description-1.label"| translate}} </p>
        <p> {{"home.about-us.description-2.label"| translate}} </p>
        <p> {{"home.about-us.description-3.label"| translate}} </p>

        <button class="zoom" mat-raised-button color="primary"
                (click)="aboutUs()">
          <span>{{"home.btn.about-us.label" | translate}}</span>
        </button>

      </div>
    </div>

  </div>


  <div class="video-zone">
    <!-- <video width="800" height="500" controls disabled="true" #videoPlayer>
        <source src="assets/videos/test.MP4" type="video/mp4">
    </video> -->

    <iframe width="800" height="500"
            src="https://www.youtube.com/embed/KytkiVJCezk?autoplay=1&mute=0">
    </iframe>

  </div>

  <div class="last-zone">
    <div class="our-services-zone">
      <div class="border-primary text-center" style="margin: 30px;">
        <label class="services-title">{{"home.our-services.title.label"| translate}}</label>
      </div>

      <div class="d-flex flex-wrap" style="flex-wrap: wrap;">

        <div class="service-item m-2" *ngFor="let service of services">
          <div class="service-title">{{service.title| translate}}</div>
          <div class="service-text">{{service.content | translate}}</div>
        </div>


      </div>

    </div>
  </div>
  <br><br>

  <app-footer-bar></app-footer-bar>
</section>

<file-downloader hidden #downloader class="hide"
                 [open-in-tab]="openFileInTab"
                 file-type="application/pdf"
                 succedCodeMessage="document.download.succeed"
                 failCodeMessage="document.download.fail"
></file-downloader>

