<div  class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'member.edit.header.title'">
        <button
            mat-raised-button
            routerLink="/member/list"
            color="primary"
            aria-label="button with a add one icon">
            <mat-icon>list</mat-icon>
            <mat-label>{{"member.list.header.title" | translate}}</mat-label>
          </button>
      </app-header>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card col-xs-12  col-sm-12">
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content >
      <form [formGroup]="memberForm" name='member-edit-form'>
        <div class="row">
          <mat-form-field class="col-xs-12 col-sm-6 col-md-4" >
            <input type="text" placeholder='{{"member.name.label" | translate}}'
            [formControl]="memberForm.get('name')" name='name'
             matInput maxlength="255" [value]="currentObject.name" >
            <mat-error *ngIf="memberForm.get('name').errors">{{ "member.name.empty.error" | translate}}</mat-error>
          </mat-form-field>
          <mat-form-field class="col-xs-12 col-sm-6 col-md-4" >
            <input type="text" placeholder='{{"member.surname.label" | translate}}'
            [formControl]="memberForm.get('surname')" name='surname'
             matInput maxlength="255" [value]="currentObject.name" >
            <mat-error *ngIf="memberForm.get('surname').errors">{{ "member.surname.empty.error" | translate}}</mat-error>
        </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <app-buttons-bar *ngIf="buttons"
        [functionality]="'third-party-payer.edit'"
        [actions]="buttons"
        (action)="btnActionClick($event)">
        </app-buttons-bar>
    </mat-card-actions>
  </mat-card>
</div>
