import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProgressBarService} from '../../../services/progress-bar/progress-bar.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-progress-bar-noble',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {

  bufferValue: number;
  mode: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  value: number;
  visible: boolean;

  private unsubscribeAll: Subject<any>;

  constructor(
    private nobleProgressBarService: ProgressBarService
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this.nobleProgressBarService.bufferValue()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((bufferValue) => {
        this.bufferValue = bufferValue;
      });

    this.nobleProgressBarService.mode()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((mode) => {
        this.mode = mode;
      });

    this.nobleProgressBarService.value()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((value) => {
        this.value = value;
      });

    this.nobleProgressBarService.visible()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((visible) => {
        this.visible = visible;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

}
