import {Component, OnDestroy, OnInit} from '@angular/core';
import * as Chartist from 'chartist';
import {ConfigService} from '@noble/services/config.service';
import {DashboardService} from '../../services/dashbord/dashboard.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Count} from "../../model/register/MemberShip";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  memberCount: Count;
  activeMemberCount: Count;
  inactiveMemberCount: Count;
  onProbationMemberCount: Count;
  deceaseMemberCount: Count;
  memberAndFamilyCount: Count;

  private _unsubscribeAll: Subject<any>;

  constructor(private _configService: ConfigService, private _dashboardService: DashboardService) {
    // Configure the layout
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };

    this._unsubscribeAll = new Subject();
  }

  startAnimationForLineChart(chart){
    // tslint:disable-next-line:one-variable-per-declaration
    const  delays = 80, durations = 500;
    let seq = 0;

    chart.on('draw', (data) => {
        if (data.type === 'line' || data.type === 'area') {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint
            }
          });
        } else if (data.type === 'point') {
              seq++;
              data.element.animate({
                opacity: {
                  begin: seq * delays,
                  dur: durations,
                  from: 0,
                  to: 1,
                  easing: 'ease'
                }
              });
          }
      });

    seq = 0;
  }
  startAnimationForBarChart(chart){
    // tslint:disable-next-line:one-variable-per-declaration
      let seq2: any, delays2: any, durations2: any;

      seq2 = 0;
      delays2 = 80;
      durations2 = 500;
      chart.on('draw', (data) => {
        if (data.type === 'bar'){
            seq2++;
            data.element.animate({
              opacity: {
                begin: seq2 * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            });
        }
      });

      seq2 = 0;
  }


  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {


          /* ----------==========     Adherents Registration Chart initialization    ==========---------- */

    const datawebsiteViewsChart = {
            labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
            series: [
              [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]

            ]
          };
    const optionswebsiteViewsChart = {
              axisX: {
                  showGrid: false
              },
              low: 0,
              high: 1000,
              chartPadding: { top: 0, right: 5, bottom: 0, left: 0}
          };
    const responsiveOptions: any[] = [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }]
          ];
    const websiteViewsChart = new Chartist.Bar('#websiteViewsChart', datawebsiteViewsChart, optionswebsiteViewsChart, responsiveOptions);

          // start animation for the Adherents Registration Chart
    this.startAnimationForBarChart(websiteViewsChart);



      /* ----------==========     Active Adherents Chart initialization For Documentation    ==========---------- */

    const dataDailySalesChart: any = {
          labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
          series: [
              [12, 17, 7, 17, 23, 18, 38]
          ]
      };

    const optionsDailySalesChart: any = {
          lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: { top: 0, right: 0, bottom: 0, left: 0},
      };

    const dailySalesChart = new Chartist.Line('#dailySalesChart', dataDailySalesChart, optionsDailySalesChart);

      // start animation for the Active Adherents Chart - Line Chart
    this.startAnimationForLineChart(dailySalesChart);



      /* ----------==========     Deceased Adherents Chart initialization    ==========---------- */

    const dataCompletedTasksChart: any = {
          labels: ['12p', '3p', '6p', '9p', '12p', '3a', '6a', '9a'],
          series: [
              [230, 750, 450, 300, 280, 240, 200, 190]
          ]
      };

    const optionsCompletedTasksChart: any = {
          lineSmooth: Chartist.Interpolation.cardinal({
              tension: 0
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: { top: 0, right: 0, bottom: 0, left: 0}
      };

    const completedTasksChart = new Chartist.Line('#completedTasksChart', dataCompletedTasksChart, optionsCompletedTasksChart);

      // start animation for the Deceased Adherents Chart - Line Chart
    this.startAnimationForLineChart(completedTasksChart);

    this._dashboardService.memberCountOnChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(count => {
        this.memberCount = count;
        console.log(this.memberCount);
      });
    this._dashboardService.activeMemberCountOnChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(count => {
        this.activeMemberCount = count;
      });

    this._dashboardService.inactiveMemberCountOnChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(count => {
        this.inactiveMemberCount = count;
      });

    this._dashboardService.onProbationMemberCountOnChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(count => {
        this.onProbationMemberCount = count;
      });

    this._dashboardService.deceaseMemberCountOnChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(count => {
        this.deceaseMemberCount = count;
      });

    this._dashboardService.memberAndFamilyCountOnChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(count => {
        this.memberAndFamilyCount = count;
      });

  }

}
