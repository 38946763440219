import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {RecordDoc} from '../../../../../model/document/record-doc';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors} from '@angular/forms';
import {FileUploadService} from '../../../../../services/document/file.upload.service';
import {tap} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {IModalComponent} from '../../../../base/modal/modal.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormAction, IFormActionBarButton} from '../../../../base/buttons-bar/buttons-bar.component';
let self;

@Component({
  selector: 'app-record-document',
  templateUrl: './record-document.component.html',
  styleUrls: ['./record-document.component.scss'],
})
export class RecordDocumentComponent extends IModalComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * Garde les informations du fichier uploader
   */
  static file: any;
  static extensions = ['video/mp4', 'text/javascript', 'application/x-msdownload', 'audio/mp3', 'application/zip'];
  static goodExtensions = ['application/pdf'];

  /**
   * Contient les informations du fichier renvoyer par le serveur
   */
  fileInformation: any;

  // tslint:disable-next-line:variable-name
  _currentObject: RecordDoc;

  externalId: string;

  public buttons: IFormActionBarButton[];

  fileUpload = { status: '', message: '', filePath: '', name: '', externalId: '' };

  error: string;

  @Input()
  set modal(value) {
    this._modal = value;
  }
  @Input() _modal = true;

  public modalData: any;
  @Input() path: string = environment.apiHost +
    environment.uploadFile;

  get currentObject(){
    return this._currentObject;
  }
  set currentObject(object: RecordDoc) {
    this._currentObject = object;
  }
  headerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    public injector: Injector
    ) {
    super();
    self = this;
    this._currentObject = new RecordDoc();

    this.buttons = [
      {id: 'save', value: FormAction.UPDATE, text: 'btn.update.label', cssClass: 'btn-update' , disabled: false,
        icon: {type : 'save', color: '#ff9933'}},
    ];
  }

  /**
   * Use to validate a size file and type.
   */
  static validateFile(control: AbstractControl): ValidationErrors | null {
    if (RecordDocumentComponent.file) {
      if (RecordDocumentComponent.file.size && RecordDocumentComponent.file.size > 5242880) {
        return { validateFile : 'document.upload.file.size.error'};
      } else if (!RecordDocumentComponent.goodExtensions
        .includes(RecordDocumentComponent.file.type || RecordDocumentComponent.file.type === '')) {
        return { validateFile : 'document.upload.type.error.record'};
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    if (this.modalData && this.modalData.data) {
      this.externalId = this.modalData.data.externalId;
      this.setObjectInView(this.modalData.data);
    }
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id ){
      if (this.isValid()) {
        this.update().then(res => {
          // this.dialogRef.close(res);
        }).catch(err => {
          // this.dialogRef.close(err);
        });
      }
    }
  }

  ngOnInit(): void {
    if (this._modal === true) {
      this.modalData = this.injector.get(MAT_DIALOG_DATA);
    }

    this.initForm();

    this.fileUploadService.onPhotoUploaded.pipe(
      tap(res => {
        return res;
      })
    ).subscribe(res => {
      this._currentObject.name = res.record_name;
      this._currentObject.externalId = res.externalId;
    });
  }

  setObjectInView(recordDoc: RecordDoc) {
    this.currentObject = recordDoc;
    this.headerForm.get('recordFile').setValue(recordDoc.name);
  }

  getData(): {name: string, externalId: string} {
    this.onSubmit();
    return {name: this._currentObject.name, externalId: this._currentObject.externalId};
  }

  initForm() {
    this.headerForm = this.fb.group({
      recordFile: [null, [RecordDocumentComponent.validateFile]],
    });
  }

  isValid() {
    let isValid = true;
    if (this.headerForm !== undefined && this.headerForm.invalid === true) {
      isValid = false;
    }
    return isValid;
  }

  onSubmit(): Promise<{name: string, externalId: string}>{
    if (this.currentObject.id !== null && this.currentObject.id !== undefined) {
      if (RecordDocumentComponent.file === null || RecordDocumentComponent.file === undefined) {
        return new Promise<{name: string; externalId: string}>((resolve, reject) => {
          resolve({name: this.currentObject.name, externalId: this.currentObject.externalId});
        });
      } else if (this.headerForm.valid) {
        this.externalId = this.currentObject.externalId;
        return this.update();
      }
    } else {
      return new Promise<{name: string, externalId: string}>((resolve, reject) => {
        this.fileUploadService
          .uploadItem(RecordDocumentComponent.file, this.path)
          .subscribe(
            res => {
              if (res.status === 'progress') {
                this.fileUpload = res;
              } else if (res.status === 'done') {
                this.fileUpload = res;
                this.fileUploadService.onPhotoUploaded.next(
                  this.fileUpload
                );
                // this.show = !this.show;
                resolve({name: res.record_name, externalId: res.externalId});
              }
            },
            err => {
              this.error = err;
              reject(err);
            }
          );
      });
    }
  }

  update(): Promise<{name: string, externalId: string}>{
    return new Promise<{name: string, externalId: string}>((resolve, reject) => {
      this.fileUploadService
        .updateItem(RecordDocumentComponent.file, this.path, this.externalId)
        .subscribe(
          res => {
            if (res.status === 'progress') {
              this.fileUpload = res;
            } else if (res.status === 'done') {
              this.fileUpload = res;
              this.fileUploadService.onPhotoUploaded.next(
                this.fileUpload
              );
              // this.show = !this.show;
              resolve({name: res.record_name, externalId: res.externalId});
            }
          },
          err => {
            this.error = err;
            reject(err);
          }
        );
    });
  }

  onSelectFile(event: any) {
    if (event.target.files &&
      event.target.files.length > 0) {
      RecordDocumentComponent.file = event.target.files[0];
      this.headerForm.get('recordFile').setValue(RecordDocumentComponent.file.name);
      this.fileInformation = null;
    }
  }
}
