import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {NOBLE_CONFIG} from './services/config.service';

@NgModule()
export class NobleModule {
  constructor(@Optional() @SkipSelf() parentModule: NobleModule) {
    if ( parentModule )
    {
      throw new Error('NobleModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(config): ModuleWithProviders<any>
  {
    return {
      ngModule : NobleModule,
      providers: [
        {
          provide : NOBLE_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
