import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl, Validators, Validator, ValidatorFn } from '@angular/forms';
import { SearchCriteria } from 'app/model/data-page';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {HttpService} from '../../../services/http-service/http.service';
import {MatOptionSelectionChange} from '@angular/material/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit, OnChanges {

  // tslint:disable-next-line:no-output-native
  @Output()
  public change: EventEmitter<any> = new EventEmitter();

  @Output()
  public new: EventEmitter<any> = new EventEmitter();

  @Input()
  public options: any[] = [];

  @Input()
  public value: any = null;

  @Input()
  public displayValue: (...params) => void;

  @Input()
  public maxlength = 255;

  @Input()
  public placeholder = '';

  @Input()
  public dataSourceFn: (...params) => void;

  @Input()
  public addNewFn: (...params) => void;

  // tslint:disable-next-line:no-input-rename
  @Input('label')
  public labelField = '';

  // tslint:disable-next-line:no-input-rename
  @Input('search')
  public searchParams: {criteria?: SearchCriteria | any, url: string, resultField?: string} = {url: null};
  // tslint:disable-next-line:no-input-rename
  @Input('search-criteria')
  public criteria: SearchCriteria | any = {};

  @ViewChild('autocompleteInput')  autocompleteInput: ElementRef;

  public optionControl: FormControl = new FormControl();

  public elements: Observable<any[]>;

  // tslint:disable-next-line:no-input-rename
  @Input('required')
  public required = false;

  constructor(private httpService: HttpService<any>) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options || changes.searchParams){
      if (this.searchParams != null){
        this.criteria = this.searchParams.criteria;
      }
      this.setElements();
    }
    if (changes.value) {
      this.optionControl.setValue(this.value);
    }
  }


  protected setElements() {
    this.optionControl.valueChanges.subscribe( (value) => {
      if (!value || typeof value === 'string') {
        this.elements = this.filterElements(value);
      } else {
        this.value = value;
        this.change.emit(value);
      }
    });
  }

  protected filterElements(value?: string): Observable<any[]> {
    return new Observable( (observer) => {
      if (this.options && this.options.length > 0) {
        const result = this.options.filter( (opt) => {
          const str = '' + this.displayValueCall(opt);
          return str.toLowerCase().indexOf(( '' + value).toLowerCase()) !== -1;
        });
        observer.next(result);
        observer.complete();
      } else if (this.searchParams && this.searchParams.url) {
        this.httpService.post(Object.assign(this.criteria || {}, { value }), this.searchParams.url ).subscribe( (results) => {
          const result = this.searchParams.resultField ? results[this.searchParams.resultField] : results;
          observer.next(result);
          observer.complete();
        });
      } else if ( this.dataSourceFn) {
        this.dataSourceFn(observer);
      }
    });
  }

  protected buildValidators(): ValidatorFn[] {
    const validators = [];
    if (this.required){
      validators.push(Validators.required);
    }
    if (this.maxlength != null){
      validators.push(Validators.maxLength(this.maxlength));
    }
    return validators.length > 0 ? validators : undefined;
  }

  public addNew(event) {
    if (this.addNewFn) {
      const newValue = this.addNewFn(this.optionControl.value);
      this.elements = new Observable( (observer) => {
        this.elements.pipe(map(list => {
          list.push(newValue);
          observer.next(list);
          observer.complete();
        }));
      });
    }
  }

  public displayValueCall(value) {
    return value ? (this.labelField ? value[this.labelField] : (this.displayValue ? this.displayValue(value) : value)) : '';
  }

  removeOption(option: any) {
    this.elements = new Observable<any[]>(observer => {
      if (this.options && this.options.length > 0) {
        const result = this.options.filter((opt) => opt === option);
        observer.next(result);
        observer.complete();
      }
    });

    this.focusOnPlaceInput();
  }

  focusOnPlaceInput() {
    this.autocompleteInput.nativeElement.focus();
    this.autocompleteInput.nativeElement.value = '';
  }

  filterOption($event) {

  }
}
