import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {StripeCardComponent, StripeService} from 'ngx-stripe';
import {PaymentIntent, StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PaymentService} from '../../../services/payment/payment.service';
import {switchMap} from 'rxjs/operators';
import {Basket} from '../../../model/payment/Basket';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {Payment} from '../../../model/payment/Payment';
import {User} from '../../../model/security/user';
import {RegisterService} from '../../../services/register/register.service';
import {SpinnerOverlayService} from '../../../services/spinner-overlay/spinner-overlay.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {LoginState, selectAuthenticationState} from '../../../store/login/login.state';
import {State} from '../../../store/login/reducers/authentication.reducer';
import {Router} from '@angular/router';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent implements OnInit , OnDestroy{

  @Input() depositAmount;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  userEmail: string;

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: this.translate.currentLang === 'fr' ? 'fr' : 'en'
  };

  stripeForm: FormGroup;


  constructor(private fb: FormBuilder,
              private stripeService: StripeService,
              private msgService: NotificationService,
              private registerService: RegisterService,
              protected router: Router,
              private translate: TranslateService,
              private spinnerOverlayService: SpinnerOverlayService,
              private store: Store<LoginState>,
              private paymentService: PaymentService) {
    this.authenticationState$ = this.store.select(selectAuthenticationState);
  }

  ngOnDestroy() {
    this.authenticationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.userEmail = state.user.user.login;
      }
    });
    this.stripeForm = this.fb.group({
      cardName: ['', [Validators.required]],
      // cardEmail: ['', [Validators.required]],
    });
  }

  createToken(): void {
    if (this.depositAmount === undefined) {
      this.translate.get('stripe.amount.not.define').subscribe(ch => {
        this.msgService.log(ch, NotificationType.ERROR);
      });
      return;
    }

    if (this.stripeForm.invalid || !this.card.element) {
      this.translate.get('stripe.empty.value').subscribe(ch => {
        this.msgService.log(ch, NotificationType.ERROR);
      });
      return;
    }

    if (this.stripeForm.valid) {
      this.spinnerOverlayService.show();

      const numberAmount: number = Number(`${this.depositAmount}`);

      const basket = new Basket(
        {
          amount: `${numberAmount}`,
          name: this.stripeForm.get('cardName').value,
          email: this.userEmail
        }
      );

      this.paymentService.createPaymentIntent( basket ).pipe(
        switchMap((pi) => {
          return this.stripeService.confirmCardPayment(pi.clientSecret, {
            payment_method: {
              card: this.card.element,
              billing_details: {
                name: this.stripeForm.get('cardName').value,
                email: this.userEmail
              },
            },
          });
        })
      ).subscribe((result) => {
        if (result.error) {
          this.spinnerOverlayService.hide();
          this.msgService.log(result.error.message, NotificationType.ERROR);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            this.translate.get('stripe.payment.status.good').subscribe(ch => {
              this.msgService.log(ch, NotificationType.SUCCESS);
            });
            this.doAfterPaymentSucceeded(this.userEmail, result.paymentIntent)
              .then((res) => {
                this.spinnerOverlayService.hide();
                this.translate.get('stripe.payment.valid').subscribe(ch => {
                  this.msgService.log(ch, NotificationType.SUCCESS);
                });
                this.router.navigate(['/documents/list']);
              }).catch((err: string) => {
              this.spinnerOverlayService.hide();
              this.msgService.log(err, NotificationType.ERROR);
            });
          }
        }
      }, (err) => {
        this.spinnerOverlayService.hide();
        this.msgService.log(err, NotificationType.ERROR);
      });
    }
  }

  doAfterPaymentSucceeded(userEmail: string, paymentIntent: PaymentIntent): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.registerService.findUserByLogin(userEmail).then((user: User) => {
        let payment = new Payment();
        payment = {
          ...payment,
          user,
          amount: paymentIntent.amount,
          currency: 'EUR',
          paymentId: paymentIntent.id,
          created: paymentIntent.created,
          description: paymentIntent.description,
          paymentMethod: paymentIntent.payment_method,
          type: 'stripe',
          status: paymentIntent.status,
          receiptEmail: paymentIntent.receipt_email,
        };

        this.paymentService.saveSucceedPayment(payment).then(res => {
          resolve(res);
        }).catch(err => {
          this.translate.get('save.payment.error').subscribe(ch => {
            reject(ch);
          });
        });

      }).catch(err => {
        this.translate.get('find.user.email.error').subscribe(ch => {
          reject(ch);
        });
      });
    });
  }

}
