import { Persistent, ClassNameMap } from '../persistent/persistent';

export class User extends Persistent {

    public login?: string;

    public administrator?: string;

    public isOrganisation?: boolean;

    public lang?: string;

    public hashPassword?: string;

    public password?: string;

    public userName?: string;

    public userMail?: string;

    public userPhoneNumber?: string;

    public confirmPassword?: string;

    public number?: string;

    constructor(){
        super();
        this.classe = ClassNameMap.User;
    }
}
