<div class="row">
  <ng-container class="col-xs-12 col-sm-6 col-md-6">
    <app-profile-picture [(url)]="picurl" [editmode]="editEnabled" #profilePictureComponent></app-profile-picture>
  </ng-container>
  <ng-container class="col-xs-12 col-sm-6 col-md-6">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{"family-member-picture-settings.label" | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <section class="example-section">
          <mat-checkbox
            class="example-margin"
            #checkbox
            id="cbeditmode"
            [checked]="editEnabled"
            (change)="editEnabled=checkbox.checked">
            {{"family-member-picture-edit-mode.label" | translate}}
          </mat-checkbox>
        </section>
        <ng-container *ngIf="loading">
          <button mat-raised-button mat-button class="register-btn">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          </button>
        </ng-container>
        <ng-container *ngIf="useRemove">
          <button mat-raised-button mat-button
                  (click)="clear()" class="register-btn">
            <mat-icon>remove</mat-icon> {{ 'family-member-picture.clear.btn.label' | translate }}
          </button>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>
