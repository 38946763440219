<mat-card>
    <ng-container class="mat-card-header-zone">
        <mat-card-header>
            <mat-card-title> {{"news-management.editor.form.title"| translate}}</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
    </ng-container>
    
    <mat-card-content>
        <div>
            <label>{{ "news-management.title.label" | translate}}</label>
            <mat-form-field class="col-xs-3 col-sm-3 col-md-3" appearance="outline">
                <mat-label>{{"news-management.editor.title.label" | translate}}</mat-label>
                <input type="text" [formControl]="titleControl" matInput required rows="1">
                <mat-error *ngIf="titleControl.errors">{{"news-management.editor.title.empty.error" | translate}}</mat-error>
            </mat-form-field> 
        </div>

        <div>
            <label>{{ "news-management.description.label" | translate}}</label>
            <mat-form-field class="col-xs-7 col-sm-7 col-md-7 " appearance="outline">
                <mat-label>{{"news-management.editor.description.label" | translate}}</mat-label>
                <textarea  type="text" [formControl]="descriptionControl" rows="10"
                    matInput [(ngModel)]="news.description"></textarea>   
            </mat-form-field> 
        </div>
       
  </mat-card-content>
</mat-card>

<mat-card-footer>
    <app-buttons-bar *ngIf="buttons" 
        [actions]="buttons"
        (action)="btnActionClick($event)">
    </app-buttons-bar>
</mat-card-footer>