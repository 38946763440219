<div class="login-zone align-items">
  <div class="card row login-container">
      <h2 class="col-xs-12 col-sm-12 col-md-12 card-header align-items">
        {{"login.form.title.label"| translate}}
      </h2>
      <div class="card-body">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

              <mat-form-field class="col-xs-12 col-sm-12 col-md-12" appearance="outline">
                <mat-label>{{'login.form.userName.label' | translate}}</mat-label>
                <input matInput formControlName="username" >
                <mat-error *ngIf="submitted && loginForm.get('username').invalid">{{ "login.form.userName.required" | translate}}</mat-error>
              </mat-form-field><br>

              <mat-form-field class="col-xs-12 col-sm-12 col-md-12" appearance="outline">
                <mat-label>{{'login.form.password.label' | translate}}</mat-label>
                <input [type]="hide ? 'password' : 'text'"
                 matInput formControlName="password" >
                <mat-error *ngIf="submitted && loginForm.get('password').invalid">{{ "login.form.password.required" | translate}}</mat-error>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field><br><br>

              <button [disabled]="loading" class="btn btn-lg btn-block signIn-btn">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{"login.btn.label"| translate}}
              </button><br>

              <a routerLink="/register" class="btn btn-link align-items register-link">
                {{"register.btn.label"| translate}}
              </a><br>

              <a routerLink="/forgot-password" class="btn btn-link align-items register-link">
                {{"login.form.forgot.password"| translate}}
              </a>
          </form>
      </div>
  </div>
</div>
