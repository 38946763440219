import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SplashScreenService} from '../../../services/splash-screen/splash-screen.service';
import {ProgressBarService} from '../../../services/progress-bar/progress-bar.service';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EmailConfirm, LoginFailure, LoginSuccess} from '../../../store/login/actions/authentication.action';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {Store} from '@ngrx/store';
import {LoginState} from '../../../store/login/login.state';
import {GlobalVariables} from '../../../services/global-variables';
import {ConfirmUser} from '../../../model/security/confirm-user';
import {SpinnerOverlayService} from '../../../services/spinner-overlay/spinner-overlay.service';
import {ConfigService} from '@noble/services/config.service';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit, AfterViewInit {

  constructor(private nobleProgressBarService: ProgressBarService,
              private route: ActivatedRoute,
              private spinnerOverlayService: SpinnerOverlayService,
              private store: Store<LoginState>,
              private msgService: NotificationService,
              private router: Router,
              private nobleSplashScreenService: SplashScreenService,
              private _configService: ConfigService) {}

  ngAfterViewInit() {
    // Configure the layout
    this._configService.config = {
      layout: {
        style   : 'app-navbar-out',
      }
    };
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const data: {id?: string, token?: string} = (params as any).params;
      if (data.id !== undefined && data.token !== undefined && data.id !== null && data.token !== null) {
        this.spinnerOverlayService.show();
        const confirmUser = new ConfirmUser(Number.parseInt(data.id, 10), data.token);
        this.store.dispatch(new EmailConfirm(confirmUser));
      } else {
        this.router.navigate(GlobalVariables.HOME_ROUTE);
      }
    });
  }

}
