<form class="exemple-form">
  <mat-form-field class="exemple-full-width" style="width: 100%">
    <mat-chip-list #chipList>
      <mat-chip style="margin-top: 0.4em;"
      *ngFor="let option of options" (removed)="removeOption(option)">
        <span>{{option.title}}</span>

        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input type="text" placeholder="{{placeholder | translate}}"  matInput
             (keydown.enter)="addNew($event)" (change)="addNew($event)"
             [formControl]="optionControl"
             [matAutocomplete]="autocomplete"
             #autocompleteInput
             [matChipInputFor]="chipList"
             [required]="required"
             [attr.maxlength]="maxlength">
      <mat-icon matPrefix></mat-icon>
      <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayValueCall">
        <mat-option (onSelectionChange)="filterOption($event)" *ngFor="let option of elements | async" [value]="option">
          {{displayValueCall(option)}}
        </mat-option>
      </mat-autocomplete>
    </mat-chip-list>
  </mat-form-field>
</form>
