import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Contact} from '../../../model/organisation/contact.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegisterService} from '../../../services/register/register.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {NobleValidators} from '../../base/validators/noble-validators';
import { ClassNameMap } from 'app/model/persistent/persistent';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterViewInit {

  @ViewChild('mapElement') mapElement: any;
  map: google.maps.Map;

  loading = false;

  contact: Contact = new Contact({});
  contactForm: FormGroup;
  checkTermsUse = false;

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private registerService: RegisterService,
    protected translateService: TranslateService,
    protected msgService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.contactForm = this.createContactForm();
  }

  ngAfterViewInit() {
    const mapProperties = {
      center: new google.maps.LatLng(50.426101, 4.474790),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement,    mapProperties);
  }

  aboutUs(){
    this.router.navigate(['home/about-us']);
  }

  register(){
    this.router.navigate(['register']);
  }
  home(){
    this.router.navigate(['home']);
  }
  /**
   * Create contact form
   */
  createContactForm(): FormGroup
  {
    return this._formBuilder.group({
      id      : [this.contact.id],
      name    : [this.contact.name, [Validators.required, NobleValidators.notEmpty]],
      lastName: [this.contact.lastName],
      avatar  : [this.contact.avatar],
      nickname: [this.contact.nickname],
      company : [this.contact.company],
      jobTitle: [this.contact.jobTitle],
      email   : [this.contact.email, [Validators.email, Validators.required, NobleValidators.notEmpty]],
      phone   : [this.contact.phone],
      address : [this.contact.address],
      birthday: [this.contact.birthday],
      notes   : [this.contact.notes, [Validators.required, NobleValidators.notEmpty]],
      classe  : ClassNameMap.Contact
    });
  }

  save() {
    if (this.contactForm.valid) {
      this.contact = Object.assign(this.contactForm.value);

      this.registerService.saveContactForm(this.contact).then((result: Contact) => {
        this.translateService.get('contact-us.form.send.success').subscribe(res => {
          this.msgService.log(res, NotificationType.SUCCESS);
          this.home();
        });
      }).catch(err => {
        console.log(err);
        this.translateService.get('contact-us.form.send.error.try.again').subscribe(res => {
          this.msgService.log(res, NotificationType.ERROR);
        });
      });
    }
  }

  cancel() {}

}
