import {ClassNameMap, Persistent} from '../persistent/persistent';

export class Contact extends Persistent{
  name?: string;
  lastName?: string;
  avatar?: string;
  nickname?: string;
  company?: string;
  jobTitle?: string;
  email?: string;
  phone?: string;
  address?: string;
  birthday?: string;
  notes?: string;

  /**
   * Constructor
   */
  constructor(contact) {
    super();

    {
      this.id = contact.id || null;
      this.name = contact.name || '';
      this.lastName = contact.lastName || '';
      this.avatar = contact.avatar || 'assets/images/avatars/profile.jpg';
      this.nickname = contact.nickname || '';
      this.company = contact.company || '';
      this.jobTitle = contact.jobTitle || '';
      this.email = contact.email || '';
      this.phone = contact.phone || '';
      this.address = contact.address || '';
      this.birthday = contact.birthday || '';
      this.notes = contact.notes || '';
      this.classe = ClassNameMap.Contact;
    }
  }
}
