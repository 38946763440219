import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonsBarComponent, FormAction, IFormActionBarButton } from '../../base/buttons-bar/buttons-bar.component';

@Component({
  selector: 'app-confirm-deposit',
  templateUrl: './confirm-deposit.component.html',
  styleUrls: ['./confirm-deposit.component.scss']
})
export class ConfirmDepositComponent implements OnInit {

  amount: number;
  feeTransaction: number;

  @ViewChild('btnBar')
  btnBarCmp: ButtonsBarComponent;
  public buttons: IFormActionBarButton[];

  constructor(private route: ActivatedRoute, private router: Router) {
    this.buttons = [{id: 'back', value: FormAction.BACK, icon: {type: 'skip_previous', color: '#ff9933'},
    text: 'btn.cancel.label', disabled: false, functionalities: []},
    {id: 'next', value: FormAction.NEXT, icon: {type: 'skip_next', color: '#ff9933'},
        text: 'btn.next.label', disabled: false, functionalities: []},
    ];
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe( params => {
      let amount = params.get('amount');
      if (amount) {
        this.amount = Number.parseInt(amount, 10);
      }
    });
  }

  getAmount(): string {
    return `${this.amount}`;
  }

  getFeesTransaction(): string {
    this.feeTransaction = 0;
    if (this.amount > 0) {this.feeTransaction = 6}
    return `${this.feeTransaction}`;
  }

  getTotalAmout() {
    const total = this.feeTransaction + this.amount;
    return `${total}`
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id ) {
      this.router.navigate(['/deposit/make']);
    }
  }

}
