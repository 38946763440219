<div class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'member.list.header.title'">
        <!--<button
            mat-raised-button
            routerLink="/member/edit"
            color="primary"
            aria-label="button with a add one icon">
            <mat-icon>add</mat-icon>
            <mat-label>{{"app.menu.member.new" | translate}}</mat-label>
          </button>-->
      </app-header>
    </mat-card-content>
  </mat-card>
  <div class="row">
    <div class="col-xs-12 col-md-12">
        <table style="width: 100%;" #table mat-table [dataSource]="tableDataSource"  class="mat-elevation-z4" >
            <!-- Checkbox column-->
            <ng-container matColumnDef="checked">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? checkBoxChange(element) : null"
                            [checked]="selection.isSelected(element)"
                            >
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Registration number Column -->
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef > {{ "member.list.registration.number.label" | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.mobilePhone}} </td>
              </ng-container>

              <!-- Registration number Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef > {{ "member.list.header.name.label" | translate}} </th>
              <td mat-cell *matCellDef="let element"> {{element.fullname}} </td>
            </ng-container>

            <!-- Group Column -->
            <ng-container matColumnDef="group">
                <th mat-header-cell *matHeaderCellDef > {{ "member.list.header.group.label" | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.options }} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef > {{ "profile.about.birthdate.label" | translate}} </th>
              <td mat-cell *matCellDef="let element"> {{getYear(element)}} </td>
          </ng-container>

          <!-- dead Column -->
          <ng-container matColumnDef="dead">
            <th mat-header-cell *matHeaderCellDef > {{ "member.list.header.dead.label" | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.dead }} </td>
        </ng-container>

        <!-- Registration date Column -->
        <ng-container matColumnDef="since">
          <th mat-header-cell *matHeaderCellDef > {{ "member.list.header.since.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.creationDate | date }} </td>
      </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 2em;" > </th>
                <td mat-cell *matCellDef="let element" style="width: 2em;">
                    <button mat-icon-button (click)="viewElement(element)" style="color: orange;" matTooltip="{{ 'btn.show.label' | translate}}">
                      <mat-icon >info</mat-icon></button>
                </td>
            </ng-container>
            <!-- pagination on footer -->
            <ng-container matColumnDef="pagination">
              <td mat-footer-cell *matFooterCellDef colspan="5" class="footer-td" >
                  <mat-paginator *ngIf="paginatorParams" [length]="paginatorParams.length"
                                (page)="pageChanged($event)"
                                [pageSize]="paginatorParams.pageSize"
                                [pageSizeOptions]="paginatorParams.pageSizeOptions">
                  </mat-paginator>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['checked','number','name', 'group', 'since', 'status', 'dead']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['checked','number','name', 'group', 'since', 'status', 'dead'];" (click)="checkBoxChange(row)"></tr>
            <tr mat-footer-row *matFooterRowDef="['pagination']" ></tr>
        </table>
    </div>

    <app-buttons-bar
  #btnBar *ngIf="buttons"
  [functionality]="'third-party-payer.care-coverage-doc'"
  [actions]="buttons"
  (action)="btnActionClick($event)">
</app-buttons-bar>
</div>
