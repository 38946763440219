import {NobleConfig} from '@noble/types';


export const nobleConfig: NobleConfig = {
  colorTheme      : 'theme-default',
  customScrollbars: true,
  layout          : {
    style    : 'app-navbar-home',
    appNavbarOut: {
      showLoginButton: true,
      showRegisterButton: true,
    },
    width    : 'fullwidth',
    navbar   : {
      primaryBackground  : 'noble-navy-700',
      secondaryBackground: 'noble-navy-900',
      folded             : false,
      hidden             : false,
      position           : 'left',
      variant            : 'app-navbar'
    },
    toolbar  : {
      customBackgroundColor: false,
      background           : 'noble-white-500',
      hidden               : false,
      position             : 'below-static'
    },
    footer   : {
      customBackgroundColor: true,
      background           : 'noble-navy-900',
      hidden               : false,
      position             : 'below-fixed'
    },
    sidepanel: {
      hidden  : false,
      position: 'right'
    }
  }
};
