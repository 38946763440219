<div class="useful-zone">
    <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
        <div class=" noble-retour-link">
            <span class="link-title">
                {{"footer.link.noble-retour.title.label" | translate}}
            </span><br><br>

            <ul class="link-ul footer-link">
                <li *ngFor="let link of nobleLink" style="margin-bottom: 5px;">
                    <a [routerLink]="link.routerLink" class="d-flex align-items-center">
                        <span class="list-dot"></span>
                        <span class="uc-first link-item ml-2">
                            {{ link.label | translate | lowercase }}
                        </span>
                    </a>
                </li>

            </ul>

        </div>

      <div class=" infos-link">
            <span class="link-title">
                {{"footer.link.legal-infos.title.label" | translate}}
            </span><br><br>

        <ul class="link-ul">
          <li *ngFor="let link of infosLink" style="margin-bottom: 5px;">
            <a (click)="beginDownload(link.element)" class="link d-flex align-items-center">
              <span class="list-dot"></span>
              <span class="link-item ml-2">
                            {{ link.label | translate}}
              </span>
            </a>
          </li>
        </ul>
      </div>

        <div class=" usefull-link">
            <span class="link-title">
                {{"footer.link.usefull.title.label" | translate}}
            </span><br><br>

            <ul class="link-ul">
                <li *ngFor="let link of usefulLink" style="margin-bottom: 5px;">
                  <a (click)="beginDownload(link.element)" class="d-flex align-items-center link">
                    <span class="list-dot"></span>
                    <span class="link-item ml-2">
                            {{ link.label | translate}}
                    </span>
                </a>
                </li>

            </ul>
        </div>

        <div class=" contact-link">
            <span class="link-title">
                {{"footer.link.contact.title.label" | translate}}
            </span><br><br>

            <ul class="link-ul">
                <li style="margin-bottom: 2px;">
                    <span class="link-item2">
                        <mat-icon class="link-icon2">local_phone</mat-icon>
                        {{ "footer.link.contact.number.label" | translate}}
                    </span>
                </li>

                <li style="margin-bottom: 2px;">
                    <span class="link-item2">
                      <mat-icon class="link-icon2" style="padding-right: 4px;">email</mat-icon>
                      <span style="user-select: none; pointer-events: none">{{ "footer.link.contact.email.label" | translate}}</span>
                    </span>
                </li>

                <li style="margin-bottom: 2px;">
                    <span class="link-item2">
                      <mat-icon class="link-icon2" style="padding-right: 4px;">public</mat-icon>
                      <a href="https://www.noble-retour.org">{{ "footer.link.contact.website.label" | translate}}</a>
                    </span>
                </li>

                <li style="margin-bottom: 2px;">
                    <mat-icon class="link-icon2">location_on</mat-icon>
                    <span class="link-item2">
                        {{ "footer.link.contact.address.label" | translate}}
                    </span>
                </li>

                <li style="margin-bottom: 2px;">
                    <span class="link-icon2">IBAN</span>
                    <span class="link-item2">
                        {{ "footer.link.contact.iban.label" | translate}}
                    </span>
                </li>

                <li style="margin-bottom: 2px;">
                    <span class="link-icon2">BIC</span>
                    <span class="link-item2">
                        {{ "footer.link.contact.bic.label" | translate}}
                    </span>
                </li>

                <!--<li style="font-size: 15px;">
                    <span>
                        <a href="https://www.paypal.com/mep/dashboard" style="color: #969696;" target="_Blank">
                            <img src="assets/images/paypal.png" style="width: 141px;margin-top: 9px;"/>
                        </a>
                    </span>
                </li>-->

            </ul>

        </div>

    </div>

    <div class="row" style="margin-bottom: 20px;">
        <div class="col-xs-12 col-sm-6 col-md-3">
            <a routerLink="/home">
                <img src="assets/images/logo.png" style="width:217px;"/>
            </a>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-6" class="social-link">
            <span class="link-title" style="padding-left: 15px">
                {{"footer.social.icon.title.label" | translate}}
            </span><br><br>
            <a href="https://www.facebook.com/nobleretourasbl" class="col-xs-2 col-sm-2 col-md-1">
                <img src="assets/images/facebook.ico" class="social-icon"/>
            </a>

            <a href="https://www.youtube.com/channel/UCikRGVQQjyeouv-G8exkZsQ?view_as=subscriber" class="col-xs-2 col-sm-2 col-md-1">
                <img src="assets/images/youtube.ico" class="social-icon"/>
            </a>

            <a href="https://www.linkedin.com/company/nobleretourasbl/?viewAsMember=true" class="col-xs-2 col-sm-2 col-md-1">
                <img src="assets/images/linkedin.ico"  class="social-icon" />
            </a>

            <a href="https://www.instagram.com/nobleretourasbl/" class="col-xs-2 col-sm-2 col-md-1">
                <img src="assets/images/instagram.ico" class="social-icon"/>
            </a>
        </div>

    </div>

</div>

<div class="footer-bar">
    <div class="copyright">
        <span style="color:#fff;">
            {{"footer.copyriht.label" | translate}}
        </span>
    </div>
</div>

<file-downloader hidden #downloader class="hide"
                 [open-in-tab]="openFileInTab"
                 file-type="application/pdf"
                 succedCodeMessage="document.download.succeed"
                 failCodeMessage="document.download.fail"
></file-downloader>
