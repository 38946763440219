import {AfterViewInit, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {PdfViewerComponent} from 'ng2-pdf-viewer';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit, AfterViewInit {

  pdfSource: string;

  @ViewChild('viewer') viewer: PdfViewerComponent;

  @Input() path: string = environment.apiHost + environment.uploadStore;

  constructor(public dialogRef: MatDialogRef<DocumentViewerComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit(): void {
    if (this.modalData.data) {
      this.setObjectInView(this.modalData.data);
    }
  }

  ngAfterViewInit() {
    if (this.viewer) {

    }
  }

  private setObjectInView(data: any) {
    this.pdfSource = `${this.path}/${data}`;
  }
}
