<div class="primary-navbar">
  <div mat-ripple routerLink="/home" class="navbar-logo">
    <img alt="logo" class="navbar-logo-image" src="assets/images/new-logo.png" height="38" width="38">
    <div class="navbar-logo-text d-none d-md-block">NOBLE RETOUR</div>
  </div>

  <app-navbar-menu [menuLinks]="menuLinks" class="d-none d-lg-block"></app-navbar-menu>

  <div class="navbar-buttons">
    <button routerLink="/login"
            mat-raised-button color="primary"
            attr.aria-label="{{'signIn.btn.label' | translate}}">
      {{ "login.btn.label" | translate}}
    </button>

    <div class="ml-2 d-flex align-items-center"
         [matMenuTriggerFor]="langMenu"
         *ngIf="languageService.currentLanguage"
         attr.aria-label="{{'change-lang-btn.label' | translate}}"
         matTooltip="{{'change-lang-btn.label' | translate}}">
      {{languageService.currentLanguage.value | uppercase}}
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-menu #langMenu="matMenu">
      <ng-container *ngFor="let lang of languageService.availableLanguages">
        <button mat-menu-item (click)="languageService.changeLanguage(lang)">{{lang.labelCode | translate}}
        </button>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-menu>

  </div>

</div>

<div class="d-lg-none secondary-navbar">
  <app-navbar-menu [menuLinks]="menuLinks"></app-navbar-menu>
</div>
