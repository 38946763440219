<div class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'profile.page.beneficiary.title'">
        <button
          mat-raised-button
          routerLink="/manage/account"
          color="primary"
          aria-label="button with a add one icon">
          <mat-icon>skip_previous</mat-icon>
          <mat-label>{{"profile.password.update.btn-back-to-account.label" | translate}}</mat-label>
        </button>
      </app-header>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card col-xs-12  col-sm-12">
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="text-center">
         <ng-container *ngIf="!currentObject && mode === 'view'">
           <button mat-raised-button (click)="addBeneficiary()" color="primary"
             aria-label="button with a add one icon">
             <mat-icon>add</mat-icon>
             <mat-label>{{"profile.beneficiary.add.btn.label" | translate}}</mat-label>
           </button>
         </ng-container>
          <ng-container *ngIf="currentObject && mode === 'view'">
            <button mat-raised-button color="accent" (click)="editBeneficiary()"
                    aria-label="button with a add one icon">
              <mat-icon>edit</mat-icon>
              <mat-label>{{"profile.beneficiary.edit.btn.label" | translate}}</mat-label>
            </button>
          </ng-container>
      </div>

      <ng-container *ngIf="mode === 'edit'">
        <form [formGroup]="beneficiaryForm" name='beneficiary-edit-form'>
          <div class="row">
            <mat-form-field class="col-xs-12 col-sm-6 col-md-4" >
              <input required type="text" placeholder='{{"profile.beneficiary.name.label" | translate}}'
                     formControlName="name" name='name'
                     matInput maxlength="255" >
              <mat-error *ngIf="beneficiaryForm.get('name')?.errors">{{ "member.name.empty.error" | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-xs-12 col-sm-6 col-md-4" >
              <input required type="text" placeholder='{{"profile.beneficiary.surname.label" | translate}}'
                     formControlName="surname" name='surname'
                     matInput maxlength="255" >
              <mat-error *ngIf="beneficiaryForm.get('surname')?.errors">{{ "member.surname.empty.error" | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field  class="col-xs-12 col-sm-6 col-md-4">
              <mat-label>{{ 'register.form.birth.date.label' | translate }}</mat-label>
              <input [max]="maxDate" formControlName="birthdate" matInput #birthDateMatInput [matDatepicker]="picker"
                     placeholder="{{ 'register.form.birth.date.placeholder' | translate }}"
                     required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="beneficiaryForm.get('birthdate')?.errors">{{ "profile.beneficiary.birthdate.empty.error" | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-xs-12 col-sm-6 col-md-4">
              <mat-label>{{ 'family-member.form.gender.label' | translate }}</mat-label>
              <input required
                     formControlName="gender" matInput
                     [matAutocomplete]="autoGender">
              <mat-autocomplete #autoGender="matAutocomplete" [displayWith]="display">
                <mat-option  *ngFor="let option of genders" [value]="option">
                  {{ display(option) }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="beneficiaryForm.get('gender')?.errors">{{ "profile.beneficiary.gender.empty.error" | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-xs-12 col-sm-6 col-md-4" >
              <input required type="number" placeholder='{{"member.phone.label" | translate}}'
                     formControlName="mobilePhone" name='mobilePhone'
                     matInput maxlength="255">
              <mat-error *ngIf="beneficiaryForm.get('mobilePhone').errors">{{ "profile.beneficiary.name.mobilePhone.error" | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-xs-12 col-sm-6 col-md-4" >
              <input required type="email" placeholder='{{"member.emailAddress.label" | translate}}'
                     formControlName="emailAddress" name='emailAddress'
                     matInput maxlength="255" >
              <mat-error *ngIf="beneficiaryForm.get('emailAddress')?.errors">{{ "family-member-update.emailAddress.error" | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="col-xs-12 col-sm-6 col-md-4" >
              <input required type="text" placeholder='{{"member.fax.label" | translate}}'
                     formControlName="fax" name='fax'
                     matInput maxlength="255" >
              <mat-error *ngIf="beneficiaryForm.get('fax')?.errors">{{ "profile.beneficiary.edit.fax.empty.error" | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="col-xs-12 col-sm-12 col-md-12" >
          <textarea
            placeholder='{{"profile.beneficiary.edit.description.label" | translate}}'
            formControlName="description"
            name='description' matInput ></textarea>
              <mat-error *ngIf="beneficiaryForm.get('description')?.errors">{{ "profile.beneficiary.edit.description.empty.error" | translate}}</mat-error>
            </mat-form-field>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="currentObject && mode === 'view'">
        <mat-card class="example-card col-xs-12  col-sm-12">
          <mat-card-header>
            <mat-card-title>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content *ngIf='currentObject'>
              <div class="row"  style="margin-bottom: 15px">
                <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="currentObject.name">
                  <label style="margin-right: 15px">{{"profile.about.name.label" | translate}}</label>
                  <span>{{ currentObject.name }}</span>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="currentObject.surname">
                  <label style="margin-right: 15px">{{"profile.beneficiary.surname.label" | translate}}</label>
                  <span>{{ currentObject.surname }}</span>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="currentObject.birthdate" >
                  <label style="margin-right: 15px">{{"profile.about.birthdate.label" | translate}}</label>
                  <span>{{getYear(currentObject)}}</span>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="currentObject.gender" >
                  <label style="margin-right: 15px">{{"family-member.form.gender.label" | translate}}</label>
                  <span>{{display(selectedGender)}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="currentObject.mobilePhone">
                  <label style="margin-right: 15px">{{"member.phone.label" | translate}}</label>
                  <span>{{ currentObject.mobilePhone }}</span>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="currentObject.fax">
                  <label style="margin-right: 15px">{{"member.fax.label" | translate}}</label>
                  <span>{{ currentObject.fax }}</span>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4" *ngIf="currentObject.emailAddress" >
                  <span>
                    <mat-chip-list aria-label="Fish selection">
                      <mat-chip color="accent" selected>{{currentObject.emailAddress}}</mat-chip>
                    </mat-chip-list>
                  </span>
                </div>
            </div>
          </mat-card-content>
          <mat-card-actions>

          </mat-card-actions>
        </mat-card>
      </ng-container>
    </mat-card-content>

    <mat-card-actions>
      <app-buttons-bar *ngIf="buttons && mode === 'edit'"
                       [functionality]="'profile-beneficiary-member.edit'"
                       [actions]="buttons"
                       (action)="btnActionClick($event)">
      </app-buttons-bar>
    </mat-card-actions>
  </mat-card>
</div>
