import { createFeatureSelector } from '@ngrx/store';
import * as authentication from './reducers/authentication.reducer';

export interface LoginState {
  authenticationState: authentication.State;
}

export const reducers = {
  authentication: authentication.authReducer
};


export const selectAuthenticationState = createFeatureSelector<LoginState>('authentication');
