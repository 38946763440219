import {ClassNameMap, Persistent} from '../persistent/persistent';

export class PotentialUser extends Persistent{
  public email?: string;
  public phoneNumber?: string;
  constructor(email?: string, phoneNumber?: string) {
    super();
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.classe = ClassNameMap.PotentialUser;
  }
}
