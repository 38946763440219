<div class="login-zone align-items">
  <div class="card row login-container">
    <h2 class="col-xs-12 col-sm-12 col-md-12 card-header align-items">
      {{"forgot.form.title.label"| translate}}
    </h2>
    <div class="card-body">
      <form [formGroup]="forgotPassForm" (ngSubmit)="onSubmit()">

        <mat-form-field class="col-xs-12 col-sm-12 col-md-12" appearance="outline">
          <mat-label>{{'login.form.userName.label' | translate}}</mat-label>
          <input matInput formControlName="email">
          <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="submitted && forgotPassForm.get('email').invalid">{{ "forgot.form.email.required" | translate}}</mat-error>
        </mat-form-field><br>

        <button [disabled]="loading" class="btn btn-lg btn-block signIn-btn">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{"forgot.submit.btn.label"| translate}}
        </button><br>
      </form>
    </div>
  </div>
</div>
