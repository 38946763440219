import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MemberShip} from '../../../../../model/register/MemberShip';
import {Member} from '../../../../../model/organisation/Member';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpService} from '../../../../../services/http-service/http.service';
import {NobleValidators} from "../../../../base/validators/noble-validators";

@Component({
  selector: 'app-profile-about-edit',
  templateUrl: './profile-about-edit.component.html',
  styleUrls: ['./profile-about-edit.component.scss']
})
export class ProfileAboutEditComponent implements OnInit {

  @Input() memberShip: MemberShip;

  @Output() update: EventEmitter<MemberShip> = new EventEmitter<MemberShip>();
  @Output() endUpdate: EventEmitter<MemberShip> = new EventEmitter<MemberShip>();
  @Output() errorUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter<any>();

  memberShipForm: FormGroup;

  loading = false;
  disabledSave = true;

  constructor
 (
    private fb: FormBuilder,
  )
  {

  }

  ngOnInit(): void {
    this.initForm();
    this.memberShipForm.valueChanges.subscribe(va => {
       if (this.memberShipForm.valid && (va.fax !== null && va.fax.trim() !== '') && va.fax !== this.memberShip?.fax) {
        this.disabledSave = false;
      } else if (this.memberShipForm.valid && va.mobilePhone !== this.memberShip.mobilePhone) {
         this.disabledSave = false;
       }else  {
        this.disabledSave = true;
      }
    });

    this.endUpdate.subscribe(res => {
      this.memberShip = res;
      this.memberShipForm.setValue({
        fax: this.memberShip.fax,
        mobilePhone: this.memberShip.mobilePhone
      });
      this.loading = false;
      this.cancel();
    });

    this.errorUpdate.subscribe(res => {
      this.loading = false;
    });
  }

  initForm() {
    this.memberShipForm = this.fb.group({
      fax: [this.memberShip.fax,  []],
      mobilePhone: [this.memberShip.mobilePhone,  []],
    });
  }

  save() {
    this.loading =  true;
    const entity = this.memberShip;
    entity.fax = this.memberShipForm.value.fax;
    entity.mobilePhone = this.memberShipForm.value.mobilePhone;
    entity.toAddOrUpdate = true;
    this.update.emit(entity);
  }

  cancel() {
    this.cancelEvent.next();
  }
}
