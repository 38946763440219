import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../http-service/http.service';
import {UserAppService} from '../user-service/user-app.service';
import {NotificationService} from '../notification-service/notification.service';
import {Count} from '../../model/register/MemberShip';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends HttpService<Count> implements Resolve<any>{
  memberCount: any;
  activeMemberCount: any;
  inactiveMemberCount: any;
  onProbationMemberCount: any;
  deceaseMemberCount: any;
  memberAndFamilyCount: any;

  memberCountOnChanged: BehaviorSubject<any>;
  activeMemberCountOnChanged: BehaviorSubject<any>;
  inactiveMemberCountOnChanged: BehaviorSubject<any>;
  onProbationMemberCountOnChanged: BehaviorSubject<any>;
  deceaseMemberCountOnChanged: BehaviorSubject<any>;
  memberAndFamilyCountOnChanged: BehaviorSubject<any>;

  constructor(public userService: UserAppService ,
              public notificationService: NotificationService, private _httpClient: HttpClient) {
    super(userService, _httpClient, notificationService);
    this.memberCountOnChanged = new BehaviorSubject<any>({});
    this.activeMemberCountOnChanged = new BehaviorSubject<any>({});
    this.inactiveMemberCountOnChanged = new BehaviorSubject<any>({});
    this.onProbationMemberCountOnChanged = new BehaviorSubject<any>({});
    this.deceaseMemberCountOnChanged = new BehaviorSubject<any>({});
    this.memberAndFamilyCountOnChanged = new BehaviorSubject<any>({});
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<any>((resolve, reject) => {
      Promise.all([
        this.getMemberCount(),
        this.getActiveMemberCount(),
        this.getInactiveMemberCount(),
        this.getOnProbationMemberCount(),
        this.getDeceaseMemberCount(),
        this.getMemberAndFamilyCount()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get all member count
   */
  getMemberCount(): Promise<Count>
  {
    return new Promise<any>((resolve, reject) => {
      this.get(`organisation/member-all-count`)
        .subscribe((count: Count) => {
          this.memberCount = count;
          this.memberCountOnChanged.next(this.memberCount);
          resolve(this.memberCount);
      }, (error => {
        reject(error);
      }));
    });
  }

  /**
   * Get active member count
   */
  getActiveMemberCount(): Promise<Count>
  {
    return new Promise<any>((resolve, reject) => {
      this.get(`organisation/member-active-count`)
        .subscribe((count: Count) => {
          this.activeMemberCount = count;
          this.activeMemberCountOnChanged.next(this.activeMemberCount);
          resolve(this.activeMemberCount);
      }, (error => {
        reject(error);
      }));
    });
  }
  /**
   * Get inactive member count
   */
  getInactiveMemberCount(): Promise<Count>
  {
    return new Promise<any>((resolve, reject) => {
      this.get(`organisation/member-inactive-count`)
        .subscribe((count: Count) => {
          this.inactiveMemberCount = count;
          this.inactiveMemberCountOnChanged.next(this.inactiveMemberCount);
          resolve(this.inactiveMemberCount);
      }, (error => {
        reject(error);
      }));
    });
  }

  /**
   * Get inactive member count
   */
  getOnProbationMemberCount(): Promise<Count>
  {
    return new Promise<any>((resolve, reject) => {
      this.get(`organisation/member-probation-count`)
        .subscribe((count: Count) => {
          this.onProbationMemberCount = count;
          this.onProbationMemberCountOnChanged.next(this.onProbationMemberCount);
          resolve(this.onProbationMemberCount);
      }, (error => {
        reject(error);
      }));
    });
  }
  /**
   * Get decease member count
   */
  getDeceaseMemberCount(): Promise<Count>
  {
    return new Promise<any>((resolve, reject) => {
      this.get(`organisation/member-decease-count`)
        .subscribe((count: Count) => {
          this.deceaseMemberCount = count;
          this.deceaseMemberCountOnChanged.next(this.deceaseMemberCount);
          resolve(this.deceaseMemberCount);
      }, (error => {
        reject(error);
      }));
    });
  }
  /**
   * Get member and family count
   */
  getMemberAndFamilyCount(): Promise<Count>
  {
    return new Promise<any>((resolve, reject) => {
      this.get(`organisation/member-family-count`)
        .subscribe((count: Count) => {
          this.memberAndFamilyCount = count;
          this.memberAndFamilyCountOnChanged.next(this.memberAndFamilyCount);
          resolve(this.memberAndFamilyCount);
      }, (error => {
        reject(error);
      }));
    });
  }
}
