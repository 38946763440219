<ng-container *ngIf="!useButton">
  <a *ngFor="let link of menuLinks" class="navbar-menu-link"
     routerLinkActive="active"
     [routerLinkActiveOptions]="{exact: true}"
     [routerLink]="link.routerLink">
    {{link.label | translate}}
  </a>
</ng-container>
<ng-container *ngIf="useButton">
  <button  mat-raised-button color="secondary" *ngFor="let link of menuLinks" class="navbar-menu-link toolbar-buttons"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          [routerLink]="link.routerLink">
    {{link.label | translate}}
  </button>
</ng-container>


