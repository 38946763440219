<div class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'profile.page.title'"></app-header>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card col-xs-12  col-sm-12">
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="text-center">
        <h4>{{'profile.wel-come.title'| translate}} 🤞 {{currentMemberShip?.fullname}}</h4>
        <p>{{'profile.wel-come.subtitle'| translate}}</p>
      </div>

      <div class="row">
        <div class="card-deck">
          <ng-template [ngIf]="'true'" *ngFor="let element of items">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-sm-8">
                    <h5 class="card-title">{{element.title | translate}}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{element.subtitle | translate}}</h6>
                  </div>
                  <div class="d-none d-sm-block col-sm-4 text-right">
                    <img [src]="element.imageSrc" class="w-100 h-100" style="object-fit: contain; max-height: 100px;">
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <a [routerLink]="element.button.ref" [state]="{n: currentMemberShip?.fullname}" class="btn btn-primary stretched-link">{{element.button.text | translate}}</a>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
