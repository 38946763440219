import { Injectable } from '@angular/core';
import {HttpService} from '../http-service/http.service';
import {UserAppService} from '../user-service/user-app.service';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../notification-service/notification.service';
import {Observable} from 'rxjs';
import {PaymentIntent} from '@stripe/stripe-js';
import {GlobalVariables} from '../global-variables';
import {Basket} from '../../model/payment/Basket';
import {Payment} from "../../model/payment/Payment";

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends HttpService<any>{

  constructor(public userService: UserAppService ,
              public http: HttpClient, public notificationService: NotificationService) {
    super(userService, http, notificationService);
  }

  createPaymentIntent(basket: Basket): Observable<any> {
    return this.http.post<any>(
      `${GlobalVariables.CONTEXT_PATH}/payment/create-payment-intent`,
      basket
    );
  }

  savePayment(paymentIntent: PaymentIntent): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(
        `${GlobalVariables.CONTEXT_PATH}/payment/save-payment`,
        paymentIntent
      ).subscribe(() => {
        resolve();
      }, (error => {
        reject(error);
      }));
    });
  }

  saveSucceedPayment(payment: Payment): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(
        `${GlobalVariables.CONTEXT_PATH}/payment/save-succeeded-payment`,
        payment
      ).subscribe(() => {
        resolve();
      }, (error => {
        reject(error);
      }));
    });
  }
}
