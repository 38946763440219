import { ClassNameMap, Persistent } from '../persistent/persistent';
import { Party } from './Party';
import { Person } from './Person';

export class Donation extends Persistent{
    public amount?: Amount;
    public donor?: Party;

    constructor() {
        super();
        this.classe = ClassNameMap.Donation;
        this.amount = new Amount();
        this.donor = new Party();
    }
}

export class Amount extends Persistent{
    public value?: number;
    public currency?: string;

    constructor() {
        super();
        this.classe = ClassNameMap.Amount;
    }
}

export enum Civility {
    M,
    MME,
    OTHER
}
