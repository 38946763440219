<div class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'deposit.second.title'"></app-header>
    </mat-card-content>
  </mat-card>
  <mat-card class="form-deposit">
    <mat-card-content class="content">
      <div class="deposit-information-title">
        <p class="label-title">{{ 'confirm.deposit.title' | translate}}</p>
      </div>
      <ul class="lis-style">
        <li>{{'confirm.deposit.amount.deposit.label' | translate}} : {{getAmount() | amount}}</li>
        <li>{{'confirm.deposit.transaction.fees.label' | translate}} : {{getFeesTransaction() | amount}}</li>
        <li>{{'confirm.deposit.total.amount.label' | translate}} : {{getTotalAmout() | amount}}</li>
      </ul>
      <div>
        <p style="font-family: cursive;">{{'confirm.deposit.advice' | translate}}</p>
      </div>
    </mat-card-content>
    <mat-action-list>
      <app-buttons-bar
            #btnBar *ngIf="buttons"
            [functionality]="'confirm.deposit.btn.list.label'"
            [actions]="buttons"
            (action)="btnActionClick($event)">
          </app-buttons-bar>
    </mat-action-list>
  </mat-card>
</div>
