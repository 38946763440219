
<div class="banner" >
  <div class="banner-body" style="flex-direction: column">
    <div class="banner-title">{{ "our-project.title" | translate }}</div>
    <div class="paragraph mt-4 p-3 text-center" style="color: #FFF">
      {{ "our-project.description" | translate }}
    </div>

    <div class="mt-3">
      <button mat-raised-button class="ml-2"
              (click)="hireMe()">{{ "our-project.btn.hire-me.label" | translate}}
      </button>
    </div>
  </div>
  <img src="assets/images/nosprojets.jpeg" class="banner-bg-img">
  <div class="banner-glass"></div>
</div>

<div class="page-body">

  <div class="d-flex flex-direction-column-xs">
    <div>
      <div>
        <h1 class="section-title">{{"our-project.activities.title.label"| translate}} </h1>
        <div class="paragraph">
          <div> {{"our-project.activities.description.label"| translate}} </div>
          <ul>
            <li *ngFor="let activity of activities">{{ activity | translate}} </li>
          </ul>
        </div>
      </div>

      <div class="pt-3">
        <h1 class="section-title">{{"our-project.goals.title.label"| translate}} </h1>
        <ul class="paragraph">
          <li *ngFor="let goal of goals" style="margin-bottom: 10px;">
            {{ goal | translate}}
          </li>
        </ul>
      </div>

    </div>
    <div class="col-xs-12 col-sm-8 col-md-5">
      <img src="assets/images/our-mission4.jpg" class="d-none d-sm-block" height="400">
    </div>
  </div>


  <div class="mt-5">

    <h1 class="section-title">{{"about-us.offered-services.title.label"| translate}}</h1>


    <div >

      <p class="paragraph">{{"about-us.offered-services.description1.label"| translate}}</p>
      <p class="paragraph">{{"about-us.offered-services.description2.label"| translate}}</p>

      <div class="mt-4">
        <h1>{{"about-us.offered-services.option1.label"| translate}}</h1>
        <p class="paragraph">{{"about-us.offered-services.option1.description.label"| translate}}</p>
        <br>

        <div class="services-container">
          <div *ngFor="let service of servicesOption1" class="service-item">
            <div>
              <i class="material-icons service-item-icon">{{service.icon}}</i>
            </div>

            <div>
              <h2 class="service-item-title">{{service.title | translate}}</h2>
              <p class="service-item-description">{{service.description | translate}}</p>
            </div>
          </div>
        </div>
      </div>
      <br>

      <div>
        <h1>{{"about-us.offered-services.option2.label"| translate}}</h1>
        <p class="paragraph">{{"about-us.offered-services.option2.description.label"| translate}}</p><br>
      </div>

    </div>
  </div>
</div>

<div class="p-4" style="background-color: #e1dfdd">
  <div>
    <h2>{{"about-us.interest.tilte.label"| translate}}</h2>
    <span class="my-2 paragraph">{{"about-us.interest.description.label"| translate}}</span>
  </div>
  <div class="mt-3">
    <button mat-raised-button color="primary" (click)="hireMe()">
      <mat-icon>swap_vertical_circle</mat-icon>
      <span class="ml-2">{{"about-us.interest.btn.adhere.label" | translate}}</span>
    </button>
  </div>
</div>

<app-footer-bar></app-footer-bar>
