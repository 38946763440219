<div  class="app-record-doc-edit">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'record-doc.edit.header.title'">
        <button
          mat-raised-button
          routerLink="/record-doc/list"
          color="primary"
          aria-label="button with a add one icon">
          <mat-icon>list</mat-icon>
          <mat-label>{{"record-doc.list.header.title" | translate}}</mat-label>
        </button>
      </app-header>
    </mat-card-content>
  </mat-card>
  <div class="row" style="padding: 10px;">
    <fieldset class="the-fieldset">
      <div>
        <div class="row" style="padding-right: 20px;">
          <mat-card class="example-card col-xs-12  col-sm-12">
            <mat-card-header>
              <mat-card-title>
                {{'record-doc.edit.first.step' | translate}}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <form [formGroup]="firstFormGroup">
                <app-record-information #recordInformationComponent></app-record-information>
              </form>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="row" style="padding-right: 20px;">
          <mat-card class="example-card col-xs-12  col-sm-12">
            <mat-card-header>
              <mat-card-title>
                {{'record-doc.edit.second.step' | translate}}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <form [formGroup]="secondFormGroup">
                <app-record-document [modal]="false" #recordDocumentComponent></app-record-document>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </fieldset>
  </div>

  <app-buttons-bar
    #btnBar *ngIf="buttons"
    [functionality]="'record-doc-edit'"
    [actions]="buttons"
    (action)="btnActionClick($event)">
  </app-buttons-bar>
</div>
