import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from 'app/services/http-service/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService, NotificationType } from 'app/services/notification-service/notification.service';
import { Member, MemberType } from 'app/model/organisation/Member';
import { IFormActionBarButton, FormAction } from '../../base/buttons-bar/buttons-bar.component';
import { Country } from 'app/model/country/Country';
import { GenderType } from 'app/model/organisation/Person';
import {ConfigService} from '@noble/services/config.service';

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.scss']
})
export class MemberEditComponent implements OnInit {

  currentObject: Member;

  memberForm: FormGroup;
  countryCtrl: FormControl;
  optionsCountry: Country[];

  buttons: IFormActionBarButton[];

  sexes: any[] = [
    {value: GenderType[GenderType.FEMALE], text: 'translate.Feminin'},
    {value: GenderType[GenderType.MALE], text: 'translate.Masculin'}
  ];

  types: any[] = [
    {value: MemberType[MemberType.VISITEUR], text: 'member.type.visitor'},
    {value: MemberType[MemberType.RESIDENT], text: 'member.type.resident'}
  ];

  constructor(
    private fb: FormBuilder, private httpService: HttpService<Member>, private router: Router,
    private route: ActivatedRoute, private msgService: NotificationService,
    private _configService: ConfigService
  ) {

    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };
    this.currentObject = new Member();

    this.buttons = [
      {id: 'save', value: FormAction.CREATE, icon: {type : 'save'},
      text: 'btn.save.label', disabled: false, functionalities: []},
      {id: 'list', value: FormAction.VIEW, icon: {type : 'list'},
      text: 'btn.list.label', disabled: false, functionalities: []}
    ];
   }

  ngOnInit(): void {
    this.initForm();
    this.memberForm.patchValue(this.currentObject);

    /*this.countryCtrl.valueChanges.subscribe ((value) =>{
      if (!value || typeof value === 'string') {
        this.memberForm.get('nationality').setValue(null);
        this.getCountry(value);
      } else {
        this.memberForm.get('nationality').setValue(value);
      }
    });*/

    this.route.paramMap.subscribe(params => {
      const data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'organisation/member', true).subscribe( (object) => {
          this.setObjectInView(object);
        }, (errors) => {
          this.msgService.log('member.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  initForm() {
    this.memberForm = this.fb.group({
      name: [null,  [Validators.required]],
      surname: [null,  [Validators.required]],
      placeOfBirth: [null,  []],
      birthdate: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      gender: [null, [Validators.required]],
      type: [null, [Validators.required]],
      expirationDate: [null, [Validators.required]],
      nationality: [null, [Validators.required]],
      identity: [null, [Validators.required]],
      officePhone: [null, [Validators.required]],
      socialSecurityNumber: [null, [Validators.required]]
    });
  }

  public getCountry(value) {
    this.httpService.search({ criteria: value }, 'location/country').subscribe((result) => {
      this.optionsCountry = result ? result.datas : [];
    });
  }

  getObjectFromView(){
    this.currentObject = Object.assign(this.currentObject, this.memberForm.value);
  }

  isValid(){
    let isValid = true;
    if (this.memberForm.invalid === true){
      isValid = false;
    }
    return isValid;
  }

  setObjectInView(object: Member) {
    if (object) {
      this.currentObject = object;
      this.memberForm.patchValue(this.currentObject);
    }
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id){
      this.save();
    } else if (event && event.id === this.buttons[1].id){
      this.router.navigate(['member/list', {search: true}]);
    }
  }

  save() {
    this.getObjectFromView();
    if (this.isValid() === true) {
      this.httpService.save(this.currentObject, 'organisation/member', true).subscribe( (result) => {
        this.msgService.log('member.edit.save.succed', NotificationType.SUCCESS);
        this.router.navigate(['member/list', {search: true}]);
      }, (error) => {
        if (typeof error === 'string') {
          this.msgService.log(error, NotificationType.ERROR);
        } else {
          this.msgService.log('member.edit.save.fail', NotificationType.ERROR);
        }
      });
    } else {
    }
  }

}
