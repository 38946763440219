<div class="app-record-doc">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'record-doc.list.header.title'">
        <button
          mat-raised-button
          routerLink="/record-doc/edit"
          color="primary"
          aria-label="button with a add one icon">
          <mat-icon>add</mat-icon>
          <mat-label>{{"record-doc.list.member.new" | translate}}</mat-label>
        </button>
      </app-header>
      <mat-form-field>
        <input (keydown.enter)="searchClicked($event)" #searchText type="text" matInput placeholder="{{'record-doc.search.label' | translate}}"
               [formControl]="searchFormControl" maxlength="255"/>
        <button matSuffix mat-button matTooltip="{{ 'record-doc.search.label' | translate}}"
                (click)="searchClicked($event)"><mat-icon >search</mat-icon></button>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <div class="row">

    <div class="col-xs-12 col-md-12">
      <table style="width: 100%;" #table mat-table [dataSource]="tableDataSource"  class="mat-elevation-z4" >
        <!-- Checkbox column-->
        <ng-container matColumnDef="checked">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? checkBoxChange(element) : null"
                          [checked]="selection.isSelected(element)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Record number Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef > {{ "record-doc.list.header.number.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.number}} </td>
        </ng-container>

        <!-- Record Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef > {{ "record-doc.list.header.type.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <!-- Record Type Column -->
        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef > {{ "record-doc.list.header.start.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.start | date}} </td>
        </ng-container>

        <!-- Record Type Column -->
        <ng-container matColumnDef="end">
          <th mat-header-cell *matHeaderCellDef > {{ "record-doc.list.header.end.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.end | date}} </td>
        </ng-container>

        <!-- Record Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef > {{ "record-doc.list.header.description.label" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.description }} </td>
        </ng-container>


        <!-- Record Valid Column -->
        <ng-container matColumnDef="valid">
          <th mat-header-cell *matHeaderCellDef > {{ "record-doc.list.header.is-valid.label" | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <ng-template [ngIf]="element.isValid === false">
              <button mat-icon-button>
                <mat-icon color="primary">highlight_off</mat-icon></button>
            </ng-template>
            <ng-template [ngIf]="element.isValid === true">
              <button mat-icon-button>
                <mat-icon style="color:green;">check_circle_outline</mat-icon></button>
            </ng-template>
          </td>
        </ng-container>

        <!-- Edit Action Column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef style="width: 2em;" > </th>
          <td mat-cell *matCellDef="let element" style="width: 2em;">
            <button mat-icon-button (click)="editElement(element)" style="color: orange;" matTooltip="{{ 'btn.update.label' | translate}}">
              <mat-icon>edit</mat-icon></button>
          </td>
        </ng-container>

        <!-- View Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 2em;" > </th>
          <td mat-cell *matCellDef="let element" style="width: 2em;">
            <button mat-icon-button (click)="viewElement(element)" style="color: orange;" matTooltip="{{ 'btn.show.label' | translate}}">
              <mat-icon>visibility</mat-icon></button>
          </td>
        </ng-container>
        <!-- pagination on footer -->
        <ng-container matColumnDef="pagination">
          <td mat-footer-cell *matFooterCellDef colspan="5" class="footer-td" >
            <mat-paginator *ngIf="paginatorParams" [length]="paginatorParams.length"
                           (page)="pageChanged($event)"
                           [pageSize]="paginatorParams.pageSize"
                           [pageSizeOptions]="paginatorParams.pageSizeOptions">
            </mat-paginator>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['checked','number', 'start','end', 'type', 'description', 'valid', 'edit', 'action']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['checked','number', 'start','end', 'type', 'description', 'valid', 'edit', 'action'];" (click)="checkBoxChange(row)"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" ></tr>
      </table>
    </div>


    <app-buttons-bar
      #btnBar *ngIf="buttons"
      [functionality]="'record-doc-list'"
      [actions]="buttons"
      (action)="btnActionClick($event)">
    </app-buttons-bar>
  </div>
