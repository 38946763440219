import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {LanguageService} from 'noble-retour/services';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar-out',
  templateUrl: './navbar-out.component.html'
})
export class NavbarOutComponent {

  @Input() deviceXs: boolean;
  @Input() isHandset$: Observable<boolean>;

  @Input()
  showLoginButton = true;
  @Input()
  showRegisterButton = true;

  constructor(public languageService: LanguageService) { }

}
