import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginState, selectAuthenticationState } from 'app/store/login/login.state';
import { Store } from '@ngrx/store';
import {Logout, RefreshBegin} from 'app/store/login/actions/authentication.action';
import {GlobalVariables} from '../global-variables';
import { State } from 'app/store/login/reducers/authentication.reducer';
import { User } from 'app/model/security/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  getState: Observable<any>;

  constructor(
    private store: Store<LoginState>,
  ) {
    this.getState = this.store.select(selectAuthenticationState);
  }

  init() {
    const userToken = localStorage.getItem(GlobalVariables.APP_TOKEN_STORE_KEY);
    if (userToken) {
      const refreshToken = localStorage.getItem(GlobalVariables.APP_REFRESH_TOKEN_STORE_KEY);
      if (refreshToken) {
        this.store.dispatch(new RefreshBegin(refreshToken));
      }
    }
  }

  logOut(): void {
    const refreshToken = localStorage.getItem(GlobalVariables.APP_REFRESH_TOKEN_STORE_KEY);
    this.store.dispatch(new Logout(refreshToken));
  }
}
