import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProfileService} from '../../../../services/profile.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfigService} from '@noble/services/config.service';
import {MemberShip} from '../../../../model/register/MemberShip';
import {RegisterService} from '../../../../services/register/register.service';
import {Store} from '@ngrx/store';
import {LoginState, selectAuthenticationState} from '../../../../store/login/login.state';
import {State} from '../../../../store/login/reducers/authentication.reducer';
import {User} from '../../../../model/security/user';
import {ProfileAboutEditComponent} from './profile-about-edit/profile-about-edit.component';
import {NotificationService, NotificationType} from '../../../../services/notification-service/notification.service';

@Component({
  selector: 'app-profile-about',
  templateUrl: './profile-about.component.html',
  styleUrls: ['./profile-about.component.scss']
})
export class ProfileAboutComponent implements OnInit, OnDestroy, AfterViewInit
{

  about: any;

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  private _unsubscribeAll: Subject<any>;

  memberShip: MemberShip;
  currentUser: User;

  mode: 'edit' | 'view' = 'view';
  editMode: EventEmitter<string> = new EventEmitter<string>();

  updateComponent: ProfileAboutEditComponent;
  @ViewChild('profileAboutEditComponent') set content(content: ProfileAboutEditComponent) {
    if (content) {
      this.updateComponent = content;
      this.editMode.next();
    }
  }

  favoriteOption: {text: string, value: string, description: string};
  options: {text: string, value: string, description: string}[] = [
    {
      text: 'register.option.one.text.short',
      value: 'option-1',
      description: 'register.option.one.text'
    },
    {
      text: 'register.option.two.text.short',
      value: 'option-2',
      description: 'register.option.two.text'
    }
  ];

  constructor(
    private _profileService: ProfileService,
    private _configService: ConfigService,
    private _registerService: RegisterService,
    private _msgService: NotificationService,
    private store: Store<LoginState>,
  )
  {
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };
    this._unsubscribeAll = new Subject();

    this.memberShip = new MemberShip();

    this.authenticationState$ = this.store.select(selectAuthenticationState);
  }

  ngOnInit(): void
  {
    this._profileService.aboutOnChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(about => {
        this.about = about;
      });

    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.currentUser = state.user.user;
        this._registerService.findMemberShipByLogin(this.currentUser.login).then(memberShip => {
          this.memberShip = memberShip;
          this.options.forEach(e => {
            if (e.value === memberShip.options) {
              this.favoriteOption = e;
            }
          });
        });
      }
    });
  }

  ngOnDestroy(): void
  {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void
  {
    this.editMode.subscribe(() => {
      if (this.mode === 'edit' && this.updateComponent) {
        this.updateComponent.endUpdate.next(this.memberShip);
        this.updateComponent.update.subscribe(entity => {
          this.up(entity, this.updateComponent.endUpdate, this.updateComponent.errorUpdate);
        });
        this.updateComponent.cancelEvent.subscribe(() => {
          this.mode = 'view';
        });
      }
    });
  }

  up(persistence: MemberShip, ev: EventEmitter<MemberShip>, erv: EventEmitter<any>) {

    this._registerService.findMemberShipByLogin(this.currentUser.login).then(memberShip => {
      memberShip.fax = persistence.fax;
      memberShip.toAddOrUpdate = true;
      this._registerService.updateMemberShip(memberShip).then(res => {
        this._msgService.log('member.edit.save.succed', NotificationType.SUCCESS);
        ev.next(res);
      }).catch(err => {
        erv.next(err);
        this._msgService.log('profile.about.edit.member-ship.edit.save.fail', NotificationType.ERROR);
      });
    });
  }

  getYear(element: MemberShip) {
    if (element === undefined || element === null || element.birthdate === undefined || element.birthdate === null) {
      return 0;
    }
    return  new Date(Date.now()).getFullYear() - new Date(element.birthdate).getFullYear();
  }

  editMemberShip() {
    this.mode = 'edit';
  }
}
