<div class="app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'profile.page.about.title'">
        <button
          mat-raised-button
          routerLink="/manage/account"
          color="primary"
          aria-label="button with a add one icon">
          <mat-icon>skip_previous</mat-icon>
          <mat-label>{{"profile.password.update.btn-back-to-account.label" | translate}}</mat-label>
        </button>
      </app-header>
    </mat-card-content>
  </mat-card>

  <mat-card class="example-card col-xs-12  col-sm-12">
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="text-center" style="margin-bottom: 35px">
        <ng-container *ngIf="memberShip && mode === 'view'">
          <button mat-raised-button color="accent" (click)="editMemberShip()"
                  aria-label="button with a add one icon">
            <mat-icon>edit</mat-icon>
            <mat-label>{{"profile.about.edit.btn.label" | translate}}</mat-label>
          </button>
        </ng-container>
      </div>
      <ng-container *ngIf="memberShip && mode === 'view'">
        <div class="row"  style="margin-bottom: 15px">
          <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="memberShip.fullname">
            <label style="margin-right: 15px">{{"profile.about.name.label" | translate}}</label>
            <span>{{ memberShip.fullname }}</span>
          </div>

          <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="memberShip.options" >
            <label style="margin-right: 15px">
              {{"profile.about.options.label" | translate}}
            </label>
            <span>{{favoriteOption.text | translate}}</span>
            <button matSuffix mat-button matTooltip="{{favoriteOption.description | translate}}">
              <mat-icon>live_help</mat-icon>
            </button>
          </div>

          <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="memberShip.birthdate" >
            <label style="margin-right: 15px">{{"profile.about.birthdate.label" | translate}}</label>
            <span>{{getYear(memberShip)}}</span>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="memberShip.nativeCountry" >
            <label style="margin-right: 15px">{{"register.form.nativeCountry.label" | translate}}</label>
            <span>{{memberShip.nativeCountry.name}}</span>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="memberShip.residenceCountry" >
            <label style="margin-right: 15px">{{"register.form.residenceCountry.label" | translate}}</label>
            <span>{{memberShip.residenceCountry.name}}</span>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4" *ngIf="memberShip.mobilePhone" >
            <label style="margin-right: 15px">{{"member.phone.label" | translate}}</label>
            <span>{{memberShip.mobilePhone}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6" *ngIf="memberShip.emailAddress" >
          <span>
            <mat-chip-list aria-label="Fish selection">
              <mat-chip color="accent" selected>{{memberShip.emailAddress}}</mat-chip>
            </mat-chip-list>
          </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="mode === 'edit'">
        <mat-card style="margin-top: 15px">
          <mat-card-header>
            <mat-card-title>{{"profile.about.edit.title.label" | translate}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <app-profile-about-edit [memberShip]="memberShip" #profileAboutEditComponent></app-profile-about-edit>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </mat-card-content>
  </mat-card>

</div>
