<div class="main-content app">
  <mat-card>
    <mat-card-content>
      <app-header class="app-header" [title]="'dashboard.title'"></app-header>
    </mat-card-content>
  </mat-card>
    <mat-card class="" >
        <mat-divider></mat-divider><br><br><br>
        <mat-card-content>

            <div class="container-fluid">

                <div class="row asso-status">

                    <div class="col-lg-3 col-md-6 col-sm-6 status-item">
                        <div class="card card-stats">
                            <div class="card-header card-header-success card-header-icon">
                                <div class="card-icon">
                                    <i class="material-icons">people_outline</i>
                                </div>
                                <p class="card-category">{{'dashboard.adherent.label' | translate}}</p>
                                <h3 class="card-title">{{memberCount.total}}</h3>
                            </div>
                            <div class="card-footer">
                                <div class="stats">
                                  <button mat-raised-button color="primary" aria-label="Example icon button with a open in new tab icon">
                                    <mat-label>{{"dashboard.adherent.list" | translate}}</mat-label>
                                    <mat-icon>open_in_new</mat-icon>
                                  </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 status-item">
                        <div class="card card-stats">
                            <div class="card-header card-header-success card-header-icon">
                                <div class="card-icon">
                                    <i class="material-icons">check</i>
                                </div>
                                <p class="card-category">{{'dashboard.balance.label' | translate}}</p>
                                <h3 class="card-title">{{activeMemberCount.total}}</h3>
                            </div>
                            <div class="card-footer">
                                <div class="stats">
                                  <button mat-raised-button color="primary" aria-label="Example icon button with a open in new tab icon">
                                    <mat-label>{{"dashboard.active.list" | translate}}</mat-label>
                                    <mat-icon>open_in_new</mat-icon>
                                  </button>
                                </div>
                            </div>
                        </div>
                    </div>

                  <div class="col-lg-3 col-md-6 col-sm-6 status-item">
                    <div class="card card-stats">
                      <div class="card-header card-header-success card-header-icon">
                        <div class="card-icon">
                          <i class="material-icons">favorite</i>
                        </div>
                        <p class="card-category">{{'dashboard.member-and-family.label' | translate}}</p>
                        <h3 class="card-title">{{memberAndFamilyCount.total}}</h3>
                      </div>
                      <div class="card-footer">
                        <div class="stats">
                          <button mat-raised-button color="primary" aria-label="Example icon button with a open in new tab icon">
                            <mat-label>{{"dashboard.member-and-family.list" | translate}}</mat-label>
                            <mat-icon>open_in_new</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 col-sm-6 status-item">
                    <div class="card card-stats">
                      <div class="card-header card-header-danger card-header-icon">
                        <div class="card-icon">
                          <i class="material-icons">settings_power</i>
                        </div>
                        <p class="card-category">{{'dashboard.QPI.label' | translate}}</p>
                        <h3 class="card-title">{{deceaseMemberCount.total}}</h3>
                      </div>
                      <div class="card-footer">
                        <div class="stats">
                          <button mat-raised-button color="secondary" aria-label="Example icon button with a open in new tab icon">
                            <mat-label>{{"dashboard.decease.list" | translate}}</mat-label>
                            <mat-icon>open_in_new</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                <div class="row statistics">

                    <div class="col-xs-12 col-sm-12 col-md-12">
                        <div class="card card-chart">
                            <div class="card-header card-header-warning">
                                <div class="ct-chart" id="websiteViewsChart"></div>
                            </div>
                            <div class="card-body">
                                <h4 class="card-title">{{'dashboard.adherent.registration.label' | translate}}</h4>
                                <p class="card-category">{{'dashboard.adherent.registration.comment' | translate}}</p>
                            </div>
                            <div class="card-footer">
                                <div class="stats">
                                    <i class="material-icons">date_range</i> {{'dashboard.last-update.label' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="row">

                    <div class="col-md-4 statisics-item">
                        <div class="card card-chart">
                            <div class="card-header card-header-success">
                                <div class="ct-chart" id="dailySalesChart"></div>
                            </div>
                            <div class="card-body">
                                <h4 class="card-title">{{'dashboard.adherent.active.label' | translate}}</h4>
                                <!-- <p class="card-category">
                                    <span class="text-success"><i class="fa fa-long-arrow-up"></i> 55% </span> increase in today sales.
                                </p> -->
                            </div>
                            <div class="card-footer">
                                <div class="stats">
                                    <i class="material-icons">access_time</i> {{'dashboard.time-update.label' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 statisics-item">
                        <div class="card card-chart">
                            <div class="card-header card-header-danger">
                                <div class="ct-chart" id="completedTasksChart"></div>
                            </div>
                            <div class="card-body">
                                <h4 class="card-title">{{'dashboard.adherent.deceased.label' | translate}}</h4>
                                <!-- <p class="card-category">Last Campaign Performance</p> -->
                            </div>
                            <div class="card-footer">
                                <div class="stats">
                                    <i class="material-icons">access_time</i> {{'dashboard.time-update.label' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </mat-card-content>
    </mat-card>
</div>
